import React from 'react';
import '../../../css/index.css'
import { apiCDN } from '../../config';

const Logo = () => {

  return (
    (
      <img className="img-logo" src={apiCDN + 'img/logos/sem_fundo/azuis/integracao.png'} alt='logo-graduacao' />
    )
  )
}

export default Logo