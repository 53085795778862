import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
moment.locale('pt-br')

const TabelaSimples = ({ cabecalho, dados, botaoDetalhes, cabecalhoFormat }) => (
    <div className='TabelaSimples'>
        <table className="simples">
            <thead>
                <tr style={{ textTransform: 'uppercase' }}>
                    {cabecalhoFormat.map((item, idx) => (<th key={idx}>{item} </th>))}
                </tr>
            </thead>
            <tbody>
                {
                    dados.map((linha, idx) => {

                        moment.locale('pt-br')
                        if (linha.pessoaComDeficiencia === true) linha.pessoaComDeficiencia = 'Sim'
                        if (linha.pessoaComDeficiencia === false) linha.pessoaComDeficiencia = 'Não'

                        const dataFormat = moment(linha.dataNascimento).format('DD/MM/YYYY')

                        return (
                            <tr key={idx}>
                                {
                                    cabecalho.map((item, index) => (
                                        <td key={index}> {item === 'dataNascimento' ? dataFormat : linha[item] || ''}</td>
                                    ))
                                }

                                <td>
                                    <button onClick={() => botaoDetalhes(linha, idx)} className="button button-danger button-small">
                                        X
                                    </button>
                                </td>
                            </tr>
                        )

                    })
                }
            </tbody>
        </table>
    </div>
)

export default TabelaSimples