import * as React from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { getHeaders } from '../../actions/localStorage'
import { api } from '../../config/'
import toast, { Toaster } from 'react-hot-toast';

export default function NestedList({ titulo, categoria, cpf, params, inscricao }) {
    const [open, setOpen] = React.useState(false);
    const handleClick = () => {
        setOpen(!open);
    };

    const notifyError = (erros) => {

        toast.error(erros, {
            duration: 4000,
            position: 'top-right',
            style: {
                border: '1px solid #713200',
                fontSize: '14px',
                color: '#713200',
            },
        })
    }

    const notifySuccess = (msg) => {

        toast.success(msg, {
            duration: 4000,
            position: 'top-right',
            style: {
                border: '1px solid #713200',
                fontSize: '14px',
                color: '#713200',
            },
        })
        setTimeout(function () {
            window.location.reload(true)
        }, 1000)
    }


    const alterarSituacao = async (nomeCategoria) => {
        const confirmacao = window.confirm('Deseja realmente confirmar este documento?')
        if (confirmacao == true) {
            const tokenStr = getHeaders().headers.authorization

            const query = window.location.pathname
            const partes = query.split('/')

            const myHeaders = {
                'Content-Type': 'application/json',
                "Authorization": tokenStr
            }

            await api.post(`/api/v1/inscricoes/adm/documentos/candidato/${partes[3]}`, { nome_doc: nomeCategoria }, {

                headers: myHeaders,
            }).then(() => notifySuccess('Situação do documento alterada!'))
                .catch(err => console.log(err))
        }
        else {
        }
    }

    return (
        <List style={{ width: '30%', padding: '10px' }}>
            <Toaster />
            <ListItemButton style={{ backgroundColor: '#002ff71a' }} onClick={handleClick}>
                <ListItemIcon>
                    <InboxIcon />
                </ListItemIcon>
                <ListItemText primary={titulo} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                {params && params.map(item => (
                    <List component="div" disablePadding style={{ display: 'flex', alignItems: 'center' }}>
                        {item.situacao === true ?
                            <CheckIcon
                                sx={{ color: 'green', cursor: 'pointer' }}
                                onClick={() => alterarSituacao(item.categoria)} />
                            :
                            <CloseIcon
                                sx={{ color: 'red', cursor: 'pointer' }}
                                onClick={() => alterarSituacao(item.categoria)} />

                        }
                        <ListItemButton onClick={() => window.open(
                            `https://api.anapolis.go.gov.br/apicentrodia/filesInscricoes/${cpf}/${categoria}/${item.value}`,
                            '_blank' // <- This is what makes it open in a new window.
                        )} sx={{ pl: 4 }} disabled={item.value ? false : true}>
                            <ListItemText primary={item.nome} />
                        </ListItemButton>
                    </List>
                ))
                }
            </Collapse >
        </List >
    );
}