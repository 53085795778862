import React, { Component } from "react";
import { api } from '../../../config/'
import { getHeaders } from '../../../actions/localStorage'
import UploadSimples from '../../../components/Upload/'
import { CircularProgressbar } from 'react-circular-progressbar';
import { MdCheckCircle, MdError, MdLink, MdMoodBad } from "react-icons/md";
import 'react-circular-progressbar/dist/styles.css';

export default class DocsIntegrante extends Component {

    state = {
        progress: 0,
        docs_rg_integrante: 0,
        docs_cpf_integrante: 0,
        docs_nascimento_integrante: 0,
        docs_casamento_integrante: 0,
        docs_contracheque_integrante: 0,
        docs_extrato_integrante: 0,
        docs_carteira_trabalho_integrante: 0,
        docs_declaracao_ir_integrante: 0,
        docs_averbacao_integrante: 0,
        docs_laudo_integrante: 0,
        docs_beneficiario_inss_integrante: 0,
        docs_beneficiario_pensao_integrante: 0,
        docs_aposentado_integrante: 0,
        docs_autonomos_integrante: 0,
        file: '',
        categoria: 'docs_integrante',
        inscricaoDB: '',
        progressSubCategoria: []
    }

    //CARREGAR ARQUIVO
    handleChangeFile = async event => {
        const { categoria } = this.state
        const { inscricao } = this.props
        const { name } = event.target

        this.setState({ progress: 0 })
        var file = event.target.files[0];
        if (file && file.type === 'application/pdf') {
            this.setState({ file });
        } else {
            alert('Extensão não suportada, selecione outro arquivo')
            this.setState({ file }); // storing file
        }



        const formData = new FormData();
        formData.append('file', file)
        const tokenStr = getHeaders().headers.authorization

        const myHeaders = {
            'Content-Type': 'multipart/form-data',
            "Authorization": tokenStr
        }
        var cpf2 = inscricao.cpf;
        cpf2 = cpf2.replace(".", "");
        cpf2 = cpf2.replace(".", "");
        cpf2 = cpf2.replace("-", "");


        await api.put(`/api/v1/inscricoes/docs?categoria=${categoria}&subcategoria=${name}&cpf=${cpf2}`, formData, {

            headers: myHeaders,
            onUploadProgress: data => {
                //Set the progress value to show the progress bar 
                this.setState({ [name]: Math.round((100 * data.loaded) / data.total) })
                this.setState({ progress: Math.round((100 * data.loaded) / data.total) })
            },
        }).then(res => {
            this.setState({ inscricaoDB: res.data.inscricao })
        }).then(() => window.location.reload(true))
            .catch(err => console.log(err))
    }


    render() {
        let documentos =
            [
                [
                    "Carteira de Identidade fornecida pelos órgãos de Segurança Pública das Unidades  da Federação ou Carteira Nacional de Habilitação;",
                    "rg_integrante "
                ],
                [
                    "Cadastro de Pessoa Física- CPF;",
                    "cpf_integrante "
                ],
                [
                    "Certidões de nascimento e Cédula de Identidade (RG) das pessoas  menores de 21 anos; ",
                    "nascimento_integrante "
                ],
                [
                    "Cópia da certidão de casamento e ou Declaração de união estável;",
                    "casamento_integrante "
                ],
                [
                    "Comprovante dos três últimos holerites (contracheque), no caso de renda fixa, ou seis últimos holerites (contracheques), quando houver pagamento de comissão e hora   extra; ",
                    "contracheque_integrante "
                ],
                [
                    "Extratos Bancários dos últimos 03 (três) meses de todas as contas (não apresentar comprovante de saldo em substituição aos extratos bancários), caso não tenha conta bancária apresentar Declaração de que não possui conta bancária; ",
                    "extrato_integrante "
                ],
                [
                    "Carteira de Trabalho: Cópia das páginas nas quais constem a foto, identificação, último contrato de trabalho ou rescisão do contrato, página subsequente em branco, as observações em anotações gerais, quando for o caso; ",
                    "carteira_trabalho_integrante "
                ],
                [
                    "Declaração de IR 2021 (referência/ano-calendário 2020) com todas as páginas acompanhadas do recibo de entrega à Receita Federal do Brasil e, quando  for o caso, da restituição a respectiva notificação; ",
                    "declaracao_ir_integrante "
                ],
                [
                    "Integrante divorciado ou separado judicialmente: Cópia da Certidão de Casamento constando  a averbação de separação judicial com ou sem formal de partilha.",
                    "averbacao_integrante "
                ],
                [
                    "O responsável familiar deficiente que deseje pontuar conforme o Item 5.1, critério “e” desde Edital,  deve apresentar laudo Médico constando o  CID,  com no máximo 12 meses de expedição, informando a espécie, o grau ou nível de deficiência, contendo a assinatura e carimbo com número de inscrição no CRM do médico responsável por sua emissão, ou comprovação de recebimento de beneficio do INSS em consequência da deficiência.",
                    "laudo_integrante "
                ],
                [
                    "Beneficiário de Pensão do INSS: extratos bancários dos últimos 03 (três) meses de pagamento do beneficio, emitido pela Internet no Endereço eletrônico www. mpas.gov.br ou solicitar na agência do INSS. A apresentação dos extratos bancários não dispensa a apresentação do extrato de pagamento do beneficio e vice-versa.",
                    "beneficiario_inss_integrante "
                ],
                [
                    "Beneficiário de Pensão alimentícia: extrato bancário que consta o depósito, ou acordo/decisão judicial que delimite o valor. ",
                    "beneficiario_pensao_integrante "
                ],
                [
                    "Integrante familiar aposentado: Extrato de pagamento do beneficio do último mês, emitido pela internet no endereço eletrônico  https://meu.inss.gov.br/#/login ou solicitar na agência do INSS.",
                    "aposentado_integrante "
                ],
                [
                    "Os Profissionais Autônomos que contribuem para o INSS deverão apresentar Guia de recolhimento do INSS com comprovante de pagamento do último mês, e extratos bancários dos últimos 03 (três) meses.",
                    "autonomos_integrante "
                ]
            ]

        const { file, progress, inscricaoDB, progressCategoria, categoria } = this.state
        const { inscricao, arquivos } = this.props
        var cpf2 = inscricao.cpf;
        cpf2 = cpf2.replace(".", "");
        cpf2 = cpf2.replace(".", "");
        cpf2 = cpf2.replace("-", "");
        return (
            <div className='col-md-12 row'>
                {documentos.map((item, i) => {
                    let state = 'docs_' + item[1]
                    return (
                        <div key={i} className='Input-Upload col-md-6' style={{ placeContent: 'space-around', marginTop: '30px' }}>
                            <UploadSimples
                                onChange={this.handleChangeFile}
                                arquivos={arquivos}
                                span={item[0]}
                                file={file}
                                inscricao={inscricao}
                                name={state.trim()}
                                inscricaoDB={inscricaoDB ? inscricaoDB : ''}
                                cpf2={cpf2}
                                categoria={categoria}
                                progress={progress}
                                progressCategoria={this.state[state]}
                            />
                        </div>
                    )
                })}
            </div>

        )
    }
}