import React, { Component } from 'react';
import InputSimples from '../../../components/Inputs/Simples'
import InputSelect from '../../../components/Inputs/Select'
import Titulo from '../../../components/Texto/Titulo'

import { connect } from 'react-redux';
import * as actions from '../../../actions';
import { TextoSelect } from '../../../components/Texto/Dados';

class DadosSaude extends Component {
  state = {
    open: false,
    beneficio: false,
    qualBeneficio: '',
    cras: false,
    qualCras: '',
    deficiencia: false,
    qualDeficiencia: '',
    locomove: false,
    comunica: false,
    protese: false,
    qualProtese: '',
    aparelho: false,
    qualAparelho: '',
    portadorDoenca: false,
    qualDoenca: '',
    usoContinuo: false,
    qualUsoContinuo: '',
    erros: {}
  }

  componentDidMount() {
    this.validate();
  }


  validate = () => {
    const { beneficio, qualBeneficio, cras, qualCras, deficiencia, qualDeficiencia, protese, qualProtese, aparelho, qualAparelho, portadorDoenca, qualDoenca, usoContinuo, qualUsoContinuo } = this.props
    const erros = {}

    if (beneficio === true && (!qualBeneficio || qualBeneficio === 'nenhum')) erros.qualBeneficio = 'Preencha qual é o Beneficio.';
    if (cras === true && !qualCras) erros.qualCras = 'Preencha qual o Cras ou Creas.';
    if (deficiencia === true && (!qualDeficiencia || qualDeficiencia === 'nenhum')) erros.qualDeficiencia = 'Preencha qual é a Deficiencia.';
    if (protese === true && !qualProtese) erros.qualProtese = 'Preencha qual é o tipo de Protese.';
    if (aparelho === true && !qualAparelho) erros.qualAparelho = 'Preencha qual é o Aparelho.';
    if (portadorDoenca === true && !qualDoenca) erros.qualDoenca = 'Preencha qual é a Doenca.';
    if (usoContinuo === true && !qualUsoContinuo) erros.qualUsoContinuo = 'Preencha qual é o remedio de Uso Continuo.';


    this.setState({ erros });

    this.props.setErrosSaude(erros)

    return !(Object.keys(erros).length > 0);
  }

  onChangeInput = (field, value) => this.setState({ [field]: value }, () => this.validate())

  render() {
    const { beneficio, qualBeneficio, cras, qualCras, deficiencia, qualDeficiencia, locomove, comunica, protese, qualProtese, aparelho, qualAparelho, portadorDoenca, qualDoenca, usoContinuo, qualUsoContinuo } = this.props
    const { erros } = this.state
    console.log(this.props)
    return (
      <div className='Inscricao full-width flex vertical'>
        <div className='Card-Inscricao'>
          <Titulo tipo='titulo-detalhe-inscricao' titulo='Informações sobre a saúde do canditado' />
          <div className='Container-Inscricao'>
            <div className='Input-Inscricao flex-1 flex horizontal space-between'>
              <TextoSelect
                chave='Recebe Algum Beneficio ?*'
                valor={(
                  <InputSelect
                    name='responsavelFinanceiro'
                    onChange={(ev) => (this.onChangeInput('beneficio', ev.target.value), this.props.setBeneficio(ev.target.value))}
                    value={(beneficio) ? beneficio : false}
                    opcoes={[
                      { label: 'SIM', value: true },
                      { label: 'NÃO', value: false }
                    ]} />
                )} />
              {beneficio === false ? <div /> :
                <TextoSelect
                  chave='Qual Beneficio ?*'
                  valor={(
                    <InputSelect
                      type='saude'
                      name='Cras'
                      onChange={(ev) => (this.onChangeInput('qualBeneficio', ev.target.value), this.props.setQualbeneficio(ev.target.value))}
                      value={qualBeneficio}
                      error={erros.qualBeneficio}
                      opcoes={[
                        { label: 'Selecione o Beneficio', value: 'nenhum' },
                        { label: 'BPC', value: 'bpc' },
                        { label: 'BOLSA FAMILIA', value: 'bolsaFamilia' },
                        { label: 'AUXILIO BRASIL', value: 'auxilioBrasil' },
                        { label: 'APOSENTADORIA', value: 'aposentatoria' },
                        { label: 'PENSÃO POR MORTE', value: 'pensaoMorte' }
                      ]} />
                  )} />
              }
            </div>
            <br />
            <div className='Input-Inscricao flex-1 flex horizontal space-between'>
              <TextoSelect
                chave='É atendido pelo CRAS/CREAS ?*'
                valor={(
                  <InputSelect
                    name='Cras'
                    onChange={(ev) => (this.onChangeInput('cras', ev.target.value), this.props.setCras(ev.target.value))}
                    value={(cras) ? cras : false}
                    opcoes={[
                      { label: 'SIM', value: true },
                      { label: 'NÃO', value: false }
                    ]} />
                )} />
              {cras === false ? <div /> :
                <InputSimples
                  className='flex-3'
                  id='rendaMensalTitular'
                  name='rendaMensalTitular'
                  label='Qual CRAS/CREAS?'
                  placeHolder='Digite Aqui o nome  do CRAS ou CREAS'
                  type='saude'
                  value={qualCras}
                  error={erros.qualCras}
                  onChange={(ev) => (this.onChangeInput('qualBeneficio', ev.target.value), this.props.setQualcras(ev.target.value))} />
              }
            </div>
            <br />
            <div className='Input-Inscricao flex-1 flex horizontal space-between'>
              <TextoSelect
                chave='Possui Alguma Deficiência ?*'
                valor={(
                  <InputSelect
                    name='Cras'
                    onChange={(ev) => (this.onChangeInput('deficiencia', ev.target.value), this.props.setDeficiencia(ev.target.value))}
                    value={(deficiencia) ? deficiencia : false}
                    opcoes={[
                      { label: 'SIM', value: true },
                      { label: 'NÃO', value: false }
                    ]} />
                )} />
              {deficiencia === false ? <div /> :
                <TextoSelect
                  chave='Qual Deficiência ?*'
                  valor={(
                    <InputSelect
                      type='saude'
                      name='Qual Deficiencia'
                      onChange={(ev) => (this.onChangeInput('qualDeficiencia', ev.target.value), this.props.setQualdeficiencia(ev.target.value))}
                      value={qualDeficiencia}
                      error={erros.qualDeficiencia}
                      opcoes={[
                        { label: 'Selecione a Deficiência', value: 'nenhum' },
                        { label: 'Física', value: 'fisica' },
                        { label: 'Auditiva', value: 'auditiva' },
                        { label: 'Visual', value: 'visual' },
                        { label: 'Intelectual', value: 'intelectual' },
                        { label: 'Múltipla', value: 'multipla' }
                      ]} />
                  )} />
              }
            </div>
            <br />
            <div className='Input-Inscricao flex-1 flex horizontal space-between'>
              <TextoSelect
                chave='Consegue se Locomover Sozinho ?*'
                valor={(
                  <InputSelect
                    name='Cras'
                    onChange={(ev) => (this.onChangeInput('locomove', ev.target.value), this.props.setLocomove(ev.target.value))}
                    value={(locomove) ? locomove : 'nao'}
                    opcoes={[
                      { label: 'SIM', value: 'sim' },
                      { label: 'NÃO', value: 'nao' },
                      { label: 'PARCIALMENTE', value: 'parcialmente' }
                    ]} />
                )} />
              <TextoSelect
                chave='Consegue se Comunicar Sozinho ?*'
                valor={(
                  <InputSelect
                    name='comunica'
                    onChange={(ev) => (this.onChangeInput('comunica', ev.target.value), this.props.setComunica(ev.target.value))}
                    value={(comunica) ? comunica : 'nao'}
                    opcoes={[
                      { label: 'SIM', value: 'sim' },
                      { label: 'NÃO', value: 'nao' },
                      { label: 'PARCIALMENTE', value: 'parcialmente' }
                    ]} />
                )} />
              <TextoSelect
                chave='Usa Alguma Ortese/Protese ?*'
                valor={(
                  <InputSelect
                    name='protese'
                    onChange={(ev) => (this.onChangeInput('protese', ev.target.value), this.props.setProtese(ev.target.value))}
                    value={(protese) ? protese : false}
                    opcoes={[
                      { label: 'SIM', value: true },
                      { label: 'NÃO', value: false },
                    ]} />
                )} />
            </div>
            <br />
            <div className='Input-Inscricao flex-1 flex horizontal'>
              {protese === false ? <div /> :
                <InputSimples
                  className='flex-3'
                  id='rendaMensalTitular'
                  name='rendaMensalTitular'
                  label='Qual Ortese/Protese?'
                  placeHolder='Digite Aqui qual Ortese/Protese'
                  type='saude'
                  value={qualProtese}
                  error={erros.qualProtese}
                  onChange={(ev) => (this.onChangeInput('qualProtese', ev.target.value), this.props.setQualprotese(ev.target.value))} />
              }
            </div>
            <br />
            <div className='Input-Inscricao flex-1 flex horizontal space-between'>
              <TextoSelect
                chave='Utiliza algum Aparelho/Equipamento para se locomover ?*'
                valor={(
                  <InputSelect
                    name='aparelho'
                    onChange={(ev) => (this.onChangeInput('aparelho', ev.target.value), this.props.setAparelho(ev.target.value))}
                    value={(aparelho) ? aparelho : false}
                    opcoes={[
                      { label: 'SIM', value: true },
                      { label: 'NÃO', value: false }
                    ]} />
                )} />
              {aparelho === false ? <div /> :
                <InputSimples
                  className='flex-3'
                  id='qualAparelho'
                  name='qualAparelho'
                  label='Qual Aparelho/Equipamento?'
                  placeHolder='Digite Aqui qual aparelho/equipamento'
                  type='saude'
                  value={qualAparelho}
                  error={erros.qualAparelho}
                  onChange={(ev) => (this.onChangeInput('qualAparelho', ev.target.value), this.props.setQualaparelho(ev.target.value))} />
              }
            </div>
            <br />
            <div className='Input-Inscricao flex-1 flex horizontal space-between'>
              <TextoSelect
                chave='Portator de doença cronica que faz ou necessita de tratamento?*'
                valor={(
                  <InputSelect
                    name='portadorDoenca'
                    onChange={(ev) => (this.onChangeInput('portadorDoenca', ev.target.value), this.props.setPortadordoenca(ev.target.value))}
                    value={(portadorDoenca) ? portadorDoenca : false}
                    opcoes={[
                      { label: 'SIM', value: true },
                      { label: 'NÃO', value: false }
                    ]} />
                )} />
              {portadorDoenca === false ? <div /> :
                <InputSimples
                  className='flex-3'
                  id='qualAparelho'
                  name='qualAparelho'
                  label='Qual Doença?'
                  placeHolder='Digite Aqui qual doença faz ou necessita de tratamento'
                  type='saude'
                  value={qualDoenca}
                  error={erros.qualDoenca}
                  onChange={(ev) => (this.onChangeInput('qualDoenca', ev.target.value), this.props.setQualdoenca(ev.target.value))} />
              }
            </div>
            <br />
            <div className='Input-Inscricao flex-1 flex horizontal space-between'>
              <TextoSelect
                chave='Faz uso continuo e/ou controlado de algum tipo de medicação?*'
                valor={(
                  <InputSelect
                    name='usoContinuo'
                    onChange={(ev) => (this.onChangeInput('usoContinuo', ev.target.value), this.props.setUsocontinuo(ev.target.value))}
                    value={(usoContinuo) ? usoContinuo : false}
                    opcoes={[
                      { label: 'SIM', value: true },
                      { label: 'NÃO', value: false }
                    ]} />
                )} />
              {usoContinuo === false ? <div /> :
                <InputSimples
                  className='flex-3'
                  id='qualUsoContinuo'
                  name='qualUsoContinuo'
                  label='Qual medicação?'
                  placeHolder='Digite Aqui qual medicação'
                  type='saude'
                  value={qualUsoContinuo}
                  error={erros.qualUsoContinuo}
                  onChange={(ev) => (this.onChangeInput('qualUsoContinuo', ev.target.value), this.props.setQualusocontinuo(ev.target.value))} />
              }
            </div>
            <br />
          </div>
        </div>
      </div>

    )
  }
}

export default connect(null, actions)(DadosSaude)
