import axios from "axios";
import moment from 'moment';

export var apiURL = 'https://api.anapolis.go.gov.br/apicentrodia';
//export var apiURL = 'http://localhost:3010';
export const apiCDN = 'https://cdn.anapolis.go.gov.br/'
export const versao = 'v1'

export const api = axios.create({
    baseURL: apiURL
});

export function calculaIdade(data) {
    var d1 = data;
    var d2 = moment(new Date()).format('DD/MM/YYYY');
    var diff = moment(d2, "DD/MM/YYYY").diff(moment(d1, "DD/MM/YYYY"));
    var anos = moment.duration(diff).asYears();
    return anos
}