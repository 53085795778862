import axios from 'axios';
import moment from 'moment'
import CryptoJS from 'crypto-js'
import {
  LOGIN_USER, LOGOUT_USER, GET_INSCRICOES
} from './types';
import { api, versao, apiURL } from '../config';
import { saveToken, getHeaders, cleanToken } from './localStorage'
import errorHandling from './errorHandling';

export const initApp = () => {
  const opcaoLembrar = localStorage.getItem('opcaoLembrar');
  if (opcaoLembrar === 'false') cleanToken();
}

// USUARIOS
export const handleLogin = ({ username, password, opcaoLembrar }, callback) => {
  return function (dispatch) {
    api.post(`/api/${versao}/usuarios/login`, { username, password })
      .then((response) => {
        saveToken(response.data.usuario, opcaoLembrar);
        dispatch({ type: LOGIN_USER, payload: response.data })
        window.location.href = '/'
      }).catch((e) => callback(errorHandling(e)));
  }
}

export const getUser = () => {
  return async function (dispatch) {
    await api.get(`/api/${versao}/usuarios/`, getHeaders())
      .then((response) => {
        saveToken(response.data.usuario, true);
        dispatch({ type: LOGIN_USER, payload: response.data })
      }).catch((error) => {
        //console.log(error, error.response, error.response && error.response.data)
      })
  }
}

export const buscaCpf = (termo) => {
  var cpfCrypt = CryptoJS.AES.encrypt(termo.cpf, 'graduacaoCpf').toString();

  return function (dispatch) {
    axios.get(`${api}/api/${versao}/inscricoes/search/${termo.cpf}`, getHeaders())
      .then(response => {
        dispatch({ type: GET_INSCRICOES, payload: response.data })
        if (response.data.inscricoes.total > 0) { alert('CPF já cadastrado') }
        // console.log(response)
        //  window.location.href = `${(response.data.inscricoes.total === 0) ? `/#/inscricao/${cpfCrypt}` : `/`}`
      })
      .catch(errorHandling)
  }
}

export const updateUser = (dados, cb) => {
  return function (dispatch) {
    axios.put(`${apiURL}/api/${versao}/usuarios/`, dados, getHeaders())
      .then((response) => {
        saveToken(response.data.usuario, true);
        dispatch({ type: LOGIN_USER, payload: response.data });
        cb(null);
      })
      .catch((error) => cb(errorHandling(error)));
  }
}


export const handleLogout = () => {
  cleanToken();
  return { type: LOGOUT_USER };
}

export const formatMoney = (valor) => {
  return `R$ ${valor.toFixed(2).split(',').join('.')}`
}

// export const transformMoney = (valor) => {
//   var tmp = valor + '';
//   var neg = false;
//   if (tmp.indexOf("-") == 0) {
//     neg = true;
//     tmp = tmp.replace("-", "");
//   }

//   if (tmp.length == 1) tmp = "0" + tmp

//   tmp = tmp.replace(/([0-9]{2})$/g, ".$1");
//   if (tmp.length > 6)
//     tmp = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1.$2");

//   if (tmp.length > 9)
//     tmp = tmp.replace(/([0-9]{3}).([0-9]{3}),([0-9]{2}$)/g, ".$1.$2.$3");

//   if (tmp.length > 12)
//     tmp = tmp.replace(/([0-9]{3}).([0-9]{3}).([0-9]{3}),([0-9]{2}$)/g, ".$1.$2.$3.$4");

//   if (tmp.indexOf(".") == 0) tmp = tmp.replace("", "");
//   if (tmp.indexOf(".") == 0) tmp = tmp.replace(".", "0.");

//   return (neg ? '-' + tmp : tmp);
// }

export const transformMoney = (valor) => {
  if (valor) {
    const teste = valor.toString()
    return `${parseFloat(teste.split(',').join('.'))}`
  } else { return valor }
}

export const transformDate = (data, divisor, formato) => {
  const _data = data.split(divisor);
  const dia = Number(_data[0]) + 1;
  const mes = Number(_data[1]) - 1;
  const ano = Number(_data[2]);
  return moment(new Date(ano, mes, dia)).format(formato)
}
