import React, { Component } from 'react';
import InputLogin from '../../components/Inputs/Login';
import Checkbox from '../../components/Inputs/Checkbox';
import Button from '../../components/Button/Simples';
import AlertDanger from '../../components/Alert/Danger'

import '../../../css/index.css'

import { connect } from 'react-redux';
import * as actions from '../../actions/'
import { api, versao } from '../../config'
import { onKeyDown } from '../../components/Inputs/FunctonEnter';

class Login extends Component {
  state = {
    cpf: '',
    password: '',
    opcaoLembrar: true,
    erros: {},
    err: ''
  }

  onChangeInput = (field, ev) => {
    this.setState({ [field]: ev.target.value })
    this.validate()
  }
  onChangeCheckBox = (field) => this.setState({ [field]: !this.state[field] })

  saveToken = (usuario) => {
    if (!usuario.data.token) return null;
    const [token1, token2, token3] = usuario.data.token.split('.')
    localStorage.setItem("token1", token1);
    localStorage.setItem("token2", token2);
    localStorage.setItem("token3", token3);
  }

  handleLogin = () => {
    const { cpf, password } = this.state

    api.post(`/api/v1/usuarios/login`, { username: cpf, password })
      .then((response) => {
        this.saveToken(response.data);
      })
      .then((response) => {
        window.location.href = '/#/'
      })
      .catch((error) => {
        let erro = error.response.data.errors
        if (Array.isArray(erro)) {
          let erroContent = erro[0]
          this.setState({
            err: {
              message: erroContent.messages[0]
            }
          })
        } else {
          this.setState({
            err: {
              message: erro
            }
          })
        }
      })

  }

  mCPF = (cpf) => {
    cpf = cpf.replace(/\D/g, "")
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2")
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2")
    cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
    return cpf
  }

  ValidaCPF = () => {
    var RegraValida = this.state.cpf;
    var cpf = RegraValida.trim();

    cpf = cpf.replace(/\./g, '');
    cpf = cpf.replace('-', '');
    cpf = cpf.split('');
    var v1 = 0;
    var v2 = 0;
    var aux = false;

    for (var i = 1; cpf.length > i; i++) {
      if (cpf[i - 1] != cpf[i]) {
        aux = true;
      }
    }

    if (aux == false) {
      return false;
    }

    for (var i = 0, p = 10; (cpf.length - 2) > i; i++, p--) {
      v1 += cpf[i] * p;
    }

    v1 = ((v1 * 10) % 11);

    if (v1 == 10) {
      v1 = 0;
    }

    if (v1 != cpf[9]) {
      return false;
    }

    for (var i = 0, p = 11; (cpf.length - 1) > i; i++, p--) {
      v2 += cpf[i] * p;
    }

    v2 = ((v2 * 10) % 11);

    if (v2 == 10) {
      v2 = 0;
    }

    if (v2 != cpf[10]) {
      return false;
    } else {
      return true;
    }
  }
  validate() {
    const { cpf, password } = this.state;
    const erros = {};
    if (!cpf) erros.cpf = 'Preencha aqui com seu e-mail'
    if (!password) erros.password = 'Preencha aqui com sua senha'
    this.setState({ erros });
    return !(Object.keys(erros).length > 0);
  }

  render() {
    const { cpf, password, opcaoLembrar, err, erros } = this.state;
    return (
      <div className='Login flex flex-center'>
        <div>
          <AlertDanger error={err} />

          <InputLogin
            validate={this.ValidaCPF() === false ? false : true}
            label='CPF'
            value={cpf}
            type='cpf'
            erro={this.ValidaCPF() === false ? true : false}
            helpText={'CPF inválido'}
            id='RegraValida'
            name='RegraValida'
            onChange={(e) => this.setState({ cpf: this.mCPF(e.target.value) }, () => this.validate())}
          />
          <InputLogin
            className='input-login'
            placeHolder={'Digite sua senha'}
            value={password}
            error={erros.password}
            type='password'
            onKeyUp={(event) => onKeyDown(event, this.handleLogin)}
            onChange={(ev) => this.onChangeInput('password', ev)} />
          <br /> <br />
          <div className='opcoes-login flex'>
            <div className='flex-1'>
              <Checkbox
                value={opcaoLembrar}
                onChange={(e) => this.onChangeCheckBox('opcaoLembrar')}
                label='Lembar?' />
            </div>
            <div className='flex-1 flex flex-end'>
              {/* <Link to='/recuperar-senha'><smal>Esqueceu sua senha?</smal></Link> */}
              <a href={`${api}/api/${versao}/usuarios/recuperar-senha`}>
                <small>Esqueceu sua senha?</small>
              </a>
            </div>
          </div>
          <br /><br />
          <div className='btn-login flex flex-center'>
            <Button type='login' label={<i className='fa fa-paper-plane' />} onClick={() => this.handleLogin()} />
          </div>
        </div>
      </div>
    )
  }
}

export default connect(null, actions)(Login);
