import React from 'react'

const AlertDanger = ({ error, classe }) => {
    if (!error) return null;
    let button
    if (!classe) { button = 'alert-danger' } else { button = classe }
    return (
        <div className={'alert ' + button}>
            <span>
                {error.message}
            </span>
        </div>
    )
}

export default AlertDanger