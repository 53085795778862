import { getHeaders } from './localStorage'
import axios from 'axios';
import { api, versao, apiURL } from '../config';
import { transformDate } from './index';
import errorHandling from './errorHandling';
import { GET_INSCRICOES, GET_INSCRICAO, LIMPAR_INSCRICAO, CANCELAR_INSCRICAO, GET_APTOS, GET_NAO_APTOS, GET_ACEITOS, GET_INTEGRANTES, CANCELAR_INTEGRANTE, GET_ARQUIVOS, GET_INSCRITO } from './types';

export const getInscricoes = () => {
  return function (dispatch) {
    axios.get(`${apiURL}/api/${versao}/inscricoes/adm`, getHeaders())
      .then(response => dispatch({ type: GET_INSCRICOES, payload: response.data }))
      .catch(errorHandling)
  }
}

export const getIntegrantes = () => {
  return function (dispatch) {
    axios.get(`${apiURL}/api/${versao}/inscricoes/integrante`, getHeaders())
      .then(response => dispatch({ type: GET_INTEGRANTES, payload: response.data }))
      .catch(errorHandling)
  }
}

export const removeIntegrante = (id) => {
  return function (dispatch) {
    axios.delete(`${apiURL}/api/${versao}/inscricoes/integrante/${id}`, getHeaders())
      .then(response => dispatch({ type: CANCELAR_INTEGRANTE, payload: response.data }))
      .catch(errorHandling)
  }
}

export const getIntegrantesAdm = (id) => {
  return function (dispatch) {
    axios.get(`${apiURL}/api/${versao}/inscricoes/adm/integrantes/` + id, getHeaders())
      .then(response => dispatch({ type: GET_INTEGRANTES, payload: response.data }))
      .catch(errorHandling)
  }
}

export const getInscricoesArquivos = (id) => {
  return function (dispatch) {
    axios.get(`${apiURL}/api/${versao}/inscricoes/adm/arquivos/${id}`, getHeaders())
      .then(response => dispatch({ type: GET_ARQUIVOS, payload: response.data }))
      .catch(errorHandling)
  }
}

export const getInscricoesArquivosAceitos = (id) => {
  return function (dispatch) {
    axios.get(`${apiURL}/api/${versao}/inscricoes/adm/documentos/candidato/${id}`, getHeaders())
      .then(response => dispatch({ type: GET_ACEITOS, payload: response.data }))
      .catch(errorHandling)
  }
}

export const getInscricoesAptos = () => {
  return function (dispatch) {
    axios.get(`${apiURL}/api/${versao}/inscricoes/adm/aptos`, getHeaders())
      .then(response => dispatch({ type: GET_INSCRICOES, payload: response.data }))
      .catch(errorHandling)
  }
}

export const getInscricoesIntegrais = () => {
  return function (dispatch) {
    axios.get(`${apiURL}/api/${versao}/aptos/integrais`, getHeaders())
      .then(response => dispatch({ type: GET_APTOS, payload: response.data }))
      .catch(errorHandling)
  }
}

export const getInscricoesParciais = () => {
  return function (dispatch) {
    axios.get(`${apiURL}/api/${versao}/aptos/parciais`, getHeaders())
      .then(response => dispatch({ type: GET_APTOS, payload: response.data }))
      .catch(errorHandling)
  }
}

export const getInscricoesReservas = () => {
  return function (dispatch) {
    axios.get(`${apiURL}/api/${versao}/aptos/reservas`, getHeaders())
      .then(response => dispatch({ type: GET_APTOS, payload: response.data }))
      .catch(errorHandling)
  }
}

export const getInscricoesDesclassificado = () => {
  return function (dispatch) {
    axios.get(`${apiURL}/api/${versao}/aptos/desclassificado`, getHeaders())
      .then(response => dispatch({ type: GET_NAO_APTOS, payload: response.data }))
      .catch(errorHandling)
  }
}


export const getInscricoesPesquisa = (termo, atual, limit) => {
  return function (dispatch) {
    axios.get(`${api}/api/${versao}/inscricoes/search/${termo}?offset=${atual}&limit=${limit}`, getHeaders())
      .then(response => dispatch({ type: GET_INSCRICOES, payload: response.data }))
      .catch(errorHandling)
  }
}

export const getInscricao = (id) => {
  return function (dispatch) {
    api.get(`/api/${versao}/inscricoes/`, getHeaders())
      .then(response => dispatch({ type: GET_INSCRICAO, payload: response.data }))
      .catch(errorHandling);
  }
}

export const getInscricaoAdm = (id) => {
  return function (dispatch) {
    api.get(`/api/${versao}/inscricoes/adm/${id}`, getHeaders())
      .then(response => dispatch({ type: GET_INSCRICAO, payload: response.data }))
      .catch(errorHandling);
  }
}

export const getInscritoAdm = (id) => {
  return function (dispatch) {
    api.get(`/api/${versao}/inscricoes/adm/inscricao/${id}`, getHeaders())
      .then(response => dispatch({ type: GET_INSCRITO, payload: response.data }))
      .catch(errorHandling);
  }
}

export const updateInscricao = (inscricao, id, cb) => {
  return function (dispatch) {
    axios.put(`${api}/api/${versao}/inscricoes/adm/${id}`, {
      nome: inscricao.nome,
      cpf: inscricao.cpf,
      rg: inscricao.rg,
      sexo: inscricao.sexo,
      dataDeNascimento: transformDate(inscricao.dataDeNascimento, '/', 'YYYY-MM-DD'),
      estadoCivil: inscricao.estadoCivil,
      email: inscricao.email,
      escolaridade: inscricao.escolaridade,
      nomeDaMae: inscricao.nomeDaMae,
      nomeDoPai: inscricao.nomeDoPai,
      cpfDaMae: inscricao.cpfDaMae,
      cpfDoPai: inscricao.cpfDoPai,
      naturalidade: inscricao.naturalidade,
      rendaMensalTitular: inscricao.rendaMensalTitular,
      quantidadeImoveis: inscricao.quantidadeImoveis,
      egresso: inscricao.egresso,
      maeSolo: inscricao.maeSolo,
      responsavelFinanceiro: inscricao.responsavelFinanceiro,
      pessoaComDeficiencia: inscricao.pessoaComDeficiencia,
      classificacaoDeficiencia: inscricao.classificacaoDeficiencia,
      cidDeficiencia: inscricao.cidDeficiencia,
      universidade: inscricao.universidade,
      curso: inscricao.curso,
      periodoCursando: inscricao.periodoCursando,
      turnoDoCurso: inscricao.turnoDoCurso,
      modalidadeCurso: inscricao.modalidadeCurso,
      matricula: inscricao.matricula,
      mensalidadeSemDesconto: inscricao.mensalidadeSemDesconto,
      mensalidadeComDesconto: inscricao.mensalidadeComDesconto,
      possuiFinOuBolsa: inscricao.possuiFinOuBolsa,
      endereco: {
        logradouro: inscricao.logradouro,
        numero: inscricao.numero,
        complemento: inscricao.complemento,
        cep: inscricao.cep,
        bairro: inscricao.bairro,
        cidade: inscricao.cidade,
      },
      pontoReferencia: inscricao.pontoReferencia,
      dataResideAnapolis: transformDate(inscricao.dataDeNascimento, '/', 'YYYY-MM-DD'),
      celular: inscricao.celular,
      telefoneContato: inscricao.telefoneContato,
      agua: inscricao.agua,
      aluguel: inscricao.aluguel,
      educacao: inscricao.educacao,
      energia: inscricao.energia,
      finanHabitacional: inscricao.finanHabitacional,
      transporte: inscricao.transporte,
      saude: inscricao.saude,
      total: inscricao.total,
    }, getHeaders())
      .then(response => {
        dispatch({ type: GET_INSCRICAO, payload: response.data })
        cb(null);
      })
      .catch((e) => cb(errorHandling(e)))
  }
}

export const removerInscricao = (id, loja, cb) => {
  return function (dispatch) {
    axios.delete(`${api}/api/${versao}/inscricoes/adm/${id}`, getHeaders())
      .then(response => {
        dispatch({ type: CANCELAR_INSCRICAO, payload: response.data })
      })
      .catch((e) => cb(errorHandling(e)))
  }
}


export const limparInscricao = () => ({ type: LIMPAR_INSCRICAO });


