import React, { Component } from 'react';
import { getHeaders } from '../../actions/localStorage'
import InputLogin from '../../components/Inputs/Login';
import Checkbox from '../../components/Inputs/Checkbox';
import Button from '../../components/Button/Simples';
import AlertDanger from '../../components/Alert/Danger'
import toast, { Toaster } from 'react-hot-toast';

import '../../../css/index.css'

import { connect } from 'react-redux';
import * as actions from '../../actions/'
import { api, versao } from '../../config'
import { onKeyDown } from '../../components/Inputs/FunctonEnter';

class AlterarSenha extends Component {
  state = {
    password: '',
    erros: {}
  }

  onChangeInput = (field, ev) => {
    this.setState({ [field]: ev.target.value })
    this.validate()
  }

  notifyError = (erros) => {
    toast.error(erros, {
      duration: 4000,
      position: 'top-right',
      style: {
        border: '1px solid #713200',
        fontSize: '14px',
        color: '#713200',
      },
    })
  }

  notifySuccess = (msg) => {
    toast.success(msg, {
      duration: 4000,
      position: 'top-right',
      style: {
        border: '1px solid #713200',
        fontSize: '14px',
        color: '#713200',
      },
    })
    setTimeout(function () {
      window.location = '/'
    }, 4000)
  }


  saveToken = (usuario) => {
    if (!usuario.data.token) return null;
    const [token1, token2, token3] = usuario.data.token.split('.')
    localStorage.setItem("token1", token1);
    localStorage.setItem("token2", token2);
    localStorage.setItem("token3", token3);
  }

  handleAlterarSenha = () => {
    const { password } = this.state
    this.props.updateUser({ password }, (error) => {
      if (error) return this.notifyError(error.message)
      this.notifySuccess('Senha alteada com sucesso!')
    })
  }

  validate() {
    const { password } = this.state;
    const erros = {};
    if (!password) erros.password = 'Preencha aqui com sua senha'
    this.setState({ erros });
    return !(Object.keys(erros).length > 0);
  }


  render() {

    const { password, erros } = this.state;
    return (
      <div className='Login flex flex-center'>
        <Toaster />
        <div>
          <AlertDanger error={erros.form} />

          <InputLogin
            className='input-login'
            label='Password'
            placeHolder={'Digite sua nova senha'}
            value={password}
            error={erros.password}
            type='password'
            onKeyUp={(event) => onKeyDown(event, this.handleAlterarSenha)}
            onChange={(ev) => this.onChangeInput('password', ev)} />
          <br /> <br />
          <div className='btn-login flex flex-center'>
            <Button label='Salvar' type='login' label={<i className='fa fa-paper-plane' />} onClick={() => this.handleAlterarSenha()} />
          </div>
        </div>
      </div>
    )
  }
}

export default connect(null, actions)(AlterarSenha);
