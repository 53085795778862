import React, { Component } from 'react';
import { Provider } from 'react-redux';
import store from './store';

import { BrowserRouter, Switch, HashRouter as Router, Route } from 'react-router-dom'

import base from './containers/HOC/Base';
import baseInscricao from './containers/HOC/BaseInscricao'
import baseCandidato from './containers/HOC/BaseCandidato'
import baseAdm from './containers/HOC/BaseAdm';
import noAuth from './containers/HOC/NoAuth';

import Login from './containers/Login';
import AlterarSenha from './containers/Login/alterarSenha';

import { initApp } from './actions'

// CONTAINER COM BASE
import Inscricao from './containers/Inscricao'
import NovaInscricaoIndex from './containers/Inscricao/NovaInscricao'

//BASE CANDIDATO
import Candidato from './containers/Candidato'
import FimInscricao from './containers/Inscricao/NovaInscricao/FimInscricao'
import AreaCandidato from './containers/areaCandidato/'
import UploadDocs from './containers/areaCandidato/uploadDocs/'

//BASE ADM
import Adm from './containers/areaAdm/'
import Inscricoes from './containers/areaAdm/inscricoes'
import ListaAptos from './containers/areaAdm/listaAptos'
import ListaIntegrais from './containers/areaAdm/listaIntegrais'
import ListaParciais from './containers/areaAdm/listaParciais'
import ListaReservas from './containers/areaAdm/listaReservas'
import ListaDesclassificado from './containers/areaAdm/listaDesclassificado'
import DetalhesCandidato from './containers/areaAdm/detalhesCandidato'
import GerarAptos from './containers/areaAdm/gerarAptos';
import DetalhesInscrito from './containers/areaAdm/detalhesInscrito';
import DesclassificaCanditado from './containers/areaAdm/desclassificaCandidato';

export default class App extends Component {

  componentDidMount() {
    initApp();
  }

  render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <Switch>
            <Route path={'/'} exact component={noAuth(Candidato)} />
            <Route path={'/inscricao/novainscricao'} exact component={baseInscricao(NovaInscricaoIndex)} />
            <Route path={'/candidato'} exact component={baseCandidato(AreaCandidato)} />
            <Route path={'/candidato/upload'} exact component={baseCandidato(UploadDocs)} />
            <Route path={'/inscricoes/ficha/:id'} component={base(Inscricao)} />
            <Route path={'/alterarSenha'} exact component={base(AlterarSenha)} />
            <Route path={'/login'} exact component={noAuth(Login)} />
            <Route path={'/adm/'} exact component={baseAdm(Adm)} />
            <Route path={'/adm/gerarAptos'} exact component={baseAdm(GerarAptos)} />
            <Route path={'/adm/aptos'} exact component={baseAdm(ListaAptos)} />
            <Route path={'/adm/inscricoes'} exact component={baseAdm(Inscricoes)} />
            <Route path={'/adm/integrais'} exact component={baseAdm(ListaIntegrais)} />
            <Route path={'/adm/parciais'} exact component={baseAdm(ListaParciais)} />
            <Route path={'/adm/reservas'} exact component={baseAdm(ListaReservas)} />
            <Route path={'/adm/desclassificado'} exact component={baseAdm(ListaDesclassificado)} />
            <Route path={'/adm/inscrito/:id'} exact component={baseAdm(DetalhesInscrito)} />
            <Route path={'/adm/detalhes/:id'} exact component={baseAdm(DetalhesCandidato)} />
            <Route path={'/adm/desclassifica/:id'} exact component={baseAdm(DesclassificaCanditado)} />
            <Route path={'/FimInscricao'} exact component={noAuth(FimInscricao)} />

          </Switch>
        </BrowserRouter>
      </Provider>
    );
  }
}
