import React from 'react'

const SubFooter = () => (
  <div className="SubFooter flex horizontal full-width">
    <div className='Duvidas flex-1 left'>

      <b>Dúvidas:</b><br />
      <i className="fa fa-phone" aria-hidden="true" /> (62) 3902-2034
      <br />
      <i className="fa fa-envelope" aria-hidden="true" /> graduacao@anapolis.go.gov.br
    </div>

    <div className='flex-1'>
      <p>SECRETARIA MUNICIPAL DE<br />
        <b>DESENVOLVIMENTO SOCIAL,<br />
          TRABALHO, EMPREGO E RENDA</b></p>
      <p></p>
      <p></p>
    </div>

    <div className='flex-1'>
      <img alt='logo-anapolis' style={{ width: '80%' }} src='/img/logo-gov-anapolis-eleicao.png' />
    </div>

    <div className='Endereco flex-1 right'>
      <p><b>Endereço:</b><br />
        Rua General Joaquim Inácio,<br />
        esq. c/ Travessa João Bruta<br />
        Centro - Anápolis - GO</p>
    </div>
    <br /><br /><br />
  </div>
)

export default SubFooter;
