import React, { Component } from 'react';

import NovaInscricao from './inscricao'

class NovaInscricaoIndex extends Component {

  constructor(props) {
    super();
    this.state = { cpf: '', inscricao: '', vacinas: '' };
  }

  componentDidMount() {
    const propsCpf = this.props.location.state
    const propsInscricoes = this.props.location.state.inscricao
    this.setState(propsCpf ? { cpf: propsCpf.cpf } : { cpf: '' })
    this.setState(propsInscricoes ? { inscricao: propsInscricoes.inscricoes, vacinas: propsInscricoes.vacinas } : { inscricao: '', vacinas: '' })
  }
  render() {
    return (
      <div style={{ margin: '0 auto' }}>
        {this.state.cpf !== '' &&
          <NovaInscricao cpf={this.state.cpf} inscricao={this.state.inscricao} vacinas={this.state.vacinas} setCpf={this.state.cpf} />}
      </div>

    )
  }
}

export default NovaInscricaoIndex
