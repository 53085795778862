import React, { Component } from 'react';
import Button from '../../components/Button/Simples';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import InputSimples from '../../components/Inputs/Simples'
import { connect } from 'react-redux';
import * as actions from '../../actions/'
import BarraTopo from '../Base/BarraTopo'
import Footer from '../Base/Footer';
import Logo from '../Base/logo'
import CryptoJS from 'crypto-js'
import errorHandling from '../../actions/errorHandling'
import { api, versao } from '../../config';
import { getHeaders } from '../../actions/localStorage'
import { Redirect } from 'react-router'
import { onKeyDown } from '../../components/Inputs/FunctonEnter';

class Candidato extends Component {

  state = {
    open: false,
    cpf: '',
    inscricao: {
      nome: '',
      cpf: '',
      rg: '',
      sexo: '',
      dataNascimento: '',
      estadoCivil: '',
      email: '',
      escolaridade: '',
      nomeDaMae: '',
    },
    cpfEncoded: '',
    redirect: false,
    erros: {}
  }

  componentDidMount() {
    const { getUser, authorized, history, usuario } = this.props;
    getUser();
    // if (!authorized || !usuario || !usuario.permissao.includes('Adm')) history.replace('/candidato');
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  ModalSimples(props) {
    const mCPF = (cpf) => {
      cpf = cpf.replace(/\D/g, "")
      cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2")
      cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2")
      cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
      return cpf
    }

    const ValidaCPF = () => {
      var RegraValida = this.state.cpf;
      var cpf = RegraValida.trim();

      cpf = cpf.replace(/\./g, '');
      cpf = cpf.replace('-', '');
      cpf = cpf.split('');

      var v1 = 0;
      var v2 = 0;
      var aux = false;

      for (var i = 1; cpf.length > i; i++) {
        if (cpf[i - 1] != cpf[i]) {
          aux = true;
        }
      }

      if (aux == false) {
        return false;
      }

      for (var i = 0, p = 10; (cpf.length - 2) > i; i++, p--) {
        v1 += cpf[i] * p;
      }

      v1 = ((v1 * 10) % 11);

      if (v1 == 10) {
        v1 = 0;
      }

      if (v1 != cpf[9]) {
        return false;
      }

      for (var i = 0, p = 11; (cpf.length - 1) > i; i++, p--) {
        v2 += cpf[i] * p;
      }

      v2 = ((v2 * 10) % 11);

      if (v2 == 10) {
        v2 = 0;
      }

      if (v2 != cpf[10]) {
        return false;
      } else {
        return true;
      }
    }
    const validate = () => {
      const cpf = this.state.cpf;
      const erros = {};
      if (!cpf) erros.cpf = 'Preencha aqui com seu cpf'
      if (!ValidaCPF()) erros.validaCpf = 'CPF inválido'
      this.setState({ erros });
      return !(Object.keys(erros).length > 0);
    }

    const buscarCpf = () => {
      const cpf = this.state.cpf;
      if (!validate()) return;

      var encrypted = CryptoJS.AES.encrypt('teste', "Secret Passphrase");

      api.get(`/api/v1/inscricoes/search/${cpf}`, getHeaders())
        .then(response => {
          if (response.data.inscricoes) {
            let inscricao = response.data.inscricoes
            let vacinas = response.data.vacinas
            api.get(`/api/v1/inscricoes/cadastrado/${cpf}`, getHeaders())
              .then(response2 => {
                if (response2.data.inscricoes) {
                  alert('Cadastro já Existe')
                  this.setState({ open: false, cpf: '', redirect: false, })
                }
                else {
                  if (inscricao.idade < 60) {
                    alert('Idade não permitida')
                    this.setState({ open: false, cpf: '' })
                  }

                  else {
                    this.setState({ redirect: true, inscricao: response.data })
                  }
                }


              })
          }
          else { this.setState({ redirect: true, cpfEncoded: encrypted }) }
        })
        .catch(errorHandling)
    }
    return (
      <div className='Teste-Modal'>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className='Modal'
          open={this.state.open}
          onClose={this.handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.open}>
            <div className='Card-Modal'>
              <h2 id="transition-modal-title">Informe seu CPF</h2>
              <InputSimples
                placeHolder={'Informe seu cpf...'}
                id='RegraValida'
                name='RegraValida'
                type={'cpf'}
                maxLength={'14'}
                value={this.state.cpf}
                onKeyUp={(event) => onKeyDown(event, buscarCpf)}
                onChange={(ev) => this.setState({ cpf: mCPF(ev.target.value) }, () => validate())}
                error={this.state.erros.validaCpf}
              /><br />
              <div className='Botao-Modal flex flex-center'>
                <Button
                  type='modal'
                  onClick={() => buscarCpf()}
                  label={'Iniciar'} />
              </div>
            </div>
          </Fade>
        </Modal>
      </div>
    )

  }

  render() {
    const { cpf, cpfEncoded, inscricao } = this.state;
    if (this.state.redirect) {
      return <Redirect to={{
        pathname: "/inscricao/novainscricao", state: { cpf: cpf, inscricao: this.state.inscricao }
      }} />
    }
    let edital = 'https://cdn.anapolis.go.gov.br/editais/edital_centro_dia_idoso.pdf'
    return (
      <div>
        <BarraTopo />
        <div className='flex flex-center'>
          <Logo />
        </div>
        <div className="Candidato full-width" >
          A inscrição no Programa Centro dia do Idoso é composta por 4 etapas.<br /><br />

          Estas etapas serão disponibilizadas à medida que os campos apresentados forem preenchidos. Esta inscrição somente será recebida no banco de dados após a conclusão de todas as etapas. Caso o preenchimento seja abandonado sem a finalização, será necessário iniciar um novo preenchimento.<br /><br />

          Alguns campos serão mostrados com <b>* (asterisco)</b>, isto quer dizer que são de preenchimento <b>obrigatório e indispensável</b> às etapas subsequentes e a conclusão da inscrição.<br />
          <div className='alert alert-danger '>
            <b>
              Para o preenchimento das vagas do Centro Dia poderão se inscrever as pessoas que atenderem os seguintes requisitos:<br /><br />
              I- Pessoas idosas, com idade a partir de 60 (sessenta) anos;<br />
              II- Idosos em situação de dependência, vulnerabilidade e ou risco por violação de direitos;<br />
              III- Possuírem o cartão de vacina atualizado com as vacinas contra a COVID-19;<br />
              IV- Idosos com inscrição atualizada no CAD Único;<br />
              V- Ser pertencente a um grupo familiar que possua renda per capta mensal de até 1 (um) salário mínimo  e esteja em situação de vulnerabilidade social;<br />
              VI- Não serem assistidos por seus familiares durante o dia ou parte dele, em razão desses terem que sair para o trabalho, ou não possuírem responsáveis pelos seus cuidados.<br />
              VII- Residirem no Município de Anápolis há no mínimo 2 (dois) anos<br /><br />
            </b>
          </div>
          Caso se enquadre nos requisitos acima, para iniciar o preenchimento, clique sobre o botão <b>INICIAR</b>, na parte inferior desta página. Para concluir o preenchimento, na etapa <b>FINALIZAR</b>, clique sobre o botão <b>SALVAR</b>.<br /><br />

          <b>Após a conclusão da inscrição não é possível alterar os dados, para isso solicitamos que as informações sejam revisadas antes da conclusão.<br /><br /></b>
          <div className='alert h1Inicio alert-danger '>
            <b>Não se esqueça de ler o <a href={edital}> EDITAL </a>do programa antes de realizar sua inscrição</b>
          </div>
          <div className='alert h1Inicio alert-danger '>
            <b>Preencha todos os campos obrigatórios.</b>
            <br />
            <b>Todas as publicações oficiais serão feitas através do Diário Oficial do Município, localizado no site da
              <a href="https://www.anapolis.go.gov.br/"> Prefeitura de Anápolis</a>.</b>
          </div>

          <Button type='btn btn-danger' label='Inscriçóes Encerradas' />
          <div className='Modal flex flex-center'>
            {this.ModalSimples()}
          </div>

        </div>
        <Footer />
      </div>

    )
  }
}




const mapStateToProps = state => ({
  usuario: state.auth.usuario
})
export default connect(mapStateToProps, actions)(Candidato)
