import React from 'react'
import { Link } from 'react-router-dom'
import { getHeaders, cleanToken } from '../../actions/localStorage'

function funcaoLogout() {
  cleanToken()
  setTimeout(function () {
    window.location = '/'
  }, 500)
}
const BarraTopo = ({ handleLogout }) => (
  <div className="Barra-Topo flex horizontal full-width">
    <div className='flex horizontal flex-end'>
      {getHeaders().headers.authorization === 'Bearer null' ?
        '' :
        <Link to='/'>
          <i className="fas fa-home" />
          <span>Início</span>
        </Link>}
      {getHeaders().headers.authorization === 'Bearer null' ?
        ''
        :
        <Link to='/alterarSenha'>
          <i className="fas fa-user" />
          <span>Alterar Senha</span>
        </Link>}
      {getHeaders().headers.authorization === 'Bearer null' ?
        (<div>
          <span><Link to='/'><i className="fa fa-home" aria-hidden="true"></i> Início</Link></span>
          <span><a href='https://cdn.anapolis.go.gov.br/editais/edital_centro_dia_idoso.pdf' target='_blank'><i className="fa fa-file-text-o" aria-hidden="true"></i> Edital </a></span>

          <span><Link to='/login'><i className="fa fa-sign-in" aria-hidden="true"></i> Login</Link></span>
        </div>)
        :
        <span onClick={() => funcaoLogout()}><i className="fa fa-sign-out" aria-hidden="true"></i> Sair</span>
      }

    </div>
  </div>
)

export default BarraTopo
