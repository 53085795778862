import React, { Component } from "react";
import { api } from '../../../config/'
import { getHeaders } from '../../../actions/localStorage'
import UploadSimples from '../../../components/Upload/'
import { CircularProgressbar } from 'react-circular-progressbar';
import { MdCheckCircle, MdError, MdLink, MdMoodBad } from "react-icons/md";
import 'react-circular-progressbar/dist/styles.css';

export default class DocsBens extends Component {

    state = {
        progress: 0,
        docs_bens_declaracao: 0,
        docs_bens_iptu: 0,
        docs_bens_veiculo: 0,
        file: '',
        categoria: 'docs_bens',
        inscricaoDB: '',
        progressSubCategoria: []
    }

    //CARREGAR ARQUIVO
    handleChangeFile = async event => {
        const { categoria } = this.state
        const { inscricao } = this.props
        const { name } = event.target

        this.setState({ progress: 0 })
        var file = event.target.files[0];
        if (file && file.type === 'application/pdf') {
            this.setState({ file });
        } else {
            alert('Extensão não suportada, selecione outro arquivo')
            this.setState({ file }); // storing file
        }



        const formData = new FormData();
        formData.append('file', file)
        const tokenStr = getHeaders().headers.authorization

        const myHeaders = {
            'Content-Type': 'multipart/form-data',
            "Authorization": tokenStr
        }
        var cpf2 = inscricao.cpf;
        cpf2 = cpf2.replace(".", "");
        cpf2 = cpf2.replace(".", "");
        cpf2 = cpf2.replace("-", "");


        await api.put(`/api/v1/inscricoes/docs?categoria=${categoria}&subcategoria=${name}&cpf=${cpf2}`, formData, {

            headers: myHeaders,
            onUploadProgress: data => {
                //Set the progress value to show the progress bar 
                this.setState({ [name]: Math.round((100 * data.loaded) / data.total) })
                this.setState({ progress: Math.round((100 * data.loaded) / data.total) })
            },
        }).then(res => {
            this.setState({ inscricaoDB: res.data.inscricao })
        }).then(() => window.location.reload(true))
            .catch(err => console.log(err))
    }


    render() {

        const { file, progress, inscricaoDB, progressCategoria, categoria } = this.state
        const { inscricao, arquivos } = this.props
        var cpf2 = inscricao.cpf;
        cpf2 = cpf2.replace(".", "");
        cpf2 = cpf2.replace(".", "");
        cpf2 = cpf2.replace("-", "");
        return (
            <div className='col-md-12 row'>
                <div className='Input-Upload col-md-6 horizontal' style={{ placeContent: 'space-around', marginTop: '30px' }}>
                    <UploadSimples
                        onChange={this.handleChangeFile}
                        arquivos={arquivos}
                        span='Declaração dos bens do grupo familiar'
                        file={file}
                        inscricao={inscricao}
                        name={'docs_bens_declaracao'}
                        inscricaoDB={inscricaoDB ? inscricaoDB : ''}
                        cpf2={cpf2}
                        categoria={categoria}
                        progress={progress}
                        progressCategoria={this.state.docs_bens_declaracao}
                    />

                    <UploadSimples
                        onChange={this.handleChangeFile}
                        arquivos={arquivos}
                        span='Comprovante de IPTU 2019,2020,2021 de todos os imóveis'
                        file={file}
                        inscricao={inscricao}
                        name={'docs_bens_iptu'}
                        inscricaoDB={inscricaoDB ? inscricaoDB : ''}
                        cpf2={cpf2}
                        categoria={categoria}
                        progress={progress}
                        progressCategoria={this.state.docs_bens_iptu}
                    />
                </div>

                <div className='Input-Upload col-md-6 horizontal' style={{ placeContent: 'space-around', marginTop: '30px' }}>
                    <UploadSimples
                        onChange={this.handleChangeFile}
                        arquivos={arquivos}
                        span='Documentos de proprietário e/ou comprovante de financiamento do veículo'
                        file={file}
                        inscricao={inscricao}
                        name={'docs_bens_veiculo'}
                        inscricaoDB={inscricaoDB ? inscricaoDB : ''}
                        cpf2={cpf2}
                        categoria={categoria}
                        progress={progress}
                        progressCategoria={this.state.docs_bens_veiculo}
                    />

                    <div className='Input-Upload-Item col-md-6 vertical' style={{ width: '30%', padding: '20px' }}>

                    </div>
                </div>
            </div>

        )
    }
}