import React, { Component } from 'react'
import { connect } from 'react-redux';
import moment from 'moment'
import * as actions from '../../actions/inscricoes'
import { maskMonetaria } from '../../config/mask'
import ListaArquivos from './listaArquivos'
import toast, { Toaster } from 'react-hot-toast';

class DetalhesCandidato extends Component {

  async componentDidMount() {
    const query = window.location.pathname;
    const partes = query.split('/');
    await this.props.getInscricaoAdm(parseInt(partes[3]))
    await this.props.getIntegrantesAdm(parseInt(partes[3]))

  }


  render() {
    const { inscricao, integrantes, apto } = this.props
    const notifyError = (erros) => {

      toast.error(erros, {
        duration: 4000,
        position: 'top-right',
        style: {
          border: '1px solid #713200',
          fontSize: '14px',
          color: '#713200',
        },
      })
    }

    const notifySuccess = (msg) => {

      toast.success(msg, {
        duration: 4000,
        position: 'top-right',
        style: {
          border: '1px solid #713200',
          fontSize: '14px',
          color: '#713200',
        },
      })
      setTimeout(function () {
        window.location = '/'
      }, 1000)
    }

    return (
      <div>
        <Toaster />
        <div className='Span-Container'>
          <span>Dados Pessoais</span>
        </div>

        <table className="tg">
          <thead>
            <tr>
              <td className="tg-0lax negrito">NOME</td>
              <td className="tg-0lax" colSpan="5">{inscricao ? inscricao.nome : ''}</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="tg-0lax negrito">CPF</td>
              <td className="tg-0lax" colSpan="2">{inscricao ? inscricao.cpf : ''}</td>
              <td className="tg-0lax negrito">RG</td>
              <td className="tg-0lax" colSpan="2">{inscricao ? inscricao.rg : ''}</td>
            </tr>
            <tr>
              <td className="tg-0lax negrito">SEXO</td>
              <td className="tg-0lax">{inscricao ? (inscricao.sexo == 'M' ? 'MASCULINO' : 'FEMININO') : ''}</td>
              <td className="tg-0lax negrito">DATA DE NASCIMENTO</td>
              <td className="tg-0lax">{inscricao ? moment(inscricao.dataNascimento).format('DD/MM/YYYY') : ''}</td>
              <td className="tg-0lax negrito">ESTADO CIVIL</td>
              <td className="tg-0lax">{inscricao ? inscricao.estadoCivil : ''}</td>
            </tr>
            <tr>
              <td className="tg-0lax negrito">EMAIL</td>
              <td className="tg-0lax" colSpan="5">{inscricao ? inscricao.email : ''}</td>
            </tr>
            <tr>
              <td className="tg-0lax negrito">MÃE</td>
              <td className="tg-0lax" colSpan="2">{inscricao ? inscricao.nomeDaMae : ''}</td>
              <td className="tg-0lax negrito">PAI</td>
              <td className="tg-0lax" colSpan="2">{inscricao ? inscricao.nomeDoPai : ''}</td>
            </tr>
            <tr>
              <td className="tg-0lax negrito">CPF DA MÃE</td>
              <td className="tg-0lax" colSpan="2">{inscricao ? inscricao.cpfDaMae : ''}</td>
              <td className="tg-0lax negrito">CPF DO PAI</td>
              <td className="tg-0lax" colSpan="2">{inscricao ? inscricao.cpfDoPai : ''}</td>
            </tr>
            <tr>
              <td className="tg-0lax negrito">NATURALIDADE</td>
              <td className="tg-0lax" colSpan="2">{inscricao ? inscricao.naturalidade : ''}</td>
              <td className="tg-0lax negrito">RENDA MENSAL</td>
              <td className="tg-0lax" colSpan="2">{inscricao ? maskMonetaria(inscricao.rendaMensalTitular) : ''}</td>
            </tr>
            <tr>
              <td className="tg-0lax negrito">RESP. FINANCEIRO</td>
              <td className="tg-0lax" colSpan="2">{inscricao ? (inscricao.responsavelFinanceiro === false ? 'NÃO' : 'SIM') : ''}</td>
              <td className="tg-0lax negrito">POSSUI DEFICIÊNCIA</td>
              <td className="tg-0lax" colSpan="2">{inscricao ? ((inscricao.pessoaComDeficiencia === false || inscricao.pessoaComDeficiencia === null) ? 'NÃO' : 'SIM') : ''}</td>
            </tr>
            <tr>
              <td className="tg-0lax negrito">CLASSIFICAÇÃO DEFICIENCIA</td>
              <td className="tg-0lax" colSpan="2">{inscricao ? inscricao.classificacaoDeficiencia : ' - '}</td>
              <td className="tg-0lax negrito">CID DEFICIENCIA</td>
              <td className="tg-0lax" colSpan="2">{inscricao ? inscricao.cidDeficiencia : ' - '}</td>
            </tr>
          </tbody>
        </table>

        <div className='Span-Container'>
          <span>Dados de Saúde</span>
        </div>

        <table className="tg">
          <thead>
            <tr>
              <td className="tg-0lax negrito">RECEBE ALGUM BENEFICIO</td>
              <td className="tg-0lax" colSpan="2">{inscricao ? (inscricao.beneficio === false ? 'NÃO' : 'SIM') : ''}</td>
              <td className="tg-0lax negrito">QUAL BENEFICIO</td>
              <td className="tg-0lax" colSpan="2">{inscricao ? inscricao.qualBeneficio : ' - '}</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="tg-0lax negrito">É ATENDIDO PELO CRAS/CREAS</td>
              <td className="tg-0lax" colSpan="2">{inscricao ? (inscricao.cras === false ? 'NÃO' : 'SIM') : ''}</td>
              <td className="tg-0lax negrito">QUAL CRAS/CREAS</td>
              <td className="tg-0lax" colSpan="2">{inscricao ? inscricao.qualCras : ' - '}</td>
            </tr>
            <tr>
              <td className="tg-0lax negrito">POSSUI ALGUMA DEFICIENCIA</td>
              <td className="tg-0lax" colSpan="2">{inscricao ? (inscricao.deficiencia === false ? 'NÃO' : 'SIM') : ''}</td>
              <td className="tg-0lax negrito">QUAL DEFICIENCIA</td>
              <td className="tg-0lax" colSpan="2">{inscricao ? inscricao.qualDeficiencia : ' - '}</td>
            </tr>
            <tr>
              <td className="tg-0lax negrito">CONSEGUE SE LOCOMOVER SOZINHO</td>
              <td className="tg-0lax" colSpan="2">{inscricao ? (inscricao.locomove === false ? 'NÃO' : inscricao.locomove === true ? 'NÃO' : 'SIM') : ' - '}</td>
              <td className="tg-0lax negrito">CONSEGUE SE COMUNICAR SOZINHO</td>
              <td className="tg-0lax" colSpan="2">{inscricao ? (inscricao.comunica === false ? 'NÃO' : inscricao.comunica === true ? 'NÃO' : 'SIM') : ' - '}</td>
            </tr>
            <tr>
              <td className="tg-0lax negrito">USA ALGUMA PROTESE/ORTESE</td>
              <td className="tg-0lax" colSpan="2">{inscricao ? (inscricao.protese === false ? 'NÃO' : 'SIM') : ''}</td>
              <td className="tg-0lax negrito">QUAL PROTESE/ORTESE</td>
              <td className="tg-0lax" colSpan="2">{inscricao ? inscricao.qualProtese : ' - '}</td>
            </tr>
            <tr>
              <td className="tg-0lax negrito">USA ALGUM APARELHO/EQUIPAMENTO PARA SE LOCOMOVER</td>
              <td className="tg-0lax" colSpan="2">{inscricao ? (inscricao.aparelho === false ? 'NÃO' : 'SIM') : ''}</td>
              <td className="tg-0lax negrito">QUAL APARELHO/EQUIPAMENTO</td>
              <td className="tg-0lax" colSpan="2">{inscricao ? inscricao.qualAparelho : ' - '}</td>
            </tr>
            <tr>
              <td className="tg-0lax negrito">PORTADOR DE ALGUMA DOENÇA CRONICA QUE FAZ/NECESSITA DE TRATAMENTO</td>
              <td className="tg-0lax" colSpan="2">{inscricao ? (inscricao.portadorDoenca === false ? 'NÃO' : 'SIM') : ''}</td>
              <td className="tg-0lax negrito">QUAL DOENÇA</td>
              <td className="tg-0lax" colSpan="2">{inscricao ? inscricao.qualDoenca : ' - '}</td>
            </tr>
            <tr>
              <td className="tg-0lax negrito">FAZ USO CONTINUO E/OU CONTROLADO DE ALGUMA MEDICAÇÃO</td>
              <td className="tg-0lax" colSpan="2">{inscricao ? (inscricao.usoContinuo === false ? 'NÃO' : 'SIM') : ''}</td>
              <td className="tg-0lax negrito">QUAL MEDICAÇÃO</td>
              <td className="tg-0lax" colSpan="2">{inscricao ? inscricao.qualUsoContinuo : ''}</td>
            </tr>
          </tbody>
        </table>


        <div className='Span-Container'>
          <span>Dados de Seleção</span>
        </div>

        <table className="tg">
          <thead>
            <tr>
              <td className="tg-0lax negrito">APTO</td>
              <td className="tg-0lax" colSpan="2">{apto ? (apto.apto === false ? 'NÃO' : 'SIM') : '-'}</td>
            </tr>
            <tr hidden={apto ? ((apto.apto === true) ? true : false) : ''}>
              <td className="tg-0lax negrito">MOTIVO ELIMINAÇÃO</td>
              <td className="tg-0lax" colSpan="5">{apto ? apto.motivo_eliminacao : '-'}</td>
            </tr>
            <tr hidden={apto ? ((apto.apto === true) ? false : true) : ''}>
              <td className="tg-0lax negrito">TOTAL PONTUAÇÃO</td>
              <td className="tg-0lax" colSpan="5">{apto ? inscricao.pontuacao_total : '-'}</td>
            </tr>
          </thead>
        </table>

        <div className='Span-Container'>
          <span>Dados de moradia</span>
        </div>

        <table className="tg">
          <thead>
            <tr>
              <td className="tg-0lax negrito">LOGRADOURO</td>
              <td className="tg-0lax" colSpan="5">{inscricao ? inscricao.logradouro : ''} </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="tg-0lax negrito">NUMERO</td>
              <td className="tg-0lax">{inscricao ? inscricao.numero : ''}</td>
              <td className="tg-0lax negrito">QUADRA</td>
              <td className="tg-0lax">{inscricao ? inscricao.quadra : ''}</td>
              <td className="tg-0lax negrito">LOTE</td>
              <td className="tg-0lax">{inscricao ? inscricao.lote : ''}</td>
            </tr>
            <tr>
              <td className="tg-0lax negrito">COMPLEMENTO</td>
              <td className="tg-0lax" colSpan="2">{inscricao ? inscricao.complemento : ''}</td>
              <td className="tg-0lax negrito">PONTO REFERENCIA</td>
              <td className="tg-0lax" colSpan="2">{inscricao ? inscricao.pontoReferencia : ''}</td>
            </tr>
            <tr>
              <td className="tg-0lax negrito">BAIRRO</td>
              <td className="tg-0lax">{inscricao ? inscricao.bairro : ''}</td>
              <td className="tg-0lax negrito">CIDADE</td>
              <td className="tg-0lax">{inscricao ? inscricao.cidade : ''}</td>
              <td className="tg-0lax negrito">ESTADO</td>
              <td className="tg-0lax">GO</td>
            </tr>
            <tr>
              <td className="tg-0lax negrito">CEP</td>
              <td className="tg-0lax" colSpan="2">{inscricao ? inscricao.cep : ''}</td>
              <td className="tg-0lax negrito">DATA RESIDE ANÁPOLIS</td>
              <td className="tg-0lax" colSpan="2">{inscricao ? moment(inscricao.dataResideAnapolis).format('DD/MM/YYYY') : ''}</td>
            </tr>
            <tr>
              <td className="tg-0lax negrito">CELULAR</td>
              <td className="tg-0lax" colSpan="2">
                <a target='_blank' href={inscricao ? 'https://api.whatsapp.com/send?phone=55' + inscricao.celular : ''}><i className="fab fa-whatsapp"></i></a>
                {inscricao ? ' ' + inscricao.celular : ''}
              </td>
              <td className="tg-0lax negrito">TELEFONE CONTATO</td>
              <td className="tg-0lax" colSpan="2">{inscricao ? inscricao.telefoneContato : ''}</td>
            </tr>
          </tbody>
        </table>

        <div className='Span-Container'>
          <span>Dados de pontuação</span>
        </div>

        <table className="tg">
          <thead>
            <tr>
              <td className="tg-0lax negrito">PONTO RENDA</td>
              <td className="tg-0lax">{inscricao ? inscricao.pontos_renda_pc : ''} </td>
              <td className="tg-0lax negrito">PONTO IDOSO BENEFICIÁRIO DO BENEFÍCIO DE PRESTAÇÃO CONTINUADA BPC LOAS</td>
              <td className="tg-0lax">{inscricao ? inscricao.ponto_idoso_bpc : ''}</td>
              <td className="tg-0lax negrito">PONTO IDOSO ACOMPANHADO PELOS CRAS E CREAS</td>
              <td className="tg-0lax">{inscricao ? inscricao.pontos_cras_creas : ''}</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="tg-0lax negrito">PONTO IDOSO COM DOENÇA CRÔNICA E OU DIMINUIÇÃO DE MOBILIDADE</td>
              <td className="tg-0lax">{inscricao ? inscricao.pontos_idoso_doenca : ''}</td>
              <td className="tg-0lax negrito">PONTO IDOSO COM RESPONSÁVEL FAMILIAR DEFICIENTE</td>
              <td className="tg-0lax">{inscricao ? inscricao.pontos_idoso_def_resp : ''}</td>
              <td className="tg-0lax negrito">PONTO IDOSO COM RESIDÊNCIA ALUGADA</td>
              <td className="tg-0lax">{inscricao ? inscricao.pontos_aluguel : ''}</td>

            </tr>
            <tr>
              <td className="tg-0lax negrito">PONTO IDOSO QUE RESIDE SOZINHO</td>
              <td className="tg-0lax">{inscricao ? inscricao.pontos_idoso_reside_so : ''}</td>


            </tr>
            <tr>
              <td className="tg-0lax negrito">RENDA TOTAL</td>
              <td className="tg-0lax">{inscricao ? 'R$ ' + parseFloat(inscricao.rendaTotal).toFixed(2) : ''} </td>
              <td className="tg-0lax negrito">RENDA PER CAPITA</td>
              <td className="tg-0lax">{inscricao ? 'R$ ' + parseFloat(inscricao.rendaPC).toFixed(2) : ''} </td>
              <td className="tg-0lax negrito">TOTAL PONTOS</td>
              <td className="tg-0lax">{inscricao ? parseInt(inscricao.pontuacao_total).toFixed(2) : ''}</td>
            </tr>
          </tbody>
        </table>

        <div className='Span-Container'>
          <span>Dados dos integrantes</span>
        </div>


        {integrantes && integrantes.map(item => (
          <table className="tg integrantes">
            <thead>
              <tr>
                <td className="tg-0pky negrito">NOME</td>
                <td className="tg-0pky" colSpan="2">{item.nome}</td>
                <td className="tg-0lax negrito">CPF</td>
                <td className="tg-0lax" colSpan="2">{item.cpf}</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="tg-0pky negrito">DATA DE NASCIMENTO</td>
                <td className="tg-0pky">{item.dataNascimento}</td>
                <td className="tg-0lax negrito">DEFICIÊNCIA</td>
                <td className="tg-0lax">{item.pessoaComDeficiencia === true ? 'SIM' : 'NÃO'}</td>
                <td className="tg-0lax negrito">PARENTESCO</td>
                <td className="tg-0lax">{item.parentesco}</td>
              </tr>
              <tr>
                <td className="tg-0pky negrito">RG / CERTIDÃO</td>
                <td className="tg-0pky" colSpan="5">{item.rgCertidao}</td>
              </tr>
              <tr>
                <td className="tg-0pky negrito">PROFISSÃO</td>
                <td className="tg-0pky">{item.profissaoAtividade}</td>
                <td className="tg-0lax negrito">TIPO DA RENDA</td>
                <td className="tg-0lax">{item.tipoRenda}</td>
                <td className="tg-0lax negrito">RENDA</td>
                <td className="tg-0lax">{maskMonetaria(item.rendaBruta)}</td>
              </tr>
            </tbody>
          </table>
        ))


        }

        <div className='Span-Container'>
          <span>Documentos entregues</span>
        </div>


        <ListaArquivos notifyError={notifyError}
          notifySuccess={notifySuccess} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  inscricao: state.inscricoes.inscricao,
  apto: state.inscricoes.apto,
  integrantes: state.inscricoes.integrantes,
  usuario: state.auth.usuario
})
export default connect(mapStateToProps, actions)(DetalhesCandidato)