import React, { Component } from 'react';
import Login from './Login';
import BarraTopo from '../Base/BarraTopo';
import Logo from '../Base/logo';
import Footer from '../Base/Footer';
import SubFooter from '../Base/SubFooter';
export default class LoginIndex extends Component {
  render() {
    return (
      <div>
        <BarraTopo />
        <div className='flex flex-center'>
          <Logo />
        </div>
        <Login />
        <br /><br /><br /><br />
        <div className='flex flex-center'>
          <SubFooter />
        </div>
        <Footer />
      </div>

    )
  }
}