import React, { Component } from 'react';
import InputSimples from '../../../components/Inputs/Simples'
import Titulo from '../../../components/Texto/Titulo'
import { TextoSelect } from '../../../components/Texto/Dados'
import InputDate from '../../../components/Inputs/Date';
import InputSelect from '../../../components/Inputs/Select'

import { connect } from 'react-redux';
import * as actions from '../../../actions';
import axios from 'axios'

import { pesquisacep } from '../../../config/mask'

class DadosAcademicos extends Component {
  state = {
    cep: '',
    logradouro: '',
    numero: '',
    complemento: '',
    quadra: '',
    lote: '',
    bairro: '',
    cidade: '',
    pontoReferencia: '',
    dataResideAnapolis: '',
    celular: '',
    telefoneContato: '',
    erros: {},
    buscaCep: {},
    valorCep: {}
  }

  componentDidMount() {
    this.validate();
  }



  validate = () => {

    const { cep, codigo, telefoneContato, logradouroProps, celular, numero, quadra, lote, dataResideAnapolis, residenciaAlugada, resideSozinho } = this.props;
    const erros = {};

    if (!cep) erros.cep = 'Preencha aqui o cep';
    if (cep.length < 8) erros.cep = 'Preencha aqui o cep'
    if (!logradouroProps) erros.logradouroProps = 'Preencha o complemento';
    if ((!numero || numero == 0) && !quadra) erros.quadra = 'Preencha a quadra';
    if ((!numero || numero == 0) && !lote) erros.lote = 'Preencha o lote';
    if (!telefoneContato) erros.telefoneContato = 'Preencha um telefone para contato';
    if (!celular) erros.celular = 'Preencha com um telefone celular';
    if (resideSozinho === 'nenhum') erros.resideSozinho = 'Preencha se reside sozinho';
    if (residenciaAlugada === 'nenhum') erros.residenciaAlugada = 'Preencha com residencia Alugada';
    if (!dataResideAnapolis) erros.dataResideAnapolis = 'Preencha com a data que reside em Anápolis';
    this.setState({ erros });
    this.props.setErrosEndereco(erros)
    return !(Object.keys(erros).length > 0);
  }

  onChangeInput = (field, value) => this.setState({ [field]: value }, () => this.validate())
  handleChange = (value, formattedValue) => {
    this.props.setDataResideAnapolis(value)
    this.setState({
      dataResideAnapolis: value, // ISO String, ex: "2016-11-19T12:00:00.000Z"
      formattedValue: formattedValue // Formatted String, ex: "11/19/2016"
    });
  }

  render() {
    const teste = valor => {
      this.props.setLogradouro(valor.logradouro)
      this.props.setBairro(valor.bairro)
      this.props.setCidade(valor.localidade)
      this.setState({ valorCep: valor, logradouro: valor.logradouro })
    }
    const pesquisacep = (valor) => {
      axios.get(`https://viacep.com.br/ws/${valor}/json/`)
        .then(function (response) {
          teste(response.data)
        });
    }
    // { !erros.cep && this.setState({ buscaCep: pesquisacep(cep) }) }
    const {
      cep,
      telefoneContato,
      logradouroProps,
      quadra,
      lote,
      complemento,
      bairro,
      pontoReferencia,
      celular,
      dataResideAnapolis,
      cidade,
      numero,
      resideSozinho,
      residenciaAlugada
    } = this.props;
    const { valorCep, erros, logradouro } = this.state
    return (
      <div className='Inscricao full-width flex vertical'>
        <div className='Card-Inscricao'>
          <Titulo tipo='titulo-detalhe-inscricao' titulo='Informações da moradia' />
          <div className='Container-Inscricao'>
            <div className='Input-Inscricao flex-1 flex horizontal space-between'>

              <InputSimples
                className='flex-1'
                name='cep'
                label='CEP:'
                placeHolder='Digite o CEP... *'
                value={cep}
                onClick={() => pesquisacep(this.state.cep === '' ? cep : '')}
                error={erros.cep}
                onChange={(ev) => (this.onChangeInput('cep', ev.target.value), this.props.setCep(ev.target.value))}
              />
              <button onClick={() => pesquisacep(this.state.cep === '' ? cep : '')}><i className='fas fa-search' /></button>

              <InputSimples
                className='flex-1'
                id='logradouro'
                name='logradouro'
                label='Logradouro:'
                placeHolder='Digite o logradouro... *'
                value={logradouroProps}
                error={erros.logradouro}
                onChange={(ev) => (this.onChangeInput('logradouro', ev.target.value), this.props.setLogradouro(logradouro))}
              />
              <InputSimples
                className='flex-1'
                name='numero'
                id='numero'
                label='Número :'
                type='number'
                placeHolder='Digite o número'
                value={(numero)}
                onChange={(ev) => (this.onChangeInput('numero', ev.target.value), this.props.setNumero(ev.target.value))} />
            </div>
            <br />
            <div className='Input-Inscricao flex-1 flex horizontal space-between'>
              <InputSimples
                className='flex-1'
                name='complemento'
                label='Complemento :'
                placeHolder='Digite o complemento'
                value={complemento}
                error={erros.complemento}
                onChange={(ev) => (this.onChangeInput('complemento', ev.target.value), this.props.setComplemento(ev.target.value))} />

              <InputSimples
                className='flex-1'
                name='bairro'
                id='bairro'
                label='Bairro :'
                placeHolder='Digite o bairro'
                value={bairro}
                error={erros.bairro}
                onChange={(ev) => (this.onChangeInput('bairro', ev.target.value), this.props.setBairro(ev.target.value))} />
              <InputSimples
                className='flex-1'
                name='cidade'
                label='Cidade :'
                placeHolder='Digite a Cidade'
                value={cidade}
                error={erros.cidade}
                onChange={(ev) => (this.onChangeInput('cidade', ev.target.value), this.props.setCidade(ev.target.value))} />
            </div>

            <div className='Input-Inscricao flex-1 flex horizontal space-between'>
              <InputSimples
                className='flex-1'
                name='quadra'
                label='Quadra :'
                placeHolder='Digite a quadra'
                value={quadra}
                error={erros.quadra}
                onChange={(ev) => (this.onChangeInput('quadra', ev.target.value), this.props.setQuadra(ev.target.value))} />

              <InputSimples
                className='flex-1'
                name='lote'
                id='lote'
                label='Lote :'
                placeHolder='Digite o lote'
                value={lote}
                error={erros.lote}
                onChange={(ev) => (this.onChangeInput('lote', ev.target.value), this.props.setLote(ev.target.value))} />
              <InputSimples
                escondido
                className='flex-1'
              />
            </div>


            <br />
            <div className='Input-Inscricao flex-1 flex horizontal space-between'>
              <InputSimples
                className='flex-1'
                name='pontoReferencia'
                label='Ponto de referência :'
                placeHolder='Digite o Ponto de referência'
                value={pontoReferencia}
                error={erros.pontoReferencia}
                onChange={(ev) => (this.onChangeInput('pontoReferencia', ev.target.value), this.props.setPontoReferencia(ev.target.value))} />
              <TextoSelect
                chave='Desde quando reside em Anápolis'
                vertical
                error={erros.dataResideAnapolis}
                valor={(
                  <InputDate
                    form
                    value={dataResideAnapolis}
                    onChange={(ev) => (this.onChangeInput('dataResideAnapolis', ev.target.value), this.props.setDataResideAnapolis(ev.target.value))}

                  />
                )} />
              <InputSimples
                className='flex-1'
                name='celular'
                label='Celular :'
                placeHolder='Digite o Celular'
                value={celular}
                error={erros.celular}
                onChange={(ev) => (this.onChangeInput('celular', ev.target.value), this.props.setCelular(ev.target.value))} />
            </div>
            <br />
            <div className='Input-Inscricao flex-1 flex horizontal space-between'>
              <InputSimples
                className='flex-1'
                name='telefoneContato'
                label='Telefone para contato :'
                placeHolder='Digite o Telefone para contato'
                value={telefoneContato}
                error={erros.telefoneContato}
                onChange={(ev) => (this.onChangeInput('telefoneContato', ev.target.value), this.props.setTelefoneContato(ev.target.value))} />
            </div>
            <div className='Input-Inscricao flex-1 flex horizontal space-between'>
              <TextoSelect
                chave='Residencia Alugada ?*'
                valor={(
                  <InputSelect
                    name='residenciaAlugada'
                    onChange={(ev) => (this.onChangeInput('residenciaAlugada', ev.target.value), this.props.setResidenciaAlugada(ev.target.value))}
                    value={(residenciaAlugada) ? residenciaAlugada : false}
                    error={erros.residenciaAlugada}
                    opcoes={[
                      { label: 'Nenhum', value: 'nenhum' },
                      { label: 'SIM', value: true },
                      { label: 'NÃO', value: false }
                    ]} />
                )} />
              <TextoSelect
                chave='Idoso Reside Sozinho ?*'
                valor={(
                  <InputSelect
                    type='saude'
                    name='resideSozinho'
                    onChange={(ev) => (this.onChangeInput('resideSozinho', ev.target.value), this.props.setResideSozinho(ev.target.value))}
                    value={(resideSozinho) ? resideSozinho : false}
                    error={erros.resideSozinho}
                    opcoes={[
                      { label: 'Nenhum', value: 'nenhum' },
                      { label: 'SIM', value: true },
                      { label: 'NÃO', value: false }
                    ]} />
                )} />
            </div>
          </div>

        </div>
      </div >

    )
  }
}

export default connect(null, actions)(DadosAcademicos)
