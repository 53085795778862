import React, { Component } from 'react';

import { connect } from 'react-redux';
import * as actions from '../../actions/inscricoes';
import IntegranteFamiliar from './IntegranteFamiliar'
import toast, { Toaster } from 'react-hot-toast';
import Button from '@material-ui/core/Button';
import { api } from '../../config';
import Swal from 'sweetalert2'
import { getHeaders, cleanToken } from '../../actions/localStorage'

const notifyError = (erros) => {
  toast.error(erros, {
    duration: 4000,
    position: 'top-right',
    style: {
      border: '1px solid #713200',
      fontSize: '14px',
      color: '#713200',
    },
  })
}

const notifySuccess = (msg) => {
  toast.success(msg, {
    duration: 4000,
    position: 'top-right',
    style: {
      border: '1px solid #713200',
      fontSize: '14px',
      color: '#713200',
    },
  })
  cleanToken()
  setTimeout(function () {
    window.location = '/'
  }, 2000)
}

class AreaCandidato extends Component {

  async componentDidMount() {
    await this.props.getInscricao()
  }

  componentWillUnmount() {
    this.props.limparInscricao();
  }

  handleSubmit = (form, cb) => dispatch => {
    const { inscricao } = this.props
    const MySwal = Swal.fire({
      title: 'Tem certeza?',
      text: "Tem certeza que deseja finalizar sua inscrição? não sera possivel fazer nenhumae edição.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.isConfirmed) {
        const tokenStr = getHeaders().headers.authorization
        const myHeaders = {
          'Content-Type': 'application/json',
          "Authorization": tokenStr
        }
        console.log(tokenStr)
        api.post(`/api/v1/inscricoes/fimInscricao/${inscricao.cpf}`, {
          headers: myHeaders,
        })
          .then(response => notifySuccess('Inscrição finalizada com sucesso!'))
          .catch((e) => notifyError('Houve um problema com sua inscrição, tente novamente!' + e));
      }
    })
  };


  render() {

    const { inscricao } = this.props
    return (
      <div className='Inscricao full-width flex vertical'>
        <Toaster />
        {inscricao &&
          <p>Bem vindo(a) {inscricao.nome}</p>
        }
        <Button onClick={this.handleSubmit()} type="submit" variant="contained" style={{ backgroundColor: 'green', color: 'white', maxWidth: '30%' }}>
          Finalizar Inscrição
        </Button>
        <IntegranteFamiliar
          notifyError={notifyError}
          notifySuccess={notifySuccess} />

      </div>

    )
  }
}

const mapStateToProps = state => ({
  inscricao: state.inscricoes.inscricao,
  usuario: state.auth.usuario
})

export default connect(mapStateToProps, actions)(AreaCandidato)
