import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { gerarAptos, getInscrito } from './AdmAPI';

const initialState = {
    aptos: '',
    inscrito: [],
    isLoading: false,
    hasError: false,
}

export const gerarAptosAsync = createAsyncThunk(
    'aptos/gerarAptosAsync',
    async () => {
        const response = await gerarAptos();
        return response.data
    }
);

export const buscaInscritoAsync = createAsyncThunk(
    'aptos/buscaInscritoAsync',
    async (id) => {
        const response = await getInscrito(id);
        console.log(response.data)
        return response.data
    }
);

const aptosSlice = createSlice({
    name: 'aptos',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(gerarAptosAsync.pending, (state) => {
                state.isLoading = true;
                state.hasError = false;
            })
            .addCase(gerarAptosAsync.fulfilled, (state, action) => {
                state.isLoading = false;
                state.aptos = action.payload;
            })
            .addCase(gerarAptosAsync.rejected, (state) => {
                state.isLoading = false;
                state.hasError = true;
            })

            .addCase(buscaInscritoAsync.pending, (state, action) => {
                state.isLoading = true;
                state.hasError = false;
                state.inscrito = [];
            })
            .addCase(buscaInscritoAsync.fulfilled, (state, action) => {
                state.isLoading = false;
                state.inscrito = action.payload;
            })
            .addCase(buscaInscritoAsync.rejected, (state) => {
                state.isLoading = false;
                state.hasError = true;
            })
    },
});

export const selectAptos = (state) => state.aptos;

export default aptosSlice.reducer