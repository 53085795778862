import { combineReducers } from 'redux';

import authReducer from './auth_reducer';
import inscricaoReducer from './inscricao_reducer';
import aptosReducer from '../features/Adm/AdmSlice'

const reducers = combineReducers({
  auth: authReducer,
  inscricoes: inscricaoReducer,
  aptos: aptosReducer
});

export default reducers;
