import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%'
    },
    containerModal: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '100%'
    },
    modal: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
}));

export default function DatePickers({ label, value, onChange, onBlur, type, error }) {
    const classes = useStyles();

    return (
        <form className={type === 'modal' ? classes.containerModal : classes.container} noValidate>
            {error && (<small className='small-danger'>{error}</small>)}
            <TextField
                label={label}
                type="date"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                className={type === 'modal' ? classes.modal : classes.textField}
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </form>
    );
}