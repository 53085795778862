import React, { Component, fetchData } from "react";
import { api } from '../../../config/'
import { getHeaders } from '../../../actions/localStorage'
import UploadSimples from '../../../components/Upload/'
import { CircularProgressbar } from 'react-circular-progressbar';
import { MdCheckCircle, MdError, MdLink, MdMoodBad } from "react-icons/md";
import 'react-circular-progressbar/dist/styles.css';

export default class DocsIdentificacao extends Component {

    state = {
        progress: 0,
        docs_comprovante_inscricao_cad_unico: 0,
        docs_cartao_vacina: 0,
        docs_comprovante_endereco: 0,
        docs_rg: 0,
        docs_cpf: 0,
        docs_loas: 0,
        docs_certidao_casamento: 0,
        docs_extrato_bancario: 0,
        docs_carteira_trabalho: 0,
        docs_declaracao_ir: 0,
        docs_averbacao: 0,
        docs_extrato_beneficio: 0,
        docs_laudo_medico: 0,
        docs_laudo_deficiencia: 0,
        docs_beneficiario_pensao_inss: 0,
        docs_beneficiario_pensao_alimenticia: 0,
        file: '',
        categoria: 'docs_identificacao',
        inscricaoDB: '',
        progressSubCategoria: []
    }

    //CARREGAR ARQUIVO
    handleChangeFile = async event => {
        const { categoria } = this.state
        const { inscricao, changeData } = this.props
        const { name } = event.target

        this.setState({ progress: 0 })
        var file = event.target.files[0];
        if (file && file.type === 'application/pdf') {
            this.setState({ file });
        } else {
            alert('Extensão não suportada, selecione outro arquivo')
            this.setState({ file }); // storing file
        }



        const formData = new FormData();
        formData.append('file', file)
        const tokenStr = getHeaders().headers.authorization

        const myHeaders = {
            'Content-Type': 'multipart/form-data',
            "Authorization": tokenStr
        }
        var cpf2 = inscricao.cpf;
        cpf2 = cpf2.replace(".", "");
        cpf2 = cpf2.replace(".", "");
        cpf2 = cpf2.replace("-", "");


        await api.put(`/api/v1/inscricoes/docs?categoria=${categoria}&subcategoria=${name}&cpf=${cpf2}`, formData, {

            headers: myHeaders,
            onUploadProgress: data => {
                //Set the progress value to show the progress bar 
                this.setState({ [name]: Math.round((100 * data.loaded) / data.total) })
                this.setState({ progress: Math.round((100 * data.loaded) / data.total) })
            },
        }).then(res => {
            this.setState({ inscricaoDB: res.data.inscricao })
        }).then(() => window.location.reload(true))
            .catch(err => console.log(err))
    }

    render() {
        const { file, progress, inscricaoDB, progressCategoria, categoria } = this.state
        const { inscricao, arquivos } = this.props
        var cpf2 = inscricao.cpf;
        cpf2 = cpf2.replace(".", "");
        cpf2 = cpf2.replace(".", "");
        cpf2 = cpf2.replace("-", "");

        let documentos =
            [
                [
                    "Comprovante de inscricao do CAD Unico atualizado",
                    "comprovante_inscricao_cad_unico "
                ],
                [
                    "Cartao de vacina atualizado",
                    "cartao_vacina "
                ],
                [
                    "Comprovante de endereco (agua energia telefone contrato de aluguel)",
                    "comprovante_endereco "
                ],
                [
                    "Carteira de Identidade fornecida pelos orgaos de Seguranca Publica das Unidades  da Federacao ou Carteira Nacional de Habilitacao",
                    "rg "
                ],
                [
                    "Cadastro de Pessoa Fisica- CPF",
                    "cpf "
                ],
                [
                    "Declaracao que possui o beneficio assistencial LOAS (declaracao emitida pelo INSS)",
                    "loas "
                ],
                [
                    "Copia da certidao de casamento do (a) idoso (a) e ou Declaracao de uniao estavel",
                    "certidao_casamento "
                ],
                [
                    "Extratos Bancarios dos ultimos 03 (tres) meses de todas as contas (nao apresentar comprovante de saldo em substituicao aos extratos bancarios) ",
                    "extrato_bancario "
                ],
                [
                    "Carteira de Trabalho: Copia das paginas nas quais constem a foto identificacao ultimo contrato de trabalho ou rescisao do contrato pagina subsequente em branco as observacoes em anotacoes gerais quando for o caso ",
                    "carteira_trabalho "
                ],
                [
                    "Declaracao de IR   2021 (referencia/ano-calendario 2020) com todas as paginas acompanhadas do recibo de entrega a Receita Federal do Brasil e quando  for o caso da restituicao a respectiva notificacao ",
                    "declaracao_ir "
                ],
                [
                    "Idoso divorciado ou separado judicialmente: Copia da Certidao de Casamento constando  a averbacao de separacao judicial com ou sem formal de partilha",
                    "averbacao "
                ],
                [
                    "Idoso aposentado deve apresentar o extrato de pagamento do beneficio do ultimo mes emitido pela internet no endereco eletronico  https://meu.inss.gov.br/#/login ou solicitar na agencia do INSS",
                    "extrato_beneficio "
                ],
                [
                    "Laudo medico com CID e atualizado com data maxima de 12 (doze) meses de expedicao em casos de idosos portadores de deficiencia com doenca cronica e ou diminuicao de mobilidade",
                    "laudo_medico "
                ],
                [
                    "O idoso com deficiencia que pretende fazer uso das prerrogativas do item 7.3 deste Edital deve apresentar laudo Medico constando o  CID  com no maximo 12 meses de expedicao informando a especie o grau ou nivel de deficiencia contendo a assinatura e carimbo com numero de inscricao no CRM do medico responsavel por sua emissao ou comprovacao de recebimento de beneficio do INSS em consequencia da deficiencia",
                    "laudo_deficiencia "
                ],
                [
                    "Beneficiario de Pensao do INSS: extratos bancarios dos ultimos 03 (tres) meses de pagamento do beneficio emitido pela Internet no Endereco eletronico www. mpas.gov.br ou solicitar na agencia do INSS. A apresentacao dos extratos bancarios nao dispensa a apresentacao do extrato de pagamento do beneficio e vice-versa",
                    "beneficiario_pensao_inss "
                ],
                [
                    "Beneficiario de Pensao alimenticia: extrato bancario que consta o deposito ou acordo/decisao judicial que delimite o valor",
                    "beneficiario_pensao_alimenticia "
                ]
            ]
        return (
            <div className='col-md-12 row'>
                {documentos.map((item, i) => {
                    let state = 'docs_' + item[1]
                    return (
                        <div key={i} className='Input-Upload col-md-6' style={{ placeContent: 'space-around', marginTop: '30px' }}>
                            <UploadSimples
                                onChange={this.handleChangeFile}
                                arquivos={arquivos}
                                span={item[0]}
                                file={file}
                                inscricao={inscricao}
                                name={state.trim()}
                                inscricaoDB={inscricaoDB ? inscricaoDB : ''}
                                cpf2={cpf2}
                                categoria={categoria}
                                progress={progress}
                                progressCategoria={this.state[state]}
                            />
                        </div>
                    )
                })}
            </div>

        )
    }
}