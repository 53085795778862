import React, { Component } from 'react'
import Titulo from '../../components/Texto/Titulo'
import { InscricoesGrid } from '../../components/Tabela/MaterialUIAptos'
import { connect } from 'react-redux';
import * as actions from '../../actions/inscricoes'
import { DataTableIntegrais } from '../../components/Exports/exportsIntegrais';

/* eslint eqeqeq: "off", "no-unused-vars": "off", curly: "error" */

class AreaAdm extends Component {

  state = {
    selectionModel: []
  }

  async componentDidMount() {
    await this.props.getInscricoesIntegrais();
  }

  render() {
    const { inscricao } = this.props.inscricoes;
    return (
      <div className="Inscricoes full-width">
        <Titulo tipo="h1" titulo="Inscrições" />
        <br />
        {/* <button onClick={() => PrintIntegrais(inscricao)} /> */}
        <br />
        <DataTableIntegrais inscricoes={inscricao} />
        {/* <InscricoesGrid
          columns={[
            // {field: 'id', headerName: 'ID'},
            { field: 'classificacao', headerName: 'Ordem', width: 70 },
            { field: 'id', headerName: 'Nº Inscricao', width: 110 },
            { field: 'nome', headerName: 'Nome', width: 300 },
            { field: 'cpf', headerName: 'CPF', hide: true },
            { field: 'dataNascimento', headerName: 'Data de Nascimento', hide: true },
            { field: 'email', headerName: 'Email', hide: true },
            { field: 'curso', headerName: 'Curso', width: 130 },
            { field: 'rendaTotal', headerName: 'Renda Total', hide: true },
            { field: 'pontos_renda', headerName: 'Criterio 5.1-I', width: 120 },
            { field: 'pontos_mae_solo', headerName: 'Criterio 5.1-II-a', width: 120 },
            { field: 'pontos_arrimo_familia', headerName: 'Criterio 5.1-II-b', width: 120 },
            { field: 'ponto_idoso', headerName: 'Criterio 5.1-II-c', width: 120 },
            { field: 'pontos_egresso_sms', headerName: 'Criterio 5.1-II-d', width: 120 },
            { field: 'totalPontos', headerName: 'Total Pontuação', width: 140 },
            { field: 'pcd', headerName: 'PCD', width: 100 }
          ]}
          selectionModel={(data) => this.setState({ selectionModel: data })}
          rows={inscricao ? inscricao : []} /> */}
      </div>
    )

  }
}

const mapStateToProps = state => ({
  inscricoes: state.inscricoes,
  usuario: state.auth.usuario
})
export default connect(mapStateToProps, actions)(AreaAdm)