import React from 'react';

export const TextoDados = ({ chave, valor, vertical = false, error }) => (
    <div className={`Texto-Dados flex ${vertical ? 'vertical' : 'horizontal'}`}>
        <strong className={`Texto-Dados flex ${vertical ? '' : 'flex-center'}`}>{chave}:&nbsp;</strong>
        {error && (<small className='small-danger'>{error}</small>)}
        <span className='flex flex-center'>{valor}</span>
    </div>
)

export const TextoDadosADM = ({ chave, valor, vertical = false, error }) => (
    <div className={`Texto-DadosADM flex ${vertical ? 'vertical' : 'horizontal'}`}>
        <strong className={`Texto-DadosADM flex ${vertical ? '' : 'flex-center'}`}>{chave}:&nbsp;</strong>
        {error && (<small className='small-danger'>{error}</small>)}
        <span className='flex flex-center'>{valor}</span>
    </div>
)

export const TextoSelect = ({ chave, valor, vertical = true, disabled, error }) => (
    <div style={!disabled === true ? { color: 'black' } : { color: '#C2C2C2' }} className={`Texto-Dados flex ${vertical ? 'vertical' : 'horizontal'}`}>
        <div className={`Titulo-Dados flex ${vertical ? '' : 'flex-center'}`}>{chave}</div>
        {error && (<small className='small-danger'>{error}</small>)}
        <span className=' flex-1 flex flex-end'>{valor}</span>
    </div>
)