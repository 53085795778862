import React from 'react';
import { Link } from 'react-router-dom';

const items = [
  { rota: '/adm', icone: (<i className="fas fa-home" />), titulo: 'Início' },
  { rota: '/alterarSenha', icone: (<i className="fa fa-key" aria-hidden="true"></i>), titulo: 'Alterar senha' },
  { rota: '/adm/gerarAptos', icone: (<i className="fa fa-spinner" aria-hidden="true"></i>), titulo: 'Gerar Aptos' },
  { rota: '/adm/inscricoes', icone: (<i className="fas fa-list" />), titulo: 'Lista de inscricoes' },
  { rota: '/adm/aptos', icone: (<i className="fas fa-check" />), titulo: 'Lista de aptos' },
  { rota: '/adm/desclassificado', icone: (<i className="fa fa-times" aria-hidden="true"></i>), titulo: 'Lista de desclassificados' }
]

const ListItems = ({ open, history }) => {
  const localAtual = history.location.pathname;

  return (
    (
      <div className="items-wrapper">
        {
          items.map((item, idx) => (
            <Link to={item.rota} key={idx}>
              <div className={`menu-item ${localAtual === item.rota ? 'menu-item-active' : ''} flex horizontal`}>
                <div className="flex-1 flex flex-center">
                  {item.icone}
                </div>
                {open && (
                  <div className="flex-2 flex flex-start">
                    <span>{item.titulo}</span>
                  </div>)}
              </div>
            </Link>
          ))
        }
      </div>
    )
  )
}

export default ListItems
