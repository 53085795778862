//LOCALSTORAGE

export const saveToken = (usuario, opcaoLembrar) => {
  if (!usuario.token) return null;
  const [token1, token2, token3] = usuario.token.split('.')
  localStorage.setItem("token1", token1);
  localStorage.setItem("token2", token2);
  localStorage.setItem("token3", token3);
  localStorage.setItem("opcaoLembrar", opcaoLembrar);
}

export const cleanToken = () => {
  localStorage.removeItem("token1")
  localStorage.removeItem("token2")
  localStorage.removeItem("token3")
  localStorage.removeItem("opcaoLembrar");
}

export const getToken = () => {
  const token1 = localStorage.getItem('token1');
  const token2 = localStorage.getItem('token2');
  const token3 = localStorage.getItem('token3');
  if (!token1 || !token2 || !token3) return null;
  return `${token1}.${token2}.${token3}`;
}

export const getHeaders = () => {
  return {
    "headers": {
      'authorization': `Bearer ${getToken()}`
    }
  }
}


export function ts2human(time) {
  var ts = time;
  var dt = new Date(ts * 1000);
  return (ts, dt.toLocaleString());
}
export function human2ts(data) {
  var di = data;
  var ts = Date.parse(di);

  return (ts / 1000, ts / 1000);
}

function parseJwt(token) {
  if (!token) {
    return;
  }
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace("-", "+").replace("_", "/");
  return JSON.parse(window.atob(base64));
}

export const isAuthenticated = () => {
  if (localStorage.getItem('token1') === null
    && localStorage.getItem('token0') === null
    && localStorage.getItem('token3') === null) {
    return false
  } else {
    var userLogged = parseJwt(getToken()).username
    var expira = parseJwt(getToken()).exp
    var data = new Date();
    var dataTS = human2ts(data)
    if (userLogged !== null && expira > dataTS) {
      return true;
    } else {
      return false;
    }
  }
}