import React from 'react';
import BaseAdm from '../BaseAdm';
import { connect } from 'react-redux';
import * as actions from '../../actions'

const baseAdm = Component => {
  class ComponentBaseAdm extends React.Component {

    async componentDidMount() {
      const { getUser, authorized, history, usuario } = this.props;
      await getUser();
      //  if (!this.props.authorized || !this.props.usuario || !this.props.usuario.permissao.includes('Adm')) history.replace('/login');
    }

    render() {
      return (
        <BaseAdm history={this.props.history}>
          <Component {...this.props} />
        </BaseAdm>
      )
    }
  }

  const mapStateToProps = state => ({
    authorized: state.auth.authorized,
    usuario: state.auth.usuario
  });

  return connect(mapStateToProps, actions)(ComponentBaseAdm)
}

export default baseAdm;
