import React, { Component } from 'react';
import InputSimples from '../../../components/Inputs/Simples'
import InputSelect from '../../../components/Inputs/Select'
import Titulo from '../../../components/Texto/Titulo'
import Button from '../../../components/Button/Simples'
import Tabela from '../../../components/Tabela/Simples'
import Modal from '@material-ui/core/Modal';
import InputDate from '../../../components/Inputs/Date';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { maskMonetaria, mCPF, ValidaCPF } from '../../../config/mask'

import { connect } from 'react-redux';
import * as actions from '../../../actions';

class DadosAcademicos extends Component {
  state = {
    nome: '',
    dataNascimento: '',
    cpf: '',
    rgCertidao: '',
    tipoRenda: '',
    rendaBruta: 0,
    profissaoAtividade: '',
    parentesco: '',
    pessoaComDeficiencia: '',
    classificacaoDeficiencia: '',
    cidDeficiencia: '',
    open: false,
    erros: {},
    integrantes: [],
    integrantesTmp: []
  }

  handleClose = () => {
    this.setState({
      nome: '',
      dataNascimento: '',
      cpf: '',
      rgCertidao: '',
      tipoRenda: '',
      rendaBruta: '',
      profissaoAtividade: '',
      parentesco: '',
      pessoaComDeficiencia: '',
      classificacaoDeficiencia: '',
      cidDeficiencia: '',
      rendaTotal: 0,
      integrantesTmp: '',
      open: false,
      erros: {}
    });
  };

  ModalSimples(props) {
    const mCPF = (cpf) => {
      cpf = cpf.replace(/\D/g, "")
      cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2")
      cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2")
      cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
      return cpf
    }

    const ValidaCPF = () => {
      var RegraValida = this.state.cpf;
      var cpf = RegraValida.trim();

      cpf = cpf.replace(/\./g, '');
      cpf = cpf.replace('-', '');
      cpf = cpf.split('');

      var v1 = 0;
      var v2 = 0;
      var aux = false;

      for (var i = 1; cpf.length > i; i++) {
        if (cpf[i - 1] != cpf[i]) {
          aux = true;
        }
      }

      if (aux == false) {
        return false;
      }

      for (var i = 0, p = 10; (cpf.length - 2) > i; i++, p--) {
        v1 += cpf[i] * p;
      }

      v1 = ((v1 * 10) % 11);

      if (v1 == 10) {
        v1 = 0;
      }

      if (v1 != cpf[9]) {
        return false;
      }

      for (var i = 0, p = 11; (cpf.length - 1) > i; i++, p--) {
        v2 += cpf[i] * p;
      }

      v2 = ((v2 * 10) % 11);

      if (v2 == 10) {
        v2 = 0;
      }

      if (v2 != cpf[10]) {
        return false;
      } else {
        return true;
      }
    }

    const validate = () => {

      const {
        nome,
        dataNascimento,
        cpf,
        rgCertidao,
        tipoRenda,
        rendaBruta,
        profissaoAtividade,
        parentesco,
        pessoaComDeficiencia,
        classificacaoDeficiencia,
        cidDeficiencia
      } = this.state;
      const erros = {}

      if (!nome) erros.nome = 'Preencha o nome completo';
      else if (nome.length < 6) erros.nome = 'Preencha com pelo menos 6 letrar';
      if (!rgCertidao) erros.rgCertidao = 'Preencha o RG ou certidão de nascimento.';
      if (!dataNascimento) erros.dataNascimento = 'Preencha a data de nascimento';
      if (!rgCertidao) erros.rgCertidao = 'Preencha a sua data de nascimento';
      if (!tipoRenda) erros.tipoRenda = 'Preencha o tipo de renda';
      if (!rendaBruta) erros.rendaBruta = 'Preencha a renda bruta';
      if ((cpf) && !ValidaCPF()) erros.validaCpf = 'CPF inválido'
      if (!profissaoAtividade) erros.profissaoAtividade = 'Preencha a profissao';
      if (!parentesco) erros.parentesco = 'Preencha o parentesco';
      if (pessoaComDeficiencia === true && !classificacaoDeficiencia) erros.classificacaoDeficiencia = 'Selecione aqui a classificacao da deficiência';
      if (pessoaComDeficiencia === true && !cidDeficiencia) erros.cidDeficiencia = 'Preencha aqui o CID da sua deficiência';

      this.setState({ erros });
      //this.props.setErros(erros)

      return !(Object.keys(erros).length > 0);
    }

    const addIntegrante = (data) => {
      let integrantesClone = [...this.props.integrantes]
      integrantesClone.push(data)

      this.setState({ integrantes: integrantesClone })
      this.props.setIntegrantes(integrantesClone)

      let rendaTitula = parseFloat(this.props.rendaMensalTitular.replace('R$ ', '').replace('.', '').replace(',', '.'))

      let integrantesArr = Object.values(integrantesClone) || []
      let totalRenda = []
      integrantesArr.map(item => {
        let rendaBrutaUnit = parseFloat(item.rendaBruta.replace('R$ ', '').replace('.', '').replace(',', '.'))
        totalRenda.push(rendaBrutaUnit)
      })

      this.props.setRendaTotal(parseFloat(totalRenda.reduce((a, b) => a + b) + rendaTitula))


      this.handleClose()

    }

    const onChangeInput = (field, value) => this.setState({ [field]: value }, validate)

    return (
      <div className='Teste-Modal'>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className='Modal'
          open={this.state.open}
          onClose={this.handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.open}>
            <div className='Card-Modal'>
              <h3 id="transition-modal-title">Cadastro de integrantes do grupo familiar</h3>
              <InputSimples
                placeHolder={'Informe seu nome completo *'}
                type='inputModal'
                label="Nome Completo *"
                value={this.state.nome}
                onChange={(ev) => onChangeInput('nome', ev.target.value)}
                error={this.state.erros.nome}
              />
              <div className='Input-Simples-Modal flex horizontal grid-1'>
                <label >Data de Nascimento</label>
                <small className='small-danger'>{this.state.erros.dataNascimento}</small>
                <InputDate
                  type='modal'
                  value={this.state.dataNascimento}
                  onChange={(ev) => onChangeInput('dataNascimento', ev.target.value)}

                />
              </div>

              <InputSimples horizontal
                placeHolder={'Informe seu cpf...'}
                id='RegraValida'
                type='inputModal'
                name='RegraValida'
                label="CPF"
                value={this.state.cpf}
                onChange={(ev) => onChangeInput('cpf', mCPF(ev.target.value))}
                error={this.state.erros.validaCpf}
              />
              <InputSimples
                placeHolder={'Informe seu RG ou Cert. Nascimento *'}
                type='inputModal'
                label="RG/Certidão Nasc. *"
                value={this.state.rgCertidao}
                onChange={(ev) => onChangeInput('rgCertidao', ev.target.value)}
                error={this.state.erros.rgCertidao}
              />
              <div className='Input-Simples-Modal flex horizontal grid-1'>
                <label >Tipo de renda</label>
                <small className='small-danger'>{this.state.erros.tipoRenda}</small>

                <InputSelect
                  name='tipoRenda'
                  type='modal'
                  onChange={(ev) => (onChangeInput('tipoRenda', ev.target.value))}
                  value={this.state.tipoRenda}
                  opcoes={[
                    { label: 'NÃO POSSUI RENDA', value: 'NÃO POSSUI RENDA' },
                    { label: 'APOSENTADORIA ESPECIAL', value: 'APOSENTADORIA ESPECIAL' },
                    { label: 'AJUDA DE FAMILIARES', value: 'AJUDA DE FAMILIARES' },
                    { label: 'OUTROS TIPOS DE RENDA', value: 'OUTROS TIPOS DE RENDA' },
                    { label: 'BENEFÍCIO DE PRESTAÇÃO CONTINUADA', value: 'BENEFÍCIO DE PRESTAÇÃO CONTINUADA' },
                    { label: 'SALÁRIO BRUTO', value: 'SALÁRIO BRUTO' },
                    { label: 'PENSÃO', value: 'PENSÃO' },
                    { label: 'APOSENTADORIA', value: 'APOSENTADORIA' },
                    { label: 'PENSÃO ALIMENTÍCIA', value: 'PENSÃO ALIMENTÍCIA' },
                    { label: 'AJUDA DE TERCEIROS', value: 'AJUDA DE TERCEIROS' },
                    { label: 'PROGRAMAS DE TRANSFERÊNCIA DE RENDA', value: 'PROGRAMAS DE TRANSFERÊNCIA DE RENDA' }
                  ]}
                />
              </div>

              <InputSimples
                placeHolder={'Renda bruta *'}
                type='inputModal'
                label="Renda Bruta *"
                value={this.state.rendaBruta}
                onChange={(ev) => onChangeInput('rendaBruta', maskMonetaria(ev.target.value || 0))}
                error={this.state.erros.rendaBruta}
              />

              <InputSimples
                placeHolder={'Profissão/Atividade * *'}
                type='inputModal'
                label="Profissão/Atividade *"
                value={this.state.profissaoAtividade}
                onChange={(ev) => onChangeInput('profissaoAtividade', ev.target.value)}
                error={this.state.erros.profissaoAtividade}
              />
              <div className='Input-Simples-Modal flex horizontal grid-1'>
                <label >Parentesco</label>
                <small className='small-danger'>{this.state.erros.parentesco}</small>

                <InputSelect
                  name='parentesco'
                  type='modal'
                  onChange={(ev) => (onChangeInput('parentesco', ev.target.value))}
                  value={this.state.parentesco}
                  opcoes={[
                    { label: 'Esposo/Esposa', value: 'Esposo/Esposa' },
                    { label: 'Companheiro/Companheira', value: 'Companheiro/Companheira' },
                    { label: 'Filho/Filha', value: 'Filho/Filha' },
                    { label: 'Irmão/Irmã', value: 'Irmão/Irmã' },
                    { label: 'Pai/Mãe', value: 'Pai/Mãe' },
                    { label: 'Padrasto/Madrasta', value: 'Padrasto/Madrasta' },
                    { label: 'Avô/Avó', value: 'Avô/Avó' },
                    { label: 'Tutor(a), Tutelado(a) ou Curador(a) e Curatelado(a)', value: 'Tutor(a), Tutelado(a) ou Curador(a) e Curatelado(a)' },
                    { label: 'Tio/Tia', value: 'Tio/Tia' },
                    { label: 'Pessoas socioeconomicamente dependentes cooperadas', value: 'Pessoas socioeconomicamente dependentes cooperadas' },
                  ]}
                />
              </div>
              <div className='Input-Simples-Modal flex horizontal grid-1'>
                <label >Possui deficiencia?</label>
                <small className='small-danger'>{this.state.erros.pessoaComDeficiencia}</small>

                <InputSelect
                  name='pessoaComDeficiencia'
                  type='modal'
                  onChange={(ev) => (onChangeInput('pessoaComDeficiencia', ev.target.value))}
                  value={this.state.pessoaComDeficiencia}
                  opcoes={[
                    { label: 'Sim', value: true },
                    { label: 'Não', value: false }
                  ]}
                />
              </div>
              <InputSimples
                placeHolder={'Classificação deficiencia *'}
                type='inputModal'
                label="Classificação Deficiência *"
                value={this.state.classificacaoDeficiencia}
                onChange={(ev) => onChangeInput('classificacaoDeficiencia', ev.target.value)}
                error={this.state.erros.classificacaoDeficiencia}
              />
              <InputSimples
                placeHolder={'CID da deficiência *'}
                type='inputModal'
                label="CID da deficiência *"
                value={this.state.cidDeficiencia}
                onChange={(ev) => onChangeInput('cidDeficiencia', ev.target.value)}
                error={this.state.erros.cidDeficiencia}
              />
              <div className='Botao-Modal flex flex-center'>
                {Object.values(this.state.erros).length === 0 &&
                  <Button
                    type='modal'
                    onClick={() => addIntegrante(this.state)}
                    label={'Salvar'} />
                }
                <Button
                  type='modal'
                  onClick={this.handleClose}
                  label={'Cancelar'} />
              </div>
            </div>
          </Fade>
        </Modal>
      </div>
    )

  }

  render() {

    const deleteIntegrante = (e) => {
      const { integrantes, rendaTotal } = this.props
      const { integrantesTmp } = this.state
      integrantes.splice(integrantes.indexOf(e), 1);
      this.setState({ integrantesTmp: integrantes })
      this.props.setIntegrantes(integrantes)

      let rendaTotalAtt = parseFloat(rendaTotal - parseFloat(e.rendaBruta.replace('R$ ', '').replace('.', '').replace(',', '.')))

      this.props.setRendaTotal(rendaTotalAtt)


    }

    const { integrantes, rendaTotal } = this.props
    const { integrantesTmp } = this.state

    return (
      <div className='Inscricao full-width flex vertical'>
        <div >
          <Titulo tipo='titulo-detalhe-inscricao' titulo='Informações sobre a situação sócio econômica - Composição do grupo familiar/renda' />
          <div className='Container-Inscricao'>
            <div className='Instrucao-Integrante-Familiar'>
              Na tela abaixo, informe os dados solicitados de todos os demais integrantes de seu grupo familiar.<br /><br />
              Sobre a <b>renda bruta mensal</b> dos integrantes do grupo familiar, informar os dados relacionados ao último mês de recebimento.<br /><br />

              Ao clicar sobre o botão <b>Adicionar</b> será aberta uma nova tela, na qual devem ser informados os dados solicitados. Após o preenchimento de todos os campos, clique no botão <b>Salvar</b>.<br /><br />

              Após incluir integrante familiar não é possível alterar as informações. Caso necessário, exclua e realize a inclusão novamente.<br /><br />

              Para adicionar um outro integrante familiar, repita os passos descritos.<br /><br />
              <div style={{ color: 'red', textAlign: 'center' }}>
                ATENÇÃO TITULAR:<br />
                Não é necessário adicionar seus dados novamente, apenas dos demais integrantes do grupo familiar
              </div>
            </div>
          </div>
          <br /><hr />

          <div className='flex horizontal'>
            <div className='grid-2'>
              <Button
                type='info'
                icon='fa fa-plus'
                onClick={() => this.setState({ open: true })}
                label='Adicionar integrante ao grupo familiar'
              />
            </div>
            <div className='Renda-Grupo-Familiar grid-2 flex horizontal'>
              <div>
                <b>Renda total do grupo familiar: </b>
              </div>
              <div className='Valor-Renda'>
                &ensp;{rendaTotal ? `R$ ${rendaTotal.toFixed(2)}` : this.props.rendaMensalTitular}
              </div>
            </div>
          </div>

          <div className='Table-Integrantes grid-1 flex horizontal'>
            <Tabela
              botaoDetalhes={deleteIntegrante}
              cabecalhoFormat={['nome', 'cpf', 'RG', 'data de Nascimento', 'parentesco', 'pessoa Com Deficiencia', 'classificacao da Deficiencia', 'cid', 'tipo de Renda', 'renda Bruta']}
              cabecalho={['nome', 'cpf', 'rgCertidao', 'dataNascimento', 'parentesco', 'pessoaComDeficiencia', 'classificacaoDeficiencia', 'cid', 'tipoRenda', 'rendaBruta', 'acao']}
              dados={integrantes ? integrantes : []} />
            {(this.props.integrantes) ? <div className='flex flex-end'><b>Total:</b> &nbsp;{this.props.integrantes.length}</div> : <div></div>}
          </div>
          {this.ModalSimples()}
        </div>
      </div>

    )
  }
}

export default connect(null, actions)(DadosAcademicos)
