import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { buscaInscritoAsync, selectAptos } from '../../features/Adm/AdmSlice';
import Button from '../../components/Button/Simples';
import InputValor from '../../components/Inputs/InputValor';
import AlertDanger from '../../components/Alert/Danger';
import { TextoDadosADM } from '../../components/Texto/Dados';
import { Input } from '@material-ui/core';

export default function DesclassificaCanditado(props) {

    const [motivoDesclassifica, setMotivoDesclassifica] = useState('')

    const { inscrito } = useSelector(selectAptos)

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(buscaInscritoAsync(props.match.params.id))
    }, [dispatch])

    const change = (valor) => {
        console.log(valor)
        setMotivoDesclassifica(valor)

    }
    const inscricao = inscrito.inscricao
    const apto = inscrito.apto

    return (
        <div className="Detalhes-do-Cadastro text-center">
            <AlertDanger classe='alert-success' />
            <br />
            <h1 className="">{inscricao ? inscricao.nome : ''}</h1>
            <div className='flex vertical'>
                <span className="">{apto ? (apto.apto ? 'APTO' : 'DESCLASSIFICADO') : ''}</span>
                <span className="">{apto ? apto.motivo_eliminacao : ''}</span>
                <span className="">{apto ? apto.tipo_bolsa : ''}</span>
            </div>
            {motivoDesclassifica}
            <div className='dados-inscricao flex horizontal space-between'>

                <InputValor
                    type='text'
                    name='motivoDesclassifica'
                    handleSubmit={(e) => change(e)}
                    value={motivoDesclassifica} />

            </div>
        </div>
    )

}