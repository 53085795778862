export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';

export const GET_INSCRICOES = 'GET_INSCRICOES'
export const GET_INSCRITO = 'GET_INSCRITO'
export const GET_INSCRICAO = 'GET_INSCRICAO'
export const LIMPAR_INSCRICAO = 'LIMPAR_INSCRICAO'
export const CANCELAR_INSCRICAO = 'CANCELAR_INSCRICAO'

export const GET_ARQUIVOS = 'GET_ARQUIVOS'
export const GET_APTOS = 'GET_APTOS'
export const GET_NAO_APTOS = 'GET_NAO_APTOS'

export const GET_INTEGRANTES = 'GET_INTEGRANTES'
export const CANCELAR_INTEGRANTE = 'CANCELAR_INTEGRANTE'
export const GET_ACEITOS = 'GET_ACEITOS'
