import React from 'react'
import { Link } from 'react-router-dom'
import { getHeaders, cleanToken } from '../../actions/localStorage'

function funcaoLogout() {
  cleanToken()
  setTimeout(function () {
    window.location = '/'
  }, 500)
}
const BarraTopo = ({ handleLogout }) => (
  <div className="Barra-Topo flex horizontal full-width">
    <div className='flex horizontal'>
      <div className="flex-1 flex flex-start">
        <a aria-label='facebook-square' href="/">
          <i className={`fa fa-facebook-square`} aria-hidden="true" />
        </a>
      </div>
      <div className="flex-1 flex flex-start">
        <a href='/'><i className="fa fa-instagram" aria-hidden="true"></i></a>
      </div>
      <div className="flex-1 flex flex-start">
        <a href='/'><i className="fa fa-twitter" aria-hidden="true"></i></a>
      </div>
      <div className="flex-1 flex flex-start">
        <a href='/'><i className="fa fa-youtube" aria-hidden="true"></i></a>
      </div>
    </div>

    <div className='flex horizontal flex-end'>
      {getHeaders().headers.authorization === 'Bearer null' ?
        '' :
        <Link to='/candidato'>
          <i className="fas fa-home" />
          <span>Início</span>
        </Link>}
      {getHeaders().headers.authorization === 'Bearer null' ?
        ''
        :
        <Link to='/alterarSenha'>
          <i className="fas fa-user" />
          <span>Alterar Senha</span>
        </Link>}
      {getHeaders().headers.authorization === 'Bearer null' ?
        ''
        :
        <Link to='/candidato/upload'>
          <i className="fas fa-user" />
          <span>Fazer Upload</span>
        </Link>}
      {getHeaders().headers.authorization === 'Bearer null' ?
        (<div>
          <span><Link to='/'><i className="fa fa-home" aria-hidden="true"></i> Início</Link></span>
          <span><Link to='/login'><i className="fa fa-sign-in" aria-hidden="true"></i> Login</Link></span>
        </div>)
        :
        <span onClick={() => funcaoLogout()}><i className="fa fa-sign-out" aria-hidden="true"></i> Sair</span>
      }

    </div>
  </div>
)

export default BarraTopo
