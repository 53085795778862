import React, { Component } from 'react'
import Titulo from '../../components/Texto/Titulo'
import TabelaMaterial from '../../components/Tabela/MaterialUIAptos'

import { connect } from 'react-redux';
import * as actions from '../../actions/inscricoes'

class AreaAdm extends Component {

  state = {
    selectionModel: []
  }

  async componentDidMount() {
    await this.props.getInscricoesAptos();
  }

  componentWillUnmount() {
    this.props.limparInscricao();
  }

  render() {
    const { inscricoes } = this.props;
    return (
      <div className="Inscricoes full-width">
        <Titulo tipo="h1" titulo="Inscrições" />
        <br />
        {inscricoes && <TabelaMaterial
          columns={[
            { field: 'id', headerName: 'id', hide: true },
            { field: 'nome', headerName: 'Nome', width: 300 },
            { field: 'cpf', headerName: 'CPF' },
            { field: 'rg', headerName: 'RG', hide: true },
            { field: 'sexo', headerName: 'Gênero', hide: true },
            { field: 'dataNascimento', headerName: 'Data de Nascimento' },
            { field: 'estadoCivil', headerName: 'Estado Civil', hide: true },
            { field: 'email', headerName: 'Email' },
            { field: 'escolaridade', headerName: 'Escolaridade', hide: true },
            { field: 'nomeDaMae', headerName: 'Nome da Mãe', hide: true },
            { field: 'naturalidade', headerName: 'Naturalidade', hide: true },
            { field: 'rendaMensalTitular', headerName: 'Renda Mensal do titular', hide: true },
            { field: 'quantidadeImoveis', headerName: 'Quantidade de Imóveis', hide: true },
            { field: 'egresso', headerName: 'Egresso', hide: true },
            { field: 'maeSolo', headerName: 'Mãe Solo', hide: true },
            { field: 'responsavelFinanceiro', headerName: 'Responsável Financeiro', hide: true },
            { field: 'pessoaComDeficiencia', headerName: 'Pessoa com deficiência', hide: true },
            { field: 'universidade', headerName: 'Universidade', hide: true },
            { field: 'curso', headerName: 'Curso', hide: true },
            { field: 'periodoCursando', headerName: 'Período que cursa', hide: true },
            { field: 'turnoDoCurso', headerName: 'Turno do curso', hide: true },
            { field: 'modalidadeCurso', headerName: 'Modalidade do curso', hide: true },
            { field: 'matricula', headerName: 'Matrícula', hide: true },
            { field: 'mensalidadeSemDesconto', headerName: 'Mensalidade sem desconto', hide: true },
            { field: 'mensalidadeComDesconto', headerName: 'Mensalidade com desconto', hide: true },
            { field: 'matricula', headerName: 'Matrícula', hide: true },
            { field: 'rendaTotal', headerName: 'Renda Total' },
            { field: 'pontos_renda_pc', headerName: 'Pontos Renda PC' },
            { field: 'ponto_idoso_bpc', headerName: 'Ponto Idoso BPC' },
            { field: 'pontos_cras_creas', headerName: 'Pontos Cras\Creas' },
            { field: 'pontos_idoso_doenca', headerName: 'Pontos Idoso Doença' },
            { field: 'pontos_idoso_def_resp', headerName: 'Pontos Idoso Def. Resp.' },
            { field: 'pontos_aluguel', headerName: 'Pontos Aluguel' },
            { field: 'pontos_idoso_reside_so', headerName: 'Pontos Idoso Reside Sõ' },
            { field: 'pontuacao_total', headerName: 'Total de Pontos' },

          ]}
          selectionModel={(data) => this.setState({ selectionModel: data })}
          rows={inscricoes ? inscricoes : []}
        />
        }
      </div>
    )

  }
}

const mapStateToProps = state => ({
  inscricoes: state.inscricoes.inscricoes,
  usuario: state.auth.usuario
})
export default connect(mapStateToProps, actions)(AreaAdm)