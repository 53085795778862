import React, { Component } from 'react';
import InputSimples from '../../../components/Inputs/Simples'
import InputSelect from '../../../components/Inputs/Select'
import { TextoSelect } from '../../../components/Texto/Dados'
import Titulo from '../../../components/Texto/Titulo'
import InputDate from '../../../components/Inputs/Date';
import { maskMonetaria, mCPF, ValidaCPF } from '../../../config/mask'
import moment from 'moment';
import { connect } from 'react-redux';
import * as actions from '../../../actions';
import { calculaIdade } from '../../../config';

class DadosPessoais extends Component {

  state = {
    nome: '',
    cpf: '',
    rg: '',
    sexo: '',
    dataNascimento: '',
    estadoCivil: '',
    email: '',
    escolaridade: '',
    nomeDaMae: '',
    nomeDoPai: '',
    cpfDaMae: '',
    cpfDoPai: '',
    naturalidade: '',
    responsavelFinanceiro: false,
    rendaMensalTitular: 0,
    pessoaComDeficiencia: false,
    classificacaoDeficiencia: '',
    cidDeficiencia: '',
    quantidadeImoveis: 0,
    erros: {}
  }

  componentDidMount() {

    this.validate();
  }

  validate = () => {

    const {
      nome,
      rg,
      nis,
      sexo,
      dataNascimento,
      estadoCivil,
      email,
      escolaridade,
      nomeDaMae,
      cpfDaMae,
      cpfDoPai,
      naturalidade,
      rendaMensalTitular,
      pessoaComDeficiencia,
      classificacaoDeficiencia,
      cidDeficiencia,
      responsavelFinanceiro
    } = this.props;
    const erros = {};

    if (!nome) erros.nome = 'Preencha o nome completo';
    else if (nome.length < 6) erros.nome = 'Nome precisa ser maior que 6 caracteres';
    if (!rg) erros.rg = 'Preencha o RG ou certidão de nascimento.';
    if (!nis) erros.nis = 'Preencha com o número do NIS.';
    if (!sexo) erros.sexo = 'Preencha o seu sexo';
    if (!dataNascimento) erros.dataNascimento = 'Preencha a data de nascimento';
    if (!estadoCivil) erros.estadoCivil = 'Preencha o seu estado civil';
    if (!email) erros.email = 'Preencha o E-mail';
    else if (!email.includes('@')) erros.email = 'Email incorreto';
    if (!escolaridade) erros.escolaridade = 'Preencha a escolaridade';
    if (!nomeDaMae) erros.nomeDaMae = 'Preencha o nome da sua mãe';
    else if (nomeDaMae.length < 6) erros.nomeDaMae = 'Nome da mãe precisa ser maior que 6 caracteres';
    if ((cpfDaMae) && !ValidaCPF(cpfDaMae)) erros.cpfDaMae = 'CPF inválido';
    if ((cpfDoPai) && !ValidaCPF(cpfDoPai)) erros.cpfDoPai = 'CPF inválido';
    if (!naturalidade) erros.naturalidade = 'Preencha a sua naturalidade';
    if (!rendaMensalTitular) erros.rendaMensalTitular = 'Preencha a sua renda bruta mensal';
    if (pessoaComDeficiencia === true && !classificacaoDeficiencia) erros.classificacaoDeficiencia = 'Selecione aqui a classificacao da deficiência';
    if (pessoaComDeficiencia === true && !cidDeficiencia) erros.cidDeficiencia = 'Preencha aqui o CID da sua deficiência';

    this.setState({ erros });
    this.props.setErros(erros)

    return !(Object.keys(erros).length > 0);
  }
  idade(data) {
    let date = moment(data).format('DD/MM/YYYY')
    let idade = calculaIdade(date)
    if (idade < 60) {
      alert('Idade não permitida')
      window.location = "/"
    }
  }
  onChangeInput = (field, value) => this.setState({ [field]: value }, () => this.validate())
  handleChange = (value, formattedValue) => {
    this.props.setDataNascimento(value)
    this.setState({
      dataNascimento: value, // ISO String, ex: "2016-11-19T12:00:00.000Z"
      formattedValue: formattedValue // Formatted String, ex: "11/19/2016"
    });
  }
  render() {
    const {
      nome,
      rg,
      nis,
      sexo,
      dataNascimento,
      estadoCivil,
      email,
      escolaridade,
      nomeDaMae,
      nomeDoPai,
      cpfDaMae,
      cpfDoPai,
      naturalidade,
      responsavelFinanceiro,
      rendaMensalTitular,
      pessoaComDeficiencia,
      classificacaoDeficiencia,
      cidDeficiencia,

    } = this.props;
    const { erros } = this.state;

    return (
      <div className='Inscricao full-width flex vertical'>
        <div className='Card-Inscricao'>
          <Titulo tipo='titulo-detalhe-inscricao' titulo='Dados pessoais' />
          <div className='Container-Inscricao'>
            <div className='Input-Inscricao flex-1 flex horizontal space-between'>
              <InputSimples
                className='flex-1'
                name='nome'
                label='Nome:'
                placeHolder='Nome Completo *'
                value={nome}
                error={erros.nome}
                onChange={(ev) => (this.onChangeInput('nome', ev.target.value), this.props.setNome(ev.target.value))} />

              <InputSimples
                className='flex-1'
                name='nis'
                label='NIS:'
                placeHolder='Número do NIS *'
                value={nis}
                error={erros.nis}
                onChange={(ev) => (this.onChangeInput('nis', ev.target.value), this.props.setNis(ev.target.value))} />

              <InputSimples
                className='flex-1'
                name='cpf'
                disabled
                label='CPF:'
                maxLength={'14'}
                placeHolder='CPF *'
                value={this.props.cpf}
                error={erros.cpf}
                onChange={(ev) => (this.onChangeInput('cpf', ev.target.value), this.props.setCpf(ev.target.value))}
              />

            </div>
            <br />
            <div className='Input-Inscricao flex-1 flex horizontal space-between'>
              <InputSimples
                className='flex-1'
                name='rg'
                label='RG/Certidão de nascimento:'
                placeHolder='RG/Certidão de nascimento *'
                value={rg}
                error={erros.rg}
                onChange={(ev) => (this.onChangeInput('rg', ev.target.value), this.props.setRg(ev.target.value))} />
              <TextoSelect
                chave='Gênero'
                vertical
                valor={(
                  <InputSelect
                    name='sexo'
                    onChange={(ev) => (this.onChangeInput('sexo', ev.target.value), this.props.setSexo(ev.target.value))}
                    value={sexo}
                    opcoes={[
                      { label: 'Masculino', value: 'masculino' },
                      { label: 'Feminino', value: 'feminino' },
                      { label: 'Prefiro não informar', value: 'outros' }
                    ]}
                    error={erros.sexo}
                  />
                )} />

              <TextoSelect
                chave='Data de nascimento'
                vertical
                valor={(
                  <InputDate
                    form
                    value={dataNascimento}
                    onBlur={(ev) => this.idade(ev.target.value)}
                    onChange={(ev) => (this.onChangeInput('dataNascimento', ev.target.value), this.props.setDataNascimento(ev.target.value))}

                  />
                )} />
            </div>
            <br />
            <div className='Input-Inscricao flex-1 flex horizontal space-between'>
              <TextoSelect
                chave='Estado civil'
                vertical
                valor={(
                  <InputSelect
                    name='estadoCivil'
                    onChange={(ev) => (this.onChangeInput('estadoCivil', ev.target.value), this.props.setEstadoCivil(ev.target.value))}
                    value={estadoCivil}
                    opcoes={[
                      { label: 'Solteiro', value: 'solteiro' },
                      { label: 'Casado', value: 'casado' },
                      { label: 'Divorciado', value: 'divorciado' }
                    ]}
                    error={erros.estadoCivil}
                  />
                )} />
              <InputSimples
                className='flex-1'
                name='email'
                label='E-mail'
                placeHolder='Preencha seu e-mail *'
                value={email}
                error={erros.email}
                onChange={(ev) => (this.onChangeInput('email', ev.target.value), this.props.setEmail(ev.target.value))} />
              <TextoSelect
                chave='Escolaridade'
                vertical
                valor={(
                  <InputSelect
                    name='escolaridade'
                    onChange={(ev) => (this.onChangeInput('escolaridade', ev.target.value), this.props.setEscolaridade(ev.target.value))}
                    value={escolaridade}
                    opcoes={[
                      { label: 'Fundamental - Incompleto', value: 'FI' },
                      { label: 'Fundamental - Completo', value: 'FC' },
                      { label: 'Médio - Incompleto', value: 'MI' },
                      { label: 'Médio - Completo', value: 'MC' },
                      { label: 'Superior - Incompleto', value: 'SI' },
                      { label: 'Superior - Completo', value: 'SC' },
                      { label: 'Pós-graduação (Lato senso) - Incompleto', value: 'PI' },
                      { label: 'Pós-graduação (Lato senso) - Completo', value: 'PC' }
                    ]}
                    error={erros.escolaridade}
                  />
                )} />

            </div>
            <br />
            <div className='Input-Inscricao flex-1 flex horizontal space-between'>
              <InputSimples
                className='flex-1'
                name='nomeDaMae'
                label='Nome da mãe'
                placeHolder='Preencha o nome da sua mãe *'
                value={nomeDaMae}
                error={erros.nomeDaMae}
                onChange={(ev) => (this.onChangeInput('nomeDaMae', ev.target.value), this.props.setNomeDaMae(ev.target.value))} />
              <InputSimples
                className='flex-1'
                name='nomeDoPai'
                label='Nome do Pai'
                placeHolder='Preencha o nome do seu pai (opcional)'
                value={nomeDoPai}
                error={erros.nomeDoPai}
                onChange={(ev) => (this.onChangeInput('nomeDoPai', ev.target.value), this.props.setNomeDoPai(ev.target.value))} />
              <InputSimples
                className='flex-1'
                name='cpfDaMae'
                label='CPF da mãe'
                maxLength={'14'}
                placeHolder='Preencha o cpf da sua mãe (Opcional)'
                value={cpfDaMae}
                error={erros.cpfDaMae}
                onChange={(ev) => (this.onChangeInput('cpfDaMae', mCPF(ev.target.value)), this.props.setCpfDaMae(mCPF(ev.target.value)), ValidaCPF(ev.target.value))} />

            </div>
            <br />
            <div className='Input-Inscricao flex-1 flex horizontal space-between'>
              <InputSimples
                className='flex-1'
                name='cpfDoPai'
                label='CPF do pai'
                maxLength={'14'}
                placeHolder='Preencha o cpf do seu pai (Opcional)'
                value={cpfDoPai}
                error={erros.cpfDoPai}
                onChange={(ev) => (this.onChangeInput('cpfDoPai', mCPF(ev.target.value)), this.props.setCpfDoPai(mCPF(ev.target.value)), ValidaCPF(ev.target.value))} />
              <InputSimples
                className='flex-1'
                name='naturalidade'
                label='Naturalidade:'
                placeHolder='Preencha sua naturalidade *'
                value={naturalidade}
                error={erros.naturalidade}
                onChange={(ev) => (this.onChangeInput('naturalidade', ev.target.value), this.props.setNaturalidade(ev.target.value))} />
              <TextoSelect
                chave='Você é o responsável financeiro pelo grupo familiar ?*'
                vertical
                valor={(
                  <InputSelect
                    name='responsavelFinanceiro'
                    onChange={(ev) => (this.onChangeInput('responsavalFinanceiro', ev.target.value), this.props.setResponsavelFinanceiro(ev.target.value))}
                    value={(responsavelFinanceiro) ? responsavelFinanceiro : false}
                    opcoes={[
                      { label: 'SIM', value: true },
                      { label: 'NÃO', value: false }
                    ]} />
                )} />


            </div>
            <br />
            <div className='Input-Inscricao flex-1 flex horizontal space-between'>
              <InputSimples
                className='flex-1'
                id='rendaMensalTitular'
                name='rendaMensalTitular'
                label='Renda mensal do titular'
                placeHolder='Preencha sua renda bruta *'
                value={maskMonetaria(rendaMensalTitular || 0)}
                error={erros.rendaMensalTitular}
                onChange={(ev) => (this.onChangeInput('rendaMensalTitular', ev.target.value), this.props.setRendaMensalTitular(maskMonetaria(ev.target.value)))} />

              <TextoSelect
                chave='Você é pessoa com deficiência ?*'
                vertical
                valor={(
                  <InputSelect
                    name='pessoaComDeficiencia'
                    onChange={(ev) => (this.onChangeInput('pessoaComDeficiencia', ev.target.value), this.props.setPessoaComDeficiencia(ev.target.value))}
                    value={(pessoaComDeficiencia) ? pessoaComDeficiencia : false}
                    opcoes={[
                      { label: 'SIM', value: true },
                      { label: 'NÃO', value: false }
                    ]} />
                )} />
            </div>
            <div className='Input-Inscricao flex-1 flex horizontal space-between'>
              {pessoaComDeficiencia === false ? <div /> :
                <TextoSelect
                  chave='Classificação da Deficiência'
                  vertical
                  disabled={pessoaComDeficiencia === true ? false : true}
                  valor={(
                    <InputSelect

                      disabled={pessoaComDeficiencia === true ? false : true}
                      error={erros.classificacaoDeficiencia}
                      name='classificacaoDeficiencia'
                      onChange={(ev) => (this.onChangeInput('classificacaoDeficiencia', ev.target.value), this.props.setClassificacaoDeficiencia(ev.target.value))}
                      value={classificacaoDeficiencia}
                      opcoes={[
                        { label: 'Deficiência Visual', value: 'DV' },
                        { label: 'Deficiência auditiva', value: 'DA' },
                        { label: 'Deficiência mental', value: 'DM' },
                        { label: 'Deficiência Física', value: 'DF' },
                        { label: 'Deficiência múltipla', value: 'DMU' }
                      ]} />
                  )} />
              }
              {pessoaComDeficiencia === false ? <div /> :
                <InputSimples
                  className='flex-1'
                  name='cidDeficiencia'
                  disabled={pessoaComDeficiencia === true ? false : true}
                  label='CID da deficiência'
                  placeHolder='Preencha o CID da sua deficiência *'
                  value={cidDeficiencia}
                  error={erros.cidDeficiencia}
                  onChange={(ev) => (this.onChangeInput('cidDeficiencia', ev.target.value), this.props.setCidDeficiencia(ev.target.value))} />
              }
            </div>
          </div>
        </div>
      </div>

    )
  }
}

export default connect(null, actions)(DadosPessoais)
