import { api, versao } from '../../config/index'
import { getHeaders } from '../../actions/localStorage'

export const gerarAptos = async () => {
    return new Promise(resolve =>
        setTimeout(() => {
            resolve(api.get(`/api/${versao}/Aptos/gerarAptos`, getHeaders()));
        }, 200)
    );
};

export const getInscrito = async (id) => {
    return new Promise(resolve =>
        setTimeout(() => {
            resolve(api.get(`/api/${versao}/inscricoes/adm/inscricao/${id}`, getHeaders()));
        }, 200)
    );
};