import axios from 'axios'

export const cpfMask = value => {
  return value
    .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
}

export const maskMonetaria = value => {
  var valor = value;

  valor = valor + '';
  valor = parseInt(valor.replace(/[\D]+/g, ''));
  valor = valor + '';
  valor = valor.replace(/([0-9]{2})$/g, ",$1");

  if (valor.length > 6) {
    valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
  }

  return value = `R$ ${valor}`;
}

export const mCPF = (cpf) => {
  cpf = cpf.replace(/\D/g, "")
  cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2")
  cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2")
  cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
  return cpf
}

export const ValidaCPF = (cpfDaMae) => {
  var RegraValida = cpfDaMae;
  var cpf = RegraValida.trim();

  cpf = cpf.replace(/\./g, '');
  cpf = cpf.replace('-', '');
  cpf = cpf.split('');

  var v1 = 0;
  var v2 = 0;
  var aux = false;

  for (var i = 1; cpf.length > i; i++) {
    if (cpf[i - 1] != cpf[i]) {
      aux = true;
    }
  }

  if (aux == false) {
    return false;
  }

  for (var i = 0, p = 10; (cpf.length - 2) > i; i++, p--) {
    v1 += cpf[i] * p;
  }

  v1 = ((v1 * 10) % 11);

  if (v1 == 10) {
    v1 = 0;
  }

  if (v1 != cpf[9]) {
    return false;
  }

  for (var i = 0, p = 11; (cpf.length - 1) > i; i++, p--) {
    v2 += cpf[i] * p;
  }

  v2 = ((v2 * 10) % 11);

  if (v2 == 10) {
    v2 = 0;
  }

  if (v2 != cpf[10]) {
    return false;
  } else {
    return true;
  }
}

export function retira_acentos(str) {
  let com_acento = "ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ ";
  let sem_acento = "AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr";
  let novastr = "";
  let troca, i, a
  for (i = 0; i < str.length; i++) {
    troca = false;
    for (a = 0; a < com_acento.length; a++) {
      if (str.substr(i, 1) == com_acento.substr(a, 1)) {
        novastr += sem_acento.substr(a, 1);
        troca = true;
        break;
      }
    }
    if (troca == false) {
      novastr += str.substr(i, 1);
    }
  }
  return novastr;
}

export const pesquisacep = (valor) => {
  const responseCep = axios.get(`https://viacep.com.br/ws/${valor}/json/`)
    .then(function (response) {
    });

  return responseCep;

}