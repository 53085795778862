import React, { Component } from 'react'
import { connect } from 'react-redux';
import * as actions from '../../actions/inscricoes'
import ListaMaterialUi from '../../components/Listas/ListaMaterialUI'

class ListaArquivos extends Component {

    async componentDidMount() {
        const query = window.location.pathname
        const partes = query.split('/')
        await this.props.getInscricoesArquivos(parseInt(partes[3]))
        await this.props.getInscricoesArquivosAceitos(parseInt(partes[3]))
    }

    // { label: 'Documentos instituição', value: 'docs_instituicao' },
    // { label: 'Documentos de Identificação do candidato', value: 'docs_candidato' },
    // { label: 'Comprovantes da composição do grupo familiar', value: 'docs_grupo_familiar' },
    // { label: 'Comprovante de que se trata de Pessoa com Deficiência', value: 'docs_deficiencia' },
    // { label: 'Pensionistas', value: 'docs_pensionista' },
    // { label: 'Aposentado', value: 'docs_aposentado' },
    // { label: 'Proprietário de empresa', value: 'docs_empresa' },
    // { label: 'Rendimentos de aluguel ', value: 'docs_aluguel' },
    // { label: 'Desempregado ou quem nunca trabalhou', value: 'docs_desempregado' },
    // { label: 'Declaração de bens', value: 'docs_bens' },
    // { label: 'Para maiores de 16 anos que nunca trabalharam formalmente e mulheres com atividade exclusiva Do Lar', value: 'docs_nao_trabalha' },
    // { label: 'Moradia', value: 'docs_moradia' },
    // { label: 'Declaração de Imposto de Renda', value: 'docs_imposto' },
    // { label: 'Declaração de Autônomos', value: 'docs_autonomo' },

    render() {
        const { arquivos, inscricao, aceitos, notifyError, notifySuccess } = this.props
        let cpfCandidato = inscricao ? inscricao.cpf : '000.000.000-00'
        if (!cpfCandidato) { } else {
            cpfCandidato = cpfCandidato.replace(".", "");
            cpfCandidato = cpfCandidato.replace(".", "");
            cpfCandidato = cpfCandidato.replace("-", "");
        }
        return (
            <div>
                <div className='flex flex-1 horizontal'>
                    <ListaMaterialUi
                        cpf={cpfCandidato}
                        inscricao={inscricao}
                        titulo={'Documentos de Identificação do candidato'}
                        categoria={'docs_identificacao'}
                        params={arquivos && [
                            { nome: 'RG', value: arquivos.docs_rg, categoria: 'docs_rg', situacao: aceitos ? aceitos.docs_rg : false },
                            { nome: 'CPF', value: arquivos.docs_cpf, categoria: 'docs_cpf', situacao: aceitos ? aceitos.docs_cpf : false },
                            { nome: 'Comprovante Endereço', value: arquivos.docs_comprovante_endereco, categoria: 'docs_comprovante_endereco', situacao: aceitos ? aceitos.docs_comprovante_endereco : false },
                            { nome: 'Cartão de Vacina', value: arquivos.docs_cartao_vacina, categoria: 'docs_cartao_vacina', situacao: aceitos ? aceitos.docs_cartao_vacina : false },
                            { nome: 'Cad Único', value: arquivos.docs_comprovante_inscricao_cad_unico, categoria: 'docs_comprovante_inscricao_cad_unico', situacao: aceitos ? aceitos.docs_comprovante_inscricao_cad_unico : false },
                            { nome: 'LOAS', value: arquivos.docs_loas, categoria: 'docs_loas', situacao: aceitos ? aceitos.docs_loas : false },
                            { nome: 'Certidao de Casamento', value: arquivos.docs_certidao_casamento, categoria: 'docs_certidao_casamento', situacao: aceitos ? aceitos.docs_certidao_casamento : false },
                            { nome: 'Extrato Bancario', value: arquivos.docs_extrato_bancario, categoria: 'docs_extrato_bancario', situacao: aceitos ? aceitos.docs_extrato_bancario : false },
                            { nome: 'CTPS', value: arquivos.docs_carteira_trabalho, categoria: 'docs_carteira_trabalho', situacao: aceitos ? aceitos.docs_carteira_trabalho : false },
                            { nome: 'IRPF', value: arquivos.docs_declaracao_ir, categoria: 'docs_declaracao_ir', situacao: aceitos ? aceitos.docs_declaracao_ir : false },
                            { nome: 'Averbação divorcio', value: arquivos.docs_averbacao, categoria: 'docs_averbacao', situacao: aceitos ? aceitos.docs_averbacao : false },
                            { nome: 'Extrato INSS', value: arquivos.docs_extrato_beneficio, categoria: 'docs_extrato_beneficio', situacao: aceitos ? aceitos.docs_extrato_beneficio : false },
                            { nome: 'Laudo Medico', value: arquivos.docs_laudo_medico, categoria: 'docs_laudo_medico', situacao: aceitos ? aceitos.docs_laudo_medico : false },
                            { nome: 'Laudo Medico Constando o CID', value: arquivos.docs_laudo_deficiencia, categoria: 'docs_laudo_deficiencia', situacao: aceitos ? aceitos.docs_laudo_deficiencia : false },
                            { nome: 'Beneficiario de Pensao do INSS', value: arquivos.docs_beneficiario_pensao_inss, categoria: 'docs_beneficiario_pensao_inss', situacao: aceitos ? aceitos.docs_beneficiario_pensao_inss : false },
                            { nome: 'Beneficiario de Pensao alimenticia', value: arquivos.docs_beneficiario_pensao_alimenticia, categoria: 'docs_beneficiario_pensao_alimenticia', situacao: aceitos ? aceitos.docs_beneficiario_pensao_alimenticia : false },
                        ]}
                    />
                    <ListaMaterialUi
                        cpf={cpfCandidato}
                        inscricao={inscricao}
                        titulo={'Comprovantes da composição do grupo familiar'}
                        categoria={'docs_integrante'}
                        params={arquivos && [
                            { nome: 'RG Integrante', value: arquivos.docs_rg_integrante, categoria: 'docs_rg_integrante', situacao: aceitos ? aceitos.docs_rg_integrante : false },
                            { nome: 'CPF Integrante', value: arquivos.docs_cpf_integrante, categoria: 'docs_cpf_integrante', situacao: aceitos ? aceitos.docs_cpf_integrante : false },
                            { nome: 'Certidão de Nascimento Integrante', value: arquivos.docs_nascimento_integrante, categoria: 'docs_nascimento_integrante', situacao: aceitos ? aceitos.docs_nascimento_integrante : false },
                            { nome: 'Certidao de Casamento  Integrante', value: arquivos.docs_casamento_integrante, categoria: 'docs_integrante_certidao_casamento', situacao: aceitos ? aceitos.docs_integrante_certidao_casamento : false },
                            { nome: 'Contra-Cheque Integrante', value: arquivos.docs_contracheque_integrante, categoria: 'docs_contracheque_integrante', situacao: aceitos ? aceitos.docs_contracheque_integrante : false },
                            { nome: 'Extrato Integrante', value: arquivos.docs_extrato_integrante, categoria: 'docs_extrato_integrante', situacao: aceitos ? aceitos.docs_extrato_integrante : false },
                            { nome: 'CTPS Integrante', value: arquivos.docs_carteira_trabalho_integrante, categoria: 'docs_carteira_trabalho_integrante', situacao: aceitos ? aceitos.docs_carteira_trabalho_integrante : false },
                            { nome: 'Declarção IRPF Integrante', value: arquivos.docs_declaracao_ir_integrante, categoria: 'docs_declaracao_ir_integrante', situacao: aceitos ? aceitos.docs_declaracao_ir_integrante : false },
                            { nome: 'Averbação Integrante', value: arquivos.docs_averbacao_integrante, categoria: 'docs_averbacao_integrante', situacao: aceitos ? aceitos.docs_averbacao_integrante : false },
                            { nome: 'Laudo Integrante', value: arquivos.docs_laudo_integrante, categoria: 'docs_laudo_integrante', situacao: aceitos ? aceitos.docs_laudo_integrante : false },
                            { nome: 'Beneficiario INSS Integrante', value: arquivos.docs_beneficiario_inss_integrante, categoria: 'docs_beneficiario_inss_integrante', situacao: aceitos ? aceitos.docs_beneficiario_inss_integrante : false },
                            { nome: 'Pensão Alimenticia Integrante', value: arquivos.docs_beneficiario_pensao_integrante, categoria: 'docs_beneficiario_pensao_integrante', situacao: aceitos ? aceitos.docs_beneficiario_pensao_integrante : false },
                            { nome: 'Aposentado Integrante', value: arquivos.docs_aposentado_integrante, categoria: 'docs_aposentado_integrante', situacao: aceitos ? aceitos.docs_aposentado_integrante : false },
                            { nome: 'Autonomos Integrante', value: arquivos.docs_autonomos_integrante, categoria: 'docs_autonomos_integrante', situacao: aceitos ? aceitos.docs_autonomos_integrante : false }
                        ]}
                    />
                    <ListaMaterialUi
                        cpf={cpfCandidato}
                        inscricao={inscricao}
                        categoria={'docs_empresa'}
                        titulo={'Proprietário de empresa'}
                        params={arquivos && [
                            { nome: 'Imposto de renda', value: arquivos.docs_empresa_ir, categoria: 'docs_empresa_ir', situacao: aceitos ? aceitos.docs_empresa_ir : false },
                            { nome: 'DECORE', value: arquivos.docs_empresa_decore, categoria: 'docs_empresa_decore', situacao: aceitos ? aceitos.docs_empresa_decore : false },
                            { nome: 'INSS', value: arquivos.docs_empresa_inss, categoria: 'docs_empresa_inss', situacao: aceitos ? aceitos.docs_empresa_inss : false }
                        ]}
                    />
                </div>
                <div className='flex flex-1 horizontal'>
                    <ListaMaterialUi
                        cpf={cpfCandidato}
                        inscricao={inscricao}
                        categoria={'docs_aluguel'}
                        titulo={'Rendimentos de aluguel'}
                        params={arquivos && [
                            { nome: 'Boleto', value: arquivos.docs_aluguel_contrato, categoria: 'docs_aluguel_contrato', situacao: aceitos ? aceitos.docs_aluguel_contrato : false }
                        ]}
                    />
                    <ListaMaterialUi
                        cpf={cpfCandidato}
                        inscricao={inscricao}
                        categoria={'docs_bens'}
                        titulo={'Declaração de bens'}
                        params={arquivos && [
                            { nome: 'Declaração dos bens do grupo familiar', value: arquivos.docs_bens_declaracao, categoria: 'docs_bens_declaracao', situacao: aceitos ? aceitos.docs_bens_declaracao : false },
                            { nome: 'Comprovante de IPTU', value: arquivos.docs_bens_iptu, categoria: 'docs_bens_iptu', situacao: aceitos ? aceitos.docs_bens_iptu : false },
                            { nome: 'Documento de proprietário e/ou comprovante de financiamento', value: arquivos.docs_bens_veiculo, categoria: 'docs_bens_veiculo', situacao: aceitos ? aceitos.docs_bens_veiculo : false }
                        ]}
                    />
                    <ListaMaterialUi
                        cpf={cpfCandidato}
                        inscricao={inscricao}
                        categoria={'docs_nao_trabalha'}
                        titulo={'Para maiores de 16 anos que nunca trabalharam formalmente e mulheres com atividade exclusiva Do Lar'}
                        params={arquivos && [
                            { nome: 'Declaração de que nunca trabalhou formalmente', value: arquivos.docs_nao_trabalha_boleto, categoria: 'docs_nao_trabalha_boleto', situacao: aceitos ? aceitos.docs_nao_trabalha_boleto : false },
                            { nome: 'Extratos bancários dos últimos 03 (três) meses', value: arquivos.docs_nao_trabalha_pre_matricula, categoria: 'docs_nao_trabalha_pre_matricula', situacao: aceitos ? aceitos.docs_nao_trabalha_pre_matricula : false }
                        ]}
                    />
                </div>
                <div className='flex flex-1 horizontal'>
                    <ListaMaterialUi
                        cpf={cpfCandidato}
                        inscricao={inscricao}
                        categoria={'docs_instituicao'}
                        titulo={'Moradia'}
                        params={arquivos && [
                            { nome: 'Fatura de Água', value: arquivos.docs_moradia_agua, categoria: 'docs_moradia_agua', situacao: aceitos ? aceitos.docs_moradia_agua : false },
                            { nome: 'Fatura de Energia', value: arquivos.docs_moradia_energia, categoria: 'docs_moradia_energia', situacao: aceitos ? aceitos.docs_moradia_energia : false },
                            { nome: 'IPTU', value: arquivos.docs_moradia_iptu, categoria: 'docs_moradia_iptu', situacao: aceitos ? aceitos.docs_moradia_iptu : false },
                            { nome: 'Contrato Aluguel', value: arquivos.docs_aluguel_contrato, categoria: 'docs_aluguel_contrato', situacao: aceitos ? aceitos.docs_aluguel_contrato : false },
                            { nome: 'Outro documento hábil', value: arquivos.docs_moradia_outro, categoria: 'docs_moradia_outro', situacao: aceitos ? aceitos.docs_moradia_outro : false }
                        ]}
                    />
                </div>

            </div>
        )
    }
}

const mapStateToProps = state => ({
    aceitos: state.inscricoes.aceitos,
    arquivos: state.inscricoes.arquivos,
    inscricao: state.inscricoes.inscricao,
    usuario: state.auth.usuario
})
export default connect(mapStateToProps, actions)(ListaArquivos)