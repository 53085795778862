import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    rootModal: {
        width: '100%'
    },
    formControl: {
        width: '100%'
    },
    selectEmpty: {
        width: '100%'
    },
}));

export default ({ value, name, opcoes, onChange, error, classes = useStyles(), disabled, type }) => (

    <div className={type === 'modal' ? classes.rootModal : classes.root}>

        <FormControl className={classes.formControl}>
            <Select
                disabled={disabled}
                className={classes.selectEmpty}
                value={value}
                name={name}
                onChange={onChange}
            >
                {
                    opcoes.map((opcao, idx) => (
                        <MenuItem key={idx} value={opcao.value}>{opcao.label}</MenuItem>
                    ))
                }
            </Select>
            {error && (<small className="small-danger">{error}</small>)}
        </FormControl>
    </div >
)