import React, { Component } from 'react';
import BarraTopo from '../../Base/BarraTopo'
import Footer from '../../Base/Footer';
import Logo from '../../Base/logo'
import { Redirect } from 'react-router'

class Candidato extends Component {

    state = {
        open: false,
        redirect: false,
        erros: {}
    }
    render() {
        const { cpf, cpfEncoded, inscricao } = this.state;
        if (this.state.redirect) {
            return <Redirect to={{
                pathname: "/inscricao/novainscricao", state: { cpf: cpf, inscricao: this.state.inscricao }
            }} />
        }
        return (
            <div>
                <BarraTopo />
                <div className='flex flex-center'>
                    <Logo />
                </div>
                <div className="Candidato full-width" >
                    <div className='alert alert-info'>
                        <h1>
                            <b className='text-center'> Atenção a inscrição ainda não foi concluída. </b><br /><br />
                            Para prosseguir o usuário deverá fazer o login com o CPF do
                            idoso inscrito, para o anexo da documentação.<br /><br />
                            A senha padrão é <b>123456</b>, a qual poderá ser
                            alterada após o primeiro acesso.
                        </h1>
                    </div>
                </div>
                <Footer />
            </div>

        )
    }
}

export default Candidato
