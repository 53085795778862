import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import { MdCheckCircle, MdError, MdLink, MdMoodBad } from "react-icons/md";
import { apiURL } from '../../config/'

const Upload = ({ value, onChange, span, file, name, inscricaoDB, cpf2, categoria, inscricao, arquivos, progress, progressCategoria }) => (
    <div className='Input-Upload-Item vertical' style={{ padding: '20px', borderRadius: '30px', color: 'black' }}>
        <span style={{ textAlign: 'justify' }}>{span}</span><br />
        <div className='file-upload-wrapper' data-text={file ? file.name : 'Selecione seu arquivo!'}>
            <input type="file" onChange={onChange} name={name} accept="application/pdf" />
        </div>

        <br></br>

        <div className='flex horizontal' style={{ justifyContent: 'space-between' }}>
            {console.log(arquivos[name])}
            {arquivos[name] && <a href={`${apiURL}/filesInscricoes/${cpf2}/${categoria}/${arquivos ? arquivos[name] : ''}`} target='_blank'>Clique aqui para visualizar o arquivo</a>}
            {(progress > 0 && progress < 100 && progress === progressCategoria) &&
                <CircularProgressbar
                    styles={{
                        root: { width: 40 },
                        path: { stroke: "rgb(1 164 213)" },
                    }}
                    strokeWidth={10}
                    text={String(progress)}
                    value={progress || 0}
                />
            }
            {(progress === 100 && progress === progressCategoria) && (
                <MdCheckCircle size={40} color="rgb(7 133 63)" />
            )}
            {/* {progress === 100 && window.location.reload(true)} */}
            {/* {uploadedFile.error && <MdError size={24} color="#e57878" />} */}
        </div>

        <hr />
    </div>
)

export default Upload;