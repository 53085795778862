import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { gerarAptosAsync, selectAptos } from '../../features/Adm/AdmSlice';
import Button from '../../components/Button/Simples';
import AlertDanger from '../../components/Alert/Danger';

export default function GerarAptos() {
  const { aptos } = useSelector(selectAptos)
  const [err, setErr] = useState('')

  const dispatch = useDispatch();
  useEffect(() => {
    setErr(aptos)
  }, [aptos])

  const getAptos = () => {
    // if (window.confirm("Tem certeza? Essa ação apagará as mudanças feitas") === true) {
    //   dispatch(gerarAptosAsync())
    // } else { }
    alert("Processo Iniciado e fora da 2º Fase, não é possível gerar aptos!")

  }
  return (
    <div className="full-width">
      <AlertDanger error={err} classe='alert-success' />
      <br />
      <div className='btn-login flex flex-center'>
        <Button type='login' label={<i className='fa fa-spinner' > Gerar Aptos </i>} onClick={() => getAptos()} />
      </div>
    </div>
  )

}