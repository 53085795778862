import React from 'react';
import BaseCandidato from '../BaseCandidato';
import { connect } from 'react-redux';
import * as actions from '../../actions'

const baseCandidato = Component => {
  class ComponentBaseCandidato extends React.Component {

    async componentDidMount() {
      const { getUser, authorized, history, usuario } = this.props;
      await getUser();
      if (!this.props.authorized || !this.props.usuario || !this.props.usuario.permissao.includes('Candidato')) history.replace('/login');
    }
    componentDidUpdate(prevProps) {
      const { history } = this.props;
      if (!this.props.authorized ||
        !this.props.usuario ||
        !this.props.usuario.permissao.includes('Candidato')) {
        history.replace('/login');
      }

    }

    render() {
      return (
        <BaseCandidato history={this.props.history}>
          <Component {...this.props} />
        </BaseCandidato>
      )
    }
  }

  const mapStateToProps = state => ({
    authorized: state.auth.authorized,
    usuario: state.auth.usuario
  });

  return connect(mapStateToProps, actions)(ComponentBaseCandidato)
}

export default baseCandidato;
