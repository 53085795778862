import React, { Component } from 'react';

import NovaInscricao from './NovaInscricao/inscricao'

import { connect } from 'react-redux';
import * as actions from '../../actions/inscricoes';

class Inscricao extends Component {

  componentDidMount() {
    const { usuario } = this.props;
    const { id } = this.props.match.params;
    if (!usuario) return null
    this.props.getInscricao(id)
  }

  componentWillUnmount() {
    this.props.limparInscricao();
  }

  render() {
    return (
      <div className='Inscricao full-width flex vertical'>
        <div className='Card'>
          <NovaInscricao history={this.props.history} />
        </div>
      </div>

    )
  }
}

const mapStateToProps = state => ({
  usuario: state.auth.usuario
})

export default connect(mapStateToProps, actions)(Inscricao)
