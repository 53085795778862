import { GET_INSCRICOES, GET_INSCRICAO, GET_ACEITOS, LIMPAR_INSCRICAO, CANCELAR_INSCRICAO, CANCELAR_INTEGRANTE, GET_APTOS, GET_INTEGRANTES, GET_ARQUIVOS, GET_NAO_APTOS, GET_INSCRITO } from '../actions/types'

export default (state = {}, action) => {
  switch (action.type) {

    case GET_INSCRICOES:
      return {
        ...state,
        inscricoes: action.payload.inscricoes
      }
    case GET_INSCRICAO:
      return {
        ...state,
        apto: action.payload.apto,
        inscricao: action.payload.inscricao
      }
    case GET_INSCRITO:
      return {
        ...state,
        integrantes: action.payload.integrantes,
        inscricao: action.payload.inscricao,
        apto: action.payload.apto

      }
    case GET_ARQUIVOS:
      return {
        ...state,
        arquivos: action.payload.arquivos
      }
    case GET_ACEITOS:
      return {
        ...state,
        aceitos: action.payload.aceitos
      }
    case GET_APTOS:
      return {
        ...state,
        inscricao: action.payload.aptos
      }
    case GET_NAO_APTOS:
      return {
        ...state,
        inscricoes: action.payload.nao_aptos
      }
    case GET_INTEGRANTES:
      return {
        ...state,
        integrantes: action.payload.integrantes
      }
    case LIMPAR_INSCRICAO:
      return {
        ...state,
        inscricao: null
      }
    case CANCELAR_INTEGRANTE:
      return {
        ...state,
        integrante: { ...state.integrante, deletado: action.payload.deletado }
      }
    case CANCELAR_INSCRICAO:
      return {
        ...state,
        inscricao: { ...state.inscricao, deletado: action.payload.deletado }
      }

    default:
      return state;
  }
}
