import React, { Component } from 'react';
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridPrintExportOptions, GridToolbar } from '@mui/x-data-grid';
import { TextoSelect } from '../Texto/Dados'
import InputSelect from '../Inputs/Select'
import { api } from '../../config/'
import toast, { Toaster } from 'react-hot-toast';
import { Link } from 'react-router-dom';


export default class DataTable extends Component {

  state = {
    selectionModel: [],
    modalidade: ''
  }



  onChangeInput = (field, value) => this.setState({ [field]: value })

  render() {
    const { modalidade, selectionModel } = this.state

    function CustomToolbar() {
      return (
        <GridToolbarContainer >
          <GridToolbar />
          {selectionModel.length === 1 ?
            <Link to={`/adm/detalhes/${selectionModel[0]}`} ><i style={{ position: 'absolute', right: '50px', fontSize: '1.5rem' }} class="fa fa-eye" aria-hidden="true"></i></Link> : ''}
        </GridToolbarContainer>
      );
    }
    const { columns, rows } = this.props


    const notifyError = (erros) => {

      toast.error(erros, {
        duration: 4000,
        position: 'top-right',
        style: {
          border: '1px solid #713200',
          fontSize: '14px',
          color: '#713200',
        },
      })
    }

    const notifySuccess = (msg) => {

      toast.success(msg, {
        duration: 4000,
        position: 'top-right',
        style: {
          border: '1px solid #713200',
          fontSize: '14px',
          color: '#713200',
        },
      })
      setTimeout(function () {
        window.location = '/'
      }, 4000)
    }

    const handleSubmit = (form, cb) => dispatch => {
      // eslint-disable-next-line no-restricted-globals
      if (confirm('Tem certeza que deseja gerar esses aptos?')) {
        api.put(`/api/v1/aptos`, {
          modalidade,
          selectionModel
        })
          .then(response => notifySuccess('Aptos gerados com sucesso!'))
          .catch((e) => notifyError('Houve um problema com sua inscrição, tente novamente!'));
      } else {
        return false;
      }
    }


    let cabecalho = columns.map(item => (
      {
        field: item.field,
        headerName: item.headerName,
        width: item.width ? item.width : 150,
        hide: item.hide ? item.hide : false
      }
    ))

    return (
      <div style={{ height: 400, width: '100%' }}>
        <Toaster />

        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={20}
          checkboxSelection
          disableSelectionOnClick

          components={{
            Toolbar: CustomToolbar,
          }}
          onSelectionModelChange={(newSelection) => {
            this.setState({ selectionModel: newSelection });
          }}
        />
      </div>
    );
  }
}

export class InscricoesGrid extends Component {

  state = {
    selectionModel: [],
    modalidade: ''
  }



  onChangeInput = (field, value) => this.setState({ [field]: value })

  render() {
    const { modalidade, selectionModel } = this.state

    function CustomToolbar() {
      return (
        <GridToolbarContainer>
          <GridToolbar />
          {selectionModel.length === 1 ?
            <Link to={`/adm/inscrito/${selectionModel[0]}`} ><i style={{ position: 'absolute', right: '50px', fontSize: '1.5rem' }} class="fa fa-eye" aria-hidden="true"></i></Link> : ''}
        </GridToolbarContainer>
      );
    }
    const { columns, rows } = this.props


    const notifyError = (erros) => {

      toast.error(erros, {
        duration: 4000,
        position: 'top-right',
        style: {
          border: '1px solid #713200',
          fontSize: '14px',
          color: '#713200',
        },
      })
    }

    const notifySuccess = (msg) => {

      toast.success(msg, {
        duration: 4000,
        position: 'top-right',
        style: {
          border: '1px solid #713200',
          fontSize: '14px',
          color: '#713200',
        },
      })
      setTimeout(function () {
        window.location = '/'
      }, 4000)
    }

    const handleSubmit = (form, cb) => dispatch => {
      // eslint-disable-next-line no-restricted-globals
      if (confirm('Tem certeza que deseja gerar esses aptos?')) {
        api.put(`/api/v1/aptos`, {
          modalidade,
          selectionModel
        })
          .then(response => notifySuccess('Aptos gerados com sucesso!'))
          .catch((e) => notifyError('Houve um problema com sua inscrição, tente novamente!'));
      } else {
        return false;
      }
    }


    let cabecalho = columns.map(item => (
      {
        field: item.field,
        headerName: item.headerName,
        width: item.width ? item.width : 150,
        hide: item.hide ? item.hide : false
      }
    ))

    return (
      <div style={{ height: 400, width: '100%' }}>
        <Toaster />
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={20}
          checkboxSelection
          disableSelectionOnClick
          components={{
            Toolbar: CustomToolbar,
          }}
          onSelectionModelChange={(newSelection) => {
            this.setState({ selectionModel: newSelection });
          }}
        />
      </div>
    );
  }
}