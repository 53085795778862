import React, { Component } from 'react';
import { getHeaders } from '../../../actions/localStorage'
import InputSelect from '../../../components/Inputs/Select'
import { TextoSelect } from '../../../components/Texto/Dados'

import { api } from '../../../config/'
import { connect } from 'react-redux';
import * as actions from '../../../actions/inscricoes';

import DocsIdentificacao from './docs_identificacao_candidato';
import DocsIntegrante from './docs_integrante';
import DocsEmpresa from './docs_empresa';
import DocsAluguel from './docs_aluguel';
import DocsNaoTrabalha from './docs_nao_trabalha';
import DocsBens from './docs_bens';
import DocsMoradia from './docs_moradia';



class UploadDocs extends Component {

    state = {
        categoria: '',
        file: '',
        arquivos: ''
    }

    async componentDidMount() {
        const { usuario } = this.props;
        this.props.getInscricao()
        const tokenStr = getHeaders().headers.authorization
        await api.get('/api/v1/inscricoes/arquivos/', {
            headers: { "Authorization": tokenStr }
        }).then((response) => {
            this.setState({ arquivos: response.data.arquivos })
        })
        this.props.getInscricao()
        //if (!usuario) return null
    }

    componentWillUnmount() {
        this.props.limparInscricao();
    }


    //UPLOAD ARQUIVO NO BANCO
    async uploadFile() {
        const { file, categoria } = this.state
        const { inscricao } = this.props
        const formData = new FormData();
        formData.append('file', file)

        const myHeaders = {
            'Content-Type': 'multipart/form-data'
        }
        var cpf2 = inscricao.cpf;
        cpf2 = cpf2.replace(".", "");
        cpf2 = cpf2.replace(".", "");
        cpf2 = cpf2.replace("-", "");

        await api.put(`/inscricoes/docs/${inscricao.id}?cpf=${cpf2}&categoria=${categoria}`, formData, {

            headers: myHeaders
        }).then(res => {
            alert('Feito')
        })
            .catch(err => console.log(err))
    }

    onChangeInput = (field, value) => this.setState({ [field]: value })

    //CARREGAR ARQUIVO
    handleChangeFile = (e) => {
        this.setState({ progress: 0 })
        var file = e.target.files[0]; // accesing file 
        this.setState({ file, categoria: this.state.categoria }); // storing file

    }

    render() {

        const { categoria, arquivos } = this.state
        const { inscricao, usuario } = this.props
        return (
            <div className='Inscricao full-width center'>
                <div className='Upload-Docs' style={{ textAlign: '-webkit-center' }}>
                    <TextoSelect
                        chave='Categoria *'
                        valor={(
                            <InputSelect
                                name='categoria'
                                onChange={(ev) => (this.onChangeInput('categoria', ev.target.value))}
                                value={categoria}
                                opcoes={[
                                    { label: 'Documentos de Identificação do candidato', value: 'docs_candidato' },
                                    { label: 'Comprovantes da composição do grupo familiar', value: 'docs_grupo_familiar' },
                                    { label: 'Proprietário de empresa', value: 'docs_empresa' },
                                    { label: 'Rendimentos de aluguel ', value: 'docs_aluguel' },
                                    { label: 'Declaração de bens', value: 'docs_bens' },
                                    { label: 'Para maiores de 16 anos que nunca trabalharam formalmente e mulheres com atividade exclusiva Do Lar', value: 'docs_nao_trabalha' },
                                    { label: 'Moradia', value: 'docs_moradia' },
                                ]}
                            //  error={erros.universidade}
                            />
                        )} />

                    {categoria === 'docs_candidato' &&
                        <DocsIdentificacao inscricao={inscricao} usuario={usuario} arquivos={arquivos ? arquivos : []} />
                    }

                    {categoria === 'docs_grupo_familiar' &&
                        <DocsIntegrante inscricao={inscricao} usuario={usuario} arquivos={arquivos ? arquivos : []} />
                    }

                    {categoria === 'docs_empresa' &&
                        <DocsEmpresa inscricao={inscricao} usuario={usuario} arquivos={arquivos ? arquivos : []} />
                    }

                    {categoria === 'docs_aluguel' &&
                        <DocsAluguel inscricao={inscricao} usuario={usuario} arquivos={arquivos ? arquivos : []} />
                    }

                    {categoria === 'docs_bens' &&
                        <DocsBens inscricao={inscricao} usuario={usuario} arquivos={arquivos ? arquivos : []} />
                    }
                    {categoria === 'docs_nao_trabalha' &&
                        <DocsNaoTrabalha inscricao={inscricao} usuario={usuario} arquivos={arquivos ? arquivos : []} />
                    }

                    {categoria === 'docs_moradia' &&
                        <DocsMoradia inscricao={inscricao} usuario={usuario} arquivos={arquivos ? arquivos : []} />
                    }


                    <br /><br />


                </div>



            </div >

        )
    }
}

const mapStateToProps = state => ({
    inscricao: state.inscricoes.inscricao,
    usuario: state.auth.usuario
})

export default connect(mapStateToProps, actions)(UploadDocs)
