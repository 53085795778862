import React, { Component } from 'react'
import ListaAptos from './listaAptos'

export default class AreaAdm extends Component {

  render() {
    return (
      <div className="Inscricoes full-width">
        PÁGINA INICIAL
      </div>
    )

  }
}