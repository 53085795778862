import React from 'react'
import { cleanToken } from '../../actions/localStorage'

function funcaoLogout() {
  cleanToken()
  setTimeout(function () {
    window.location = '/'
  }, 500)
}
const BarraTopo = ({ handleLogout }) => (
  <div className="Barra-Topo flex horizontal full-width">
    <div className='flex horizontal flex-end'>
      <span onClick={() => funcaoLogout()}><i className="fa fa-sign-out" aria-hidden="true"></i> Sair</span>
    </div>
  </div>
)

export default BarraTopo
