import React from 'react';
import '../../../css/index.css'

import BarraTopo from './BarraTopo';
import Logo from './logo';
import SubFooter from './SubFooter';
import Footer from './Footer';
import { connect } from 'react-redux';
import * as actions from '../../actions'

class Dashboard extends React.Component {
  render() {
    return (
      <div className='flex horizontal full-height'>
        <div className='flex vertical full-width'>
          <div className='flex horizontal'>
            <BarraTopo history={this.props.history} handleLogout={this.props.handleLogout} />
          </div>
          <Logo className='img-logo full-width' />
          <main className='flex '>
            {this.props.children}
          </main>
          <br />
          <Footer />
        </div>
      </div>
    )
  }
}

export default connect(null, actions)(Dashboard);
