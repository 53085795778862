import React from 'react';
import Base from '../Base';
import { connect } from 'react-redux';
import * as actions from '../../actions'

const baseInscricao = Component => {
  class ComponentBaseInscricao extends React.Component {

    async componentDidMount() {
      const { state } = this.props.location
      if (!state) window.location.href = "/";
    }

    componentDidUpdate(prevProps) {
      const { history } = this.props;
      if (!this.props.authorized ||
        !this.props.usuario ||
        !this.props.usuario.permissao.includes('Adm')) {
        history.replace('/candidato');
      }

    }

    render() {
      return (
        <Base history={this.props.history}>
          <Component {...this.props} />
        </Base>
      )
    }
  }

  const mapStateToProps = state => ({
    authorized: state.auth.authorized,
    usuario: state.auth.usuario
  });

  return connect(mapStateToProps, actions)(ComponentBaseInscricao)
}

export default baseInscricao;
