import React, { Component } from 'react';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import { api } from '../../../config/'
import moment from 'moment'

import { connect } from 'react-redux';
import * as actions from '../../../actions';

class FinalizarInscricao extends Component {
  state = {
    checkedA: false,
    checkedB: true,
    botoesDisabled: false,
    botoes: true,
    inscricao: ''
  };

  handleChange = (event) => {
    this.setState({
      ...this.state, [event.target.name]: event.target.checked
    });
    this.setState({
      ...this.state, [event.target.name]: event.target.checked
    })
  };

  handleChangeButton = (event) => {
    if (this.state.checkedA) {
      return <button>Loading...</button>
    }
  }

  render() {
    const {
      cpf,
      nome,
      rg,
      nis,
      sexo,
      dataNascimento,
      estadoCivil,
      email,
      escolaridade,
      nomeDaMae,
      nomeDoPai,
      cpfDaMae,
      cpfDoPai,
      naturalidade,
      egresso,
      maeSolo,
      responsavelFinanceiro,
      rendaMensalTitular,
      pessoaComDeficiencia,
      classificacaoDeficiencia,
      cidDeficiencia,
      quantidadeImoveis,

      telefoneContato,
      logradouro,
      numero,
      complemento,
      bairro,
      cidade,
      quadra,
      lote,
      pontoReferencia,
      dataResideAnapolis,
      celular,

      integrantes,

      beneficio,
      qualBeneficio,
      cras,
      qualCras,
      deficiencia,
      qualDeficiencia,
      locomove,
      comunica,
      protese,
      qualProtese,
      aparelho,
      qualAparelho,
      portadorDoenca,
      qualDoenca,
      usoContinuo,
      qualUsoContinuo,

      respIdoso,
      possueDefResp,
      qualDefResp,
      qualCIDResp,
      cpfResp,
      nomeResp,
      cepResp,
      pesquisacepResp,
      logradouroResp,
      numeroResp,
      complementoResp,
      bairroResp,
      quadraResp,
      loteResp,
      pontoReferenciaResp,
      telefoneContatoResp,

      resideSozinho,
      residenciaAlugada,
      cep,
      notifyError,
      notifySuccess,
    } = this.props

    const handleSubmit = (form, cb) => dispatch => {
      api.post(`/api/v1/inscricoes`, {
        cpf,
        nome,
        rg,
        nis,
        sexo,
        dataNascimento: moment(dataNascimento).format('YYYY-MM-DD'),
        estadoCivil,
        email,
        escolaridade,
        nomeDaMae,
        nomeDoPai,
        cpfDaMae,
        cpfDoPai,
        naturalidade,
        egresso,
        maeSolo,
        responsavelFinanceiro,
        rendaMensalTitular: parseFloat(rendaMensalTitular.replace('R$ ', '').replace('.', '').replace(',', '.')),
        pessoaComDeficiencia,
        classificacaoDeficiencia,
        cidDeficiencia,
        quantidadeImoveis,
        integrantes,
        telefoneContato,
        logradouro,
        quadra,
        lote,
        numero,
        complemento,
        bairro,
        cidade,
        pontoReferencia,
        dataResideAnapolis: moment(dataResideAnapolis).format('YYYY-MM-DD'),
        celular,
        cep,
        beneficio,
        qualBeneficio,
        cras,
        qualCras,
        deficiencia,
        qualDeficiencia,
        locomove,
        comunica,
        protese,
        qualProtese,
        aparelho,
        qualAparelho,
        portadorDoenca,
        qualDoenca,
        usoContinuo,
        qualUsoContinuo,
        respIdoso,
        possueDefResp,
        qualDefResp,
        qualCIDResp,
        cpfResp,
        nomeResp,
        cepResp,
        pesquisacepResp,
        logradouroResp,
        numeroResp,
        complementoResp,
        bairroResp,
        quadraResp,
        loteResp,
        pontoReferenciaResp,
        telefoneContatoResp,
        resideSozinho,
        residenciaAlugada

      })
        .then(response => notifySuccess('Inscrição realizada com sucesso!'))
        .catch((e) => notifyError('Houve um problema com sua inscrição, tente novamente!'));
    };

    return (
      <div className='Inscricao full-width flex vertical'>
        <div >
          <div className='Container-Inscricao'>
            <div className='Instrucao-Integrante-Familiar'>
              Declaro estar ciente que a inveracidade das informações aqui prestadas, quando constatada, implicará na desclassificação do programa. Para tanto, assumo as responsabilidades regimentais, civis e criminais, podendo ser realizadas visitas em meu domicílio para comprovação e/ou confirmação das informações expressas nesta inscrição.<br /><br />

              Autorizo a busca acerca de informações socioeconômicas tanto do meu grupo familiar quanto minhas, junto ao Ministério do Trabalho e emprego (SRTE/GO), bem como o cruzamento das respectivas informações aqui declaradas com as constantes no banco de dados deste Órgão Público.<br /><br />
            </div>
          </div>
          <div>

            <Switch
              checked={this.state.checkedA}
              onChange={this.handleChange}
              color="primary"
              name="checkedA"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
            Declaro que li e estou ciente das condições para a inscrição no programa, e desejo finalizá-la.
          </div>
          <br />
          <Button disabled={!this.state.checkedA === true} onClick={handleSubmit()} type="submit" variant="contained" style={this.state.checkedA === true ? { backgroundColor: 'green', color: 'white' } : { backgroundColor: '' }} disableElevation>
            Confirmar Inscrição
          </Button>

        </div>
      </div>

    )
  }
}

export default connect(null, actions)(FinalizarInscricao)
