import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions'

const noAuth = Component => {
  class ComponentNoAuth extends React.Component {

    async componentDidMount() {
      const { getUser, history, } = this.props;
      await getUser();
      if (this.props.authorized && this.props.usuario.permissao.includes('Adm')) history.replace('/adm');
      if (this.props.authorized && this.props.usuario.permissao.includes('Candidato')) history.replace('/candidato');
    }
    componentDidUpdate(prevProps) {
      const { authorized, history } = prevProps;
      if (!authorized && this.props.authorized && this.props.usuario.permissao.includes('Adm')) history.replace('/');
    }

    render() {
      return (
        <div>
          <Component {...this.props} />
        </div>
      )
    }
  }

  const mapStateToProps = state => ({
    authorized: state.auth.authorized,
    usuario: state.auth.usuario
  });

  return connect(mapStateToProps, actions)(ComponentNoAuth)
}

export default noAuth;
