import React from "react";
import pdfFonts from "pdfmake/build/vfs_fonts";
const $ = require('jquery');
$.DataTable = require('datatables.net-bs4');
require('jquery');
require('datatables.net-bs4');
require('datatables.net-buttons-bs4');
require('datatables.net-buttons/js/buttons.html5.js');
require('datatables.net-buttons/js/buttons.print.js');
require('datatables.net-responsive-bs4');
const jszip = require('jszip');
const pdfmake = require('pdfmake');
pdfmake.vfs = pdfFonts.pdfMake.vfs;
window.JSZip = jszip;

function buscaID(id) {
    window.location = "/adm/detalhes/" + id;
}

const columnsIntegrais = [
    { title: 'Ordem', width: "auto", data: 'classificacao' },
    { title: 'Nº Inscrição', width: "auto", data: 'id' },
    { title: 'Nome', width: "auto", data: 'nome' },
    { title: 'Curso', width: "auto", data: 'curso' },
    { title: 'Criterio 5.1-I', width: 'auto', data: 'pontos_renda' },
    { title: 'Criterio 5.1-II-a', width: 'auto', data: 'pontos_mae_solo' },
    { title: 'Criterio 5.1-II-b', width: 'auto', data: 'pontos_arrimo_familia' },
    { title: 'Criterio 5.1-II-c', width: 'auto', data: 'ponto_idoso' },
    { title: 'Criterio 5.1-II-d', width: 'auto', data: 'pontos_egresso_sms' },
    { title: 'Total Pontuação', width: 'auto', data: 'totalPontos' },
    { title: 'PCD', width: 'auto', data: 'pcd' },
];

export function DataTableIntegrais(props) {
    $('#table').DataTable({
        dom: 'Bfrtip',
        responsive: true,
        buttons: [
            {
                extend: 'pdf', className: 'btn-pdf', text: 'PDF',
                orientation: 'landscape',
                pageSize: 'A4',
                filename: "Integrais",
                layout: 'border solid 1px',
                exportOptions: {
                    columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],

                },
                footer: {
                    columns: [
                        'Left part',
                        { text: 'Right part', alignment: 'right' }
                    ]
                },
                customize: function (doc) {
                    var custom = doc.content[1].table
                    custom.widths = ['5%', '5%', '30%', '13%', '7%', '7%', '7%', '7%', '7%', '7%', '5%']
                    var objLayout = {};
                    objLayout['hLineWidth'] = function (i) { return .5; };
                    objLayout['vLineWidth'] = function (i) { return .5; };
                    objLayout['hLineColor'] = function (i) { return '#aaa'; };
                    objLayout['vLineColor'] = function (i) { return '#aaa'; };
                    objLayout['paddingLeft'] = function (i) { return 4; };
                    objLayout['paddingRight'] = function (i) { return 4; };
                    doc.content[1].layout = objLayout;
                }
            },
            {
                extend: 'excel', className: 'btn-excel', download: 'open', text: 'Excel',
                orientation: 'landscape',
                filename: "Integrais",
                pageSize: 'A4',
                layout: 'border solid 1px',
                // exportOptions: {
                //     columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
                // },
            },
            // {
            //     extend: 'print', className: 'btn-print', text: 'Imprimir',
            //     orientation: 'landscape',
            //     pageSize: 'A4',
            //     layout: 'border solid 1px',
            //     exportOptions: {
            //         columns: [0, 1, 2, 3]
            //     },
            // }
        ],

        data: props.inscricoes,
        columns: columnsIntegrais,
        destroy: true,
        ordering: false,
        'language': {
            'lengthMenu': 'Mostar _MENU_ Registro por Pagina',
            'zeroRecords': 'Nada foi encontrado',
            'info': 'Mostrando _PAGE_ de _PAGES_',
            'infoEmpty': 'Não foram encontrados Registros',
            'infoFiltered': '(Filtrados de _MAX_ do Total)',
            'search': 'Buscar:',
            "paginate": {
                "first": "Primeiro",
                "last": "Ultimo",
                "next": "Proximo",
                "previous": "Anterior"
            },
        },
        "rowCallback": function (row, data) {
            $('td', row).click(
                function () {
                    buscaID(data.id);
                })
            $('td', row).css('cursor', 'pointer')
            $('td:eq(3)', row).css('display', '')
            $('td:eq(1)', row).css('display', '')
            $('td:eq(0)', row).css('display', '')
            $('td:eq(0)', row).css('display', '')
        },
        "headerCallback": function (thead, data, display) {

            $('th:eq(0)', thead).css('display', '')
            $('th:eq(1)', thead).css('display', '')
            $('th:eq(3)', thead).css('display', '')
            $('td:eq(4)', thead).css('white-space', 'normal')
        },

    });
    return (
        <div>
            <table id="table" className="data-table-wrapper form-center table table-bordered display table-action wrap dataTable no-footer" style={{ width: 100 + "%" }} refs="main"></table>
        </div>);
}
