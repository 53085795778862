import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import DadosPessoais from './DadosPessoais';
import DadosEnderecoContato from './DadosEnderecoContato'
import IntegranteFamiliar from './IntegranteFamiliar'
import DadosSaude from './DadosSaude'
import FinalizarInscricao from './FinalizarInscricao'
import toast, { Toaster } from 'react-hot-toast';
import ResponsavelIdoso from './ResponsavelIdoso';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}



TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '90vw',
    backgroundColor: theme.palette.background.paper,
  },
  tabs: {
    padding: '0'
  },
}));

export default function FullWidthTabs(props) {

  const notifyError = (erros) => {

    toast.error(erros, {
      duration: 4000,
      position: 'top-right',
      style: {
        border: '1px solid #713200',
        fontSize: '14px',
        color: '#713200',
      },
    })
  }

  const notifySuccess = (msg) => {

    toast.success(msg, {
      duration: 4000,
      position: 'top-right',
      style: {
        border: '1px solid #713200',
        fontSize: '14px',
        color: '#713200',
      },
    })
    setTimeout(function () {
      window.location = '/FimInscricao'
    }, 2000)
  }
  const theme = useTheme();
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [erros, setErros] = useState('')
  const [errosSaude, setErrosSaude] = useState('')
  const [errosEndereco, setErrosEndereco] = useState('')
  const [errosResponsavel, setErrosResponsavel] = useState('')
  const [cpf, setCpf] = useState(!props.cpf ? '' : props.cpf)
  const [nome, setNome] = useState(!props.inscricao ? '' : props.inscricao.nome)
  const [rg, setRg] = useState(!props.inscricao ? '' : props.inscricao.rg)
  const [nis, setNis] = useState('')
  const [sexo, setSexo] = useState(!props.inscricao ? '' : props.inscricao.sexo)
  const [dataNascimento, setDataNascimento] = useState(!props.inscricao ? '' : props.inscricao.dt_nascimento)
  const [estadoCivil, setEstadoCivil] = useState('')
  const [email, setEmail] = useState('')
  const [escolaridade, setEscolaridade] = useState('')
  const [nomeDaMae, setNomeDaMae] = useState(!props.inscricao ? '' : props.inscricao.nome_mae)
  const [nomeDoPai, setNomeDoPai] = useState('')
  const [cpfDaMae, setCpfDaMae] = useState('')
  const [cpfDoPai, setCpfDoPai] = useState('')
  const [naturalidade, setNaturalidade] = useState('')
  const [responsavelFinanceiro, setResponsavelFinanceiro] = useState(false)
  const [rendaMensalTitular, setRendaMensalTitular] = useState('')
  const [pessoaComDeficiencia, setPessoaComDeficiencia] = useState(!props.inscricao ? '' : props.inscricao.pne)
  const [classificacaoDeficiencia, setClassificacaoDeficiencia] = useState('')
  const [cidDeficiencia, setCidDeficiencia] = useState('')

  const [beneficio, setBeneficio] = useState(false)
  const [qualBeneficio, setQualbeneficio] = useState('nenhum')
  const [cras, setCras] = useState(false)
  const [qualCras, setQualcras] = useState('')
  const [deficiencia, setDeficiencia] = useState(false)
  const [qualDeficiencia, setQualdeficiencia] = useState('nenhum')
  const [locomove, setLocomove] = useState(false)
  const [comunica, setComunica] = useState(false)
  const [protese, setProtese] = useState(false)
  const [qualProtese, setQualprotese] = useState('')
  const [aparelho, setAparelho] = useState(false)
  const [qualAparelho, setQualaparelho] = useState('')
  const [portadorDoenca, setPortadordoenca] = useState(false)
  const [qualDoenca, setQualdoenca] = useState('')
  const [usoContinuo, setUsocontinuo] = useState(false)
  const [qualUsoContinuo, setQualusocontinuo] = useState('')


  const [cep, setCep] = useState(!props.inscricao ? '' : props.inscricao.cep)
  const [telefoneContato, setTelefoneContato] = useState('')
  const [logradouroProps, setLogradouro] = useState(!props.inscricao ? '' : props.inscricao.rua)
  const [quadra, setQuadra] = useState(!props.inscricao ? '' : props.inscricao.quadra)
  const [lote, setLote] = useState(!props.inscricao ? '' : props.inscricao.lote)
  const [numero, setNumero] = useState(!props.inscricao ? '' : props.inscricao.numero)
  const [complemento, setComplemento] = useState(!props.inscricao ? '' : props.inscricao.complemento)
  const [bairro, setBairro] = useState(!props.inscricao ? '' : props.inscricao.bairro)
  const [dataResideAnapolis, setDataResideAnapolis] = useState('')
  const [pontoReferencia, setPontoReferencia] = useState('')
  const [celular, setCelular] = useState(!props.inscricao ? '' : props.inscricao.telefone)
  const [cidade, setCidade] = useState('Anápolis')
  const [residenciaAlugada, setResidenciaAlugada] = useState('nenhum')
  const [resideSozinho, setResideSozinho] = useState('nenhum')

  const [integrantes, setIntegrantes] = useState('')
  const [rendaTotal, setRendaTotal] = useState(0)

  const [respIdoso, setRespIdoso] = useState(false)
  const [possueDefResp, setPossueDefResp] = useState(false)
  const [qualDefResp, setQualDefResp] = useState('')
  const [qualCIDResp, setQualCIDResp] = useState('')
  const [cpfResp, setCpfResp] = useState('')
  const [nomeResp, setNomeResp] = useState('')
  const [cepResp, setCepResp] = useState('')
  const [logradouroResp, setLogradouroResp] = useState('')
  const [numeroResp, setNumeroResp] = useState('')
  const [complementoResp, setComplementoResp] = useState('')
  const [bairroResp, setBairroResp] = useState('')
  const [quadraResp, setQuadraResp] = useState(0)
  const [loteResp, setLoteResp] = useState(0)
  const [pontoReferenciaResp, setPontoReferenciaResp] = useState('')
  const [telefoneContatoResp, setTelefoneContatoResp] = useState('')


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleNext = (idx) => {
    if (idx === 0 && (Object.values(erros).length)) return notifyError('Preencha todos os campos obrigatórios na parte pessoal')
    if (idx === 1 && Object.values(errosSaude).length > 0) return notifyError('Preencha todos os campos obrigatórios na aba Saude se necessário')
    if (idx === 2 && Object.values(errosEndereco).length > 0) return notifyError('Preencha todos os campos obrigatórios no endereço')
    if (idx === 4 && Object.values(errosResponsavel).length > 0) return notifyError('Preencha todos os campos obrigatórios na aba Responsavel pelo idoso')
    setValue((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setValue((prevActiveStep) => prevActiveStep - 1);
  };
  console.log(cpfResp)
  return (
    <div>
      <Toaster />
      <div className={classes.root}>

        <AppBar position="static" color="default">
          <Tabs

            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="full width tabs example"
          >
            <Tab label="Dados Pessoais" {...a11yProps(0)} />
            <Tab label="Saude" {...a11yProps(1)}
              disabled={
                Object.values(erros).length > 0
              }
            />
            <Tab label="Informações de moradia" {...a11yProps(2)}
              disabled={
                Object.values(erros).length > 0 ||
                Object.values(errosSaude).length > 0
              }
            />
            <Tab label="Integrante Familiar" {...a11yProps(3)}
              disabled={
                Object.values(erros).length > 0 ||
                Object.values(errosSaude).length > 0 ||
                Object.values(errosEndereco).length > 0
              }
            />
            <Tab label="Responsavel do Idoso" {...a11yProps(4)}
              disabled={
                Object.values(erros).length > 0 ||
                Object.values(errosSaude).length > 0 ||
                Object.values(errosEndereco).length > 0
              }
            />
            <Tab label="Confirmar e enviar" {...a11yProps(5)}
              disabled={
                Object.values(erros).length > 0 ||
                Object.values(errosSaude).length > 0 ||
                Object.values(errosEndereco).length > 0 ||
                Object.values(errosResponsavel).length > 0
              }
            />
          </Tabs>
        </AppBar>
        <TabPanel className={classes.tabs} value={value} index={0} dir={theme.direction}>
          <DadosPessoais
            erros={erros} setErros={setErros}
            nome={nome} setNome={setNome}
            cpf={props.cpf} setCpf={setCpf}
            rg={rg} setRg={setRg}
            nis={nis} setNis={setNis}
            sexo={sexo} setSexo={setSexo}
            dataNascimento={dataNascimento} setDataNascimento={setDataNascimento}
            estadoCivil={estadoCivil} setEstadoCivil={setEstadoCivil}
            email={email} setEmail={setEmail}
            escolaridade={escolaridade} setEscolaridade={setEscolaridade}
            nomeDaMae={nomeDaMae} setNomeDaMae={setNomeDaMae}
            nomeDoPai={nomeDoPai} setNomeDoPai={setNomeDoPai}
            cpfDaMae={cpfDaMae} setCpfDaMae={setCpfDaMae}
            cpfDoPai={cpfDoPai} setCpfDoPai={setCpfDoPai}
            naturalidade={naturalidade} setNaturalidade={setNaturalidade}
            responsavelFinanceiro={responsavelFinanceiro} setResponsavelFinanceiro={setResponsavelFinanceiro}
            rendaMensalTitular={rendaMensalTitular} setRendaMensalTitular={setRendaMensalTitular}
            pessoaComDeficiencia={pessoaComDeficiencia} setPessoaComDeficiencia={setPessoaComDeficiencia}
            classificacaoDeficiencia={classificacaoDeficiencia} setClassificacaoDeficiencia={setClassificacaoDeficiencia}
            cidDeficiencia={cidDeficiencia} setCidDeficiencia={setCidDeficiencia}
          />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <DadosSaude
            beneficio={beneficio} setBeneficio={setBeneficio}
            qualBeneficio={qualBeneficio} setQualbeneficio={setQualbeneficio}
            cras={cras} setCras={setCras}
            qualCras={qualCras} setQualcras={setQualcras}
            deficiencia={deficiencia} setDeficiencia={setDeficiencia}
            qualDeficiencia={qualDeficiencia} setQualdeficiencia={setQualdeficiencia}
            locomove={locomove} setLocomove={setLocomove}
            comunica={comunica} setComunica={setComunica}
            protese={protese} setProtese={setProtese}
            qualProtese={qualProtese} setQualprotese={setQualprotese}
            aparelho={aparelho} setAparelho={setAparelho}
            qualAparelho={qualAparelho} setQualaparelho={setQualaparelho}
            portadorDoenca={portadorDoenca} setPortadordoenca={setPortadordoenca}
            qualDoenca={qualDoenca} setQualdoenca={setQualdoenca}
            usoContinuo={usoContinuo} setUsocontinuo={setUsocontinuo}
            qualUsoContinuo={qualUsoContinuo} setQualusocontinuo={setQualusocontinuo}
            errosSaude={errosSaude} setErrosSaude={setErrosSaude} />
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <DadosEnderecoContato
            errosEndereco={errosEndereco} setErrosEndereco={setErrosEndereco}
            logradouroProps={logradouroProps} setLogradouro={setLogradouro}
            numero={numero} setNumero={setNumero}
            complemento={complemento} setComplemento={setComplemento}
            bairro={bairro} setBairro={setBairro}
            cidade={cidade} setCidade={setCidade}
            quadra={quadra} setQuadra={setQuadra}
            lote={lote} setLote={setLote}
            telefoneContato={telefoneContato} setTelefoneContato={setTelefoneContato}
            dataResideAnapolis={dataResideAnapolis} setDataResideAnapolis={setDataResideAnapolis}
            pontoReferencia={pontoReferencia} setPontoReferencia={setPontoReferencia}
            celular={celular} setCelular={setCelular}
            resideSozinho={resideSozinho} setResideSozinho={setResideSozinho}
            residenciaAlugada={residenciaAlugada} setResidenciaAlugada={setResidenciaAlugada}
            cep={cep} setCep={setCep} />
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
          <IntegranteFamiliar
            integrantes={integrantes} setIntegrantes={setIntegrantes}
            rendaTotal={rendaTotal} setRendaTotal={setRendaTotal}
            rendaMensalTitular={rendaMensalTitular}
          />
        </TabPanel>
        <TabPanel value={value} index={4} dir={theme.direction}>
          <ResponsavelIdoso
            errosResponsavel={errosResponsavel} setErrosResponsavel={setErrosResponsavel}
            respIdoso={respIdoso} setRespIdoso={setRespIdoso}
            possueDefResp={possueDefResp} setPossueDefResp={setPossueDefResp}
            qualDefResp={qualDefResp} setQualDefResp={setQualDefResp}
            qualCIDResp={qualCIDResp} setQualCIDResp={setQualCIDResp}
            cpfResp={cpfResp} setCpfResp={setCpfResp}
            nomeResp={nomeResp} setNomeResp={setNomeResp}
            cepResp={cepResp} setCepResp={setCepResp}
            logradouroResp={logradouroResp} setLogradouroResp={setLogradouroResp}
            numeroResp={numeroResp} setNumeroResp={setNumeroResp}
            complementoResp={complementoResp} setComplementoResp={setComplementoResp}
            bairroResp={bairroResp} setBairroResp={setBairroResp}
            quadraResp={quadraResp} setQuadraResp={setQuadraResp}
            loteResp={loteResp} setLoteResp={setLoteResp}
            pontoReferenciaResp={pontoReferenciaResp} setPontoReferenciaResp={setPontoReferenciaResp}
            telefoneContatoResp={telefoneContatoResp} setTelefoneContatoResp={setTelefoneContatoResp}
          />
        </TabPanel>
        <TabPanel value={value} index={5} dir={theme.direction}>
          <FinalizarInscricao
            cpf={cpf}
            nome={nome}
            rg={rg}
            nis={nis}
            sexo={sexo}
            dataNascimento={dataNascimento}
            estadoCivil={estadoCivil}
            email={email}
            escolaridade={escolaridade}
            nomeDaMae={nomeDaMae}
            nomeDoPai={nomeDoPai}
            cpfDaMae={cpfDaMae}
            cpfDoPai={cpfDoPai}
            naturalidade={naturalidade}
            responsavelFinanceiro={responsavelFinanceiro}
            rendaMensalTitular={rendaMensalTitular}

            beneficio={beneficio}
            qualBeneficio={qualBeneficio}
            cras={cras}
            qualCras={qualCras}
            deficiencia={deficiencia}
            qualDeficiencia={qualDeficiencia}
            locomove={locomove}
            comunica={comunica}
            protese={protese}
            qualProtese={qualProtese}
            aparelho={aparelho}
            qualAparelho={qualAparelho}
            portadorDoenca={portadorDoenca}
            qualDoenca={qualDoenca}
            usoContinuo={usoContinuo}
            qualUsoContinuo={qualUsoContinuo}

            telefoneContato={telefoneContato}
            logradouro={logradouroProps}
            numero={numero}
            complemento={complemento}
            bairro={bairro}
            cidade={cidade}
            pontoReferencia={pontoReferencia}
            dataResideAnapolis={dataResideAnapolis}
            celular={celular}
            quadra={quadra}
            lote={lote}
            cep={cep}
            resideSozinho={resideSozinho}
            residenciaAlugada={residenciaAlugada}

            respIdoso={respIdoso}
            possueDefResp={possueDefResp}
            qualDefResp={qualDefResp}
            qualCIDResp={qualCIDResp}
            cpfResp={cpfResp}
            nomeResp={nomeResp}
            cepResp={cepResp}
            logradouroResp={logradouroResp}
            numeroResp={numeroResp}
            complementoResp={complementoResp}
            bairroResp={bairroResp}
            quadraResp={quadraResp}
            loteResp={loteResp}
            pontoReferenciaResp={pontoReferenciaResp}
            telefoneContatoResp={telefoneContatoResp}

            integrantes={integrantes} setIntegrantes={setIntegrantes}

            notifyError={notifyError}
            notifySuccess={notifySuccess}
            erros={erros}
            errosSaude={errosSaude}
            errosEndereco={errosEndereco}
            errosResponsavel={errosResponsavel}
          />
        </TabPanel>
        <div className='flex horizontal space-between'>
          <button onClick={() => handleBack(value)} style={value === 0 ? { visibility: 'hidden' } : { backgroundColor: '#1d8dc9', color: 'white', padding: '10px', border: 'none', borderRadius: '5px' }} ><i className='fa fa-arrow-left' /> Anterior</button>
          <button onClick={() => handleNext(value)} style={value === 6 - 1 ? { visibility: 'hidden' } : { backgroundColor: '#1d8dc9', color: 'white', padding: '10px', border: 'none', borderRadius: '5px' }} ><i className='fa fa-arrow-right' /> Próximo</button>
        </div>
      </div>
    </div>
  );
}