import React, { Component } from 'react';
import InputSimples from '../../../components/Inputs/Simples'
import InputSelect from '../../../components/Inputs/Select'
import Titulo from '../../../components/Texto/Titulo'

import { connect } from 'react-redux';
import * as actions from '../../../actions';
import { TextoSelect } from '../../../components/Texto/Dados';
import axios from 'axios'
import { api } from '../../../config';
import { getHeaders } from '../../../actions/localStorage';
import errorHandling from '../../../actions/errorHandling';
import CryptoJS from 'crypto-js'
import { cpfMask } from '../../../config/mask';

class ResponsavelIdoso extends Component {
  state = {
    cepResp: '',
    erros: {},
    buscaCep: {},
    valorCep: {},
    respIdoso: false,
    possueDefResp: false,
    qualDefResp: '',
    qualCID: '',
    cpfResp: '',
    nomeResp: '',
    cepResp: '',
    pesquisacepResp: '',
    logradouroResp: '',
    numeroResp: '',
    complementoResp: '',
    bairroResp: '',
    quadraResp: '',
    loteResp: '',
    pontoReferenciaResp: '',
    telefoneContatoResp: '',

  }

  componentDidMount() {
    this.validate();
  }

  buscarCpf = (cpf) => {
    let cpfFormat = cpfMask(cpf)

    api.get(`/api/v1/inscricoes/search/${cpfFormat}`, getHeaders())
      .then(response => {
        if (response.data.inscricoes) {
          let inscricao = response.data.inscricoes
          console.log(inscricao.nome)
          this.props.setNomeResp(inscricao.nome)
          this.props.setCepResp(inscricao.cep)
          this.props.setLogradouroResp(inscricao.rua)
          this.props.setNumeroResp(inscricao.numero)
          this.props.setComplementoResp(inscricao.complemento)
          this.props.setLoteResp(inscricao.lote)
          this.props.setQuadraResp(inscricao.quadra)
          this.props.setBairroResp(inscricao.bairro)
          this.props.setTelefoneContatoResp(inscricao.telefone)
        }
      })
      .catch(errorHandling)
  }


  validate = () => {
    const { respIdoso, possueDefResp, qualDefResp, qualCIDResp, cpfResp, nomeResp, cepResp, logradouroResp, numeroResp, complementoResp, bairroResp, quadraResp, loteResp, pontoReferenciaResp, telefoneContatoResp,
    } = this.props
    const erros = {}

    if (!cpfResp) erros.cpfResp = 'Preencha aqui o cpf do responsavel';
    if (!nomeResp) erros.nomeResp = 'Preencha aqui o nome do responsavel';
    if (!cepResp) erros.cepResp = 'Preencha aqui o cep';
    if (possueDefResp === true && !qualDefResp) erros.qualDefResp = 'Preencha qual é a Deficiencia.';
    if (possueDefResp === true && !qualCIDResp) erros.qualCIDResp = 'Preencha qual é o CID da Deficiencia.';
    if (cepResp.length < 8) erros.cepResp = 'Preencha aqui o cep'
    if ((!numeroResp || numeroResp === 0) && !quadraResp) erros.quadraResp = 'Preencha a quadra';
    if ((!numeroResp || numeroResp === 0) && !loteResp) erros.loteResp = 'Preencha o lote';
    if (!telefoneContatoResp) erros.telefoneContatoResp = 'Preencha um telefone para contato';

    this.setState({ erros });

    this.props.setErrosResponsavel(erros)

    return !(Object.keys(erros).length > 0);
  }

  onChangeInput = (field, value) => this.setState({ [field]: value }, () => this.validate())

  onBlur = () => this.validate()

  render() {

    const teste = valor => {
      this.props.setLogradouroResp(valor.logradouro)
      this.props.setBairroResp(valor.bairro)
      this.setState({ valorCep: valor, logradouroResp: valor.logradouro })
    }
    const pesquisacepResp = (valor) => {
      console.log(valor)
      axios.get(`https://viacep.com.br/ws/${valor}/json/`)
        .then(function (response) {
          teste(response.data)
        });
    }

    const { respIdoso, possueDefResp, qualDefResp, qualCIDResp, cpfResp, nomeResp, cepResp, logradouroResp, numeroResp, complementoResp, bairroResp, quadraResp, loteResp, pontoReferenciaResp, telefoneContatoResp,
    } = this.props
    const { erros } = this.state
    console.log(this.props.logradouroResp)
    return (
      <div className='Inscricao full-width flex vertical'>
        <div className='Card-Inscricao'>
          <Titulo tipo='titulo-detalhe-inscricao' titulo='Informações do responsável pelo idoso' />
          <div className='Container-Inscricao'>
            <div className='Input-Inscricao flex-1 flex horizontal space-between'>
              <TextoSelect
                chave='Responsavel mora com o idoso ?*'
                valor={(
                  <InputSelect
                    name='responsavelFinanceiro'
                    onChange={(ev) => (this.onChangeInput('respIdoso', ev.target.value), this.props.setRespIdoso(ev.target.value))}
                    value={(respIdoso) ? respIdoso : false}
                    opcoes={[
                      { label: 'SIM', value: true },
                      { label: 'NÃO', value: false }
                    ]} />
                )} />
              <TextoSelect
                chave='Possue alguma Deficiência*'
                valor={(
                  <InputSelect
                    name='Cras'
                    error={erros.possueDefResp}
                    onChange={(ev) => (this.onChangeInput('possueDefResp', ev.target.value), this.props.setPossueDefResp(ev.target.value))}
                    value={(possueDefResp) ? possueDefResp : false}
                    opcoes={[
                      { label: 'SIM', value: true },
                      { label: 'NÃO', value: false }
                    ]} />
                )} />
            </div>
            <br />
            <div className='Input-Inscricao flex-1 flex horizontal space-between'>
              {possueDefResp === false ? <div /> :
                <InputSimples
                  className='flex-3'
                  id='rendaMensalTitular'
                  name='rendaMensalTitular'
                  label='Qual deficiencia?'
                  placeHolder='Digite Aqui a deficiência'
                  type='text'
                  value={qualDefResp}
                  error={erros.qualDefResp}
                  onChange={(ev) => (this.onChangeInput('qualDefResp', ev.target.value), this.props.setQualDefResp(ev.target.value))} />

              }
              {possueDefResp === false ? <div /> :
                <InputSimples
                  className='flex-3'
                  id='qualCIDResp'
                  name='qualCIDResp'
                  label='Qual o CID?'
                  placeHolder='Digite aqui qual o cid'
                  type='text'
                  value={qualCIDResp}
                  error={erros.qualCIDResp}
                  onChange={(ev) => (this.onChangeInput('qualCIDResp', ev.target.value), this.props.setQualCIDResp(ev.target.value))} />

              }
            </div>
            <br />
            <div className='Input-Inscricao flex-1 flex horizontal space-between'>
              <InputSimples
                className='flex-3'
                id='cpf'
                name='cpfResp'
                label='CPF Responsavel'
                placeHolder='Digite aqui qual o CPF do responsavel'
                type='text'
                value={cpfResp}
                onBlur={(ev) => this.buscarCpf(ev.target.value)}
                error={erros.cpfResp}
                onChange={(ev) => (this.onChangeInput('cpfResp', ev.target.value), this.props.setCpfResp(ev.target.value))} />

              <InputSimples
                className='flex-3'
                id='nomeResp'
                name='nomeResp'
                label='Qual o Nome'
                placeHolder='Digite aqui qual o nome responsavel'
                type='text'
                value={nomeResp}
                error={erros.nomeResp}
                onChange={(ev) => (this.onChangeInput('nomeResp', ev.target.value), this.props.setNomeResp(ev.target.value))} />

            </div>
            <br />
            <div className='Input-Inscricao flex-1 flex horizontal space-between'>

              <InputSimples
                className='flex-1'
                name='cep'
                label='CEP:'
                placeHolder='Digite o CEP... *'
                value={cepResp}
                onClick={() => pesquisacepResp(this.state.cepResp === '' ? cepResp : '')}
                error={erros.cepResp}
                onChange={(ev) => (this.onChangeInput('cepResp', ev.target.value), this.props.setCepResp(ev.target.value))}
              />
              <button onClick={() => pesquisacepResp(cepResp)}><i className='fas fa-search' /></button>

              <InputSimples
                className='flex-1'
                id='logradouro'
                name='logradouro'
                label='Logradouro:'
                placeHolder='Digite o logradouro... *'
                value={logradouroResp}
                error={erros.logradouroResp}
                onChange={(ev) => (this.onChangeInput('logradouroResp', ev.target.value), this.props.setLogradouroResp(logradouroResp))}
              />
              <InputSimples
                className='flex-1'
                name='numero'
                id='numero'
                label='Número :'
                type='number'
                placeHolder='Digite o número'
                value={(numeroResp)}
                onChange={(ev) => (this.onChangeInput('numeroResp', ev.target.value), this.props.setNumeroResp(ev.target.value))} />
            </div>
            <br />
            <div className='Input-Inscricao flex-1 flex horizontal space-between'>
              <InputSimples
                className='flex-1'
                name='complemento'
                label='Complemento :'
                placeHolder='Digite o complemento'
                value={complementoResp}
                error={erros.complementoResp}
                onChange={(ev) => (this.onChangeInput('complementoResp', ev.target.value), this.props.setComplementoResp(ev.target.value))} />

              <InputSimples
                className='flex-1'
                name='bairro'
                id='bairro'
                label='Bairro :'
                placeHolder='Digite o bairro'
                value={bairroResp}
                error={erros.bairroResp}
                onChange={(ev) => (this.onChangeInput('bairroResp', ev.target.value), this.props.setBairroResp(ev.target.value))} />
            </div>

            <div className='Input-Inscricao flex-1 flex horizontal space-between'>
              <InputSimples
                className='flex-1'
                name='quadra'
                label='Quadra :'
                placeHolder='Digite a quadra'
                value={quadraResp}
                onBlur={() => this.onBlur}
                error={erros.quadraResp}
                onChange={(ev) => (this.onChangeInput('quadraResp', ev.target.value), this.props.setQuadraResp(ev.target.value))} />

              <InputSimples
                className='flex-1'
                name='lote'
                id='lote'
                label='Lote :'
                placeHolder='Digite o lote'
                value={loteResp}
                error={erros.loteResp}
                onChange={(ev) => (this.onChangeInput('loteResp', ev.target.value), this.props.setLoteResp(ev.target.value))} />
              <InputSimples
                escondido
                className='flex-1'
              />
            </div>


            <br />
            <div className='Input-Inscricao flex-1 flex horizontal space-between'>
              <InputSimples
                className='flex-1'
                name='pontoReferencia'
                label='Ponto de referência :'
                placeHolder='Digite o Ponto de referência'
                value={pontoReferenciaResp}
                error={erros.pontoReferenciaResp}
                onChange={(ev) => (this.onChangeInput('pontoReferenciaResp', ev.target.value), this.props.setPontoReferenciaResp(ev.target.value))} />

              <InputSimples
                className='flex-1'
                name='telefoneContato'
                label='Telefone para contato :'
                placeHolder='Digite o Telefone para contato'
                value={telefoneContatoResp}
                error={erros.telefoneContatoResp}
                onChange={(ev) => (this.onChangeInput('telefoneContatoResp', ev.target.value), this.props.setTelefoneContatoResp(ev.target.value))} />
            </div>
          </div>
        </div>
      </div >

    )
  }
}

export default connect(null, actions)(ResponsavelIdoso)
