import React, { Component } from 'react'
import moment from 'moment';
import Titulo from '../../components/Texto/Titulo'
import Pesquisa from '../../components/Inputs/Pesquisa'
import Tabela from '../../components/Tabela/Simples'
import TabelaMaterial from '../../components/Tabela/MaterialUIAptos'
import Paginacao from '../../components/Paginacao/Simples'

import { connect } from 'react-redux';
import * as actions from '../../actions/inscricoes'
import { formatMoney } from '../../actions'
import { DataTableIntegrais } from '../../components/Exports/exportsIntegrais';

class AreaAdm extends Component {

  state = {
    selectionModel: []
  }

  async componentDidMount() {
    await this.props.getInscricoesReservas();
  }

  render() {
    const { inscricao } = this.props.inscricoes;
    return (
      <div className="Inscricoes full-width">
        <Titulo tipo="h1" titulo="Inscrições" />
        <br />
        <DataTableIntegrais inscricoes={inscricao} />
        {/* <TabelaMaterial
          columns={[
            // {field: 'id', headerName: 'ID'},
            { field: 'nome', headerName: 'Nome', width: 300 },
            { field: 'cpf', headerName: 'CPF' },
            { field: 'rg', headerName: 'RG', hide: true },
            { field: 'sexo', headerName: 'Gênero', hide: true },
            { field: 'dataNascimento', headerName: 'Data de Nascimento' },
            { field: 'estadoCivil', headerName: 'Estado Civil', hide: true },
            { field: 'email', headerName: 'Email' },
            { field: 'escolaridade', headerName: 'Escolaridade', hide: true },
            { field: 'nomeDaMae', headerName: 'Nome da Mãe', hide: true },
            { field: 'naturalidade', headerName: 'Naturalidade', hide: true },
            { field: 'rendaMensalTitular', headerName: 'Renda Mensal do titular', hide: true },
            { field: 'quantidadeImoveis', headerName: 'Quantidade de Imóveis', hide: true },
            { field: 'egresso', headerName: 'Egresso', hide: true },
            { field: 'maeSolo', headerName: 'Mãe Solo', hide: true },
            { field: 'responsavelFinanceiro', headerName: 'Responsável Financeiro', hide: true },
            { field: 'pessoaComDeficiencia', headerName: 'Pessoa com deficiência', hide: true },
            { field: 'universidade', headerName: 'Universidade', hide: true },
            { field: 'curso', headerName: 'Curso', hide: true },
            { field: 'periodoCursando', headerName: 'Período que cursa', hide: true },
            { field: 'turnoDoCurso', headerName: 'Turno do curso', hide: true },
            { field: 'modalidadeCurso', headerName: 'Modalidade do curso', hide: true },
            { field: 'matricula', headerName: 'Matrícula', hide: true },
            { field: 'mensalidadeSemDesconto', headerName: 'Mensalidade sem desconto', hide: true },
            { field: 'mensalidadeComDesconto', headerName: 'Mensalidade com desconto', hide: true },
            { field: 'matricula', headerName: 'Matrícula', hide: true },
            { field: 'rendaTotal', headerName: 'Renda Total', hide: true },
            { field: 'pontos_mae_solo', headerName: 'Pontos Mãe Solo', hide: true },
            { field: 'pontos_arrimo_familia', headerName: 'Pontos Arrimo Familia', hide: true },
            { field: 'ponto_idoso', headerName: 'Pontos Idoso', hide: true },
            { field: 'pontos_egresso_sms', headerName: 'Pontos Egresso', hide: true },
            { field: 'pontos_renda_pc', headerName: 'pontos Renda' },
            { field: 'totalPontos', headerName: 'Pontos totais' }
          ]}
          selectionModel={(data) => this.setState({ selectionModel: data })}
          rows={inscricao ? inscricao : []} /> */}
      </div>
    )

  }
}

const mapStateToProps = state => ({
  inscricoes: state.inscricoes,
  usuario: state.auth.usuario
})
export default connect(mapStateToProps, actions)(AreaAdm)