import React, { Component } from 'react'
import Titulo from '../../components/Texto/Titulo'
import { InscricoesGrid } from '../../components/Tabela/MaterialUIAptos'

import { connect } from 'react-redux';
import * as actions from '../../actions/inscricoes'

class AreaAdm extends Component {

  state = {
    selectionModel: []
  }

  async componentDidMount() {
    await this.props.getInscricoes();
  }

  componentWillUnmount() {
    this.props.limparInscricao();
  }

  render() {
    const { inscricoes } = this.props;
    return (
      <div className="Inscricoes full-width">
        <Titulo tipo="h1" titulo="Inscrições" />
        <br />
        {inscricoes && <InscricoesGrid
          columns={[
            { field: 'id', headerName: 'id', hide: true },
            { field: 'nome', headerName: 'Nome', width: 300 },
            { field: 'cpf', headerName: 'CPF', width: 300 },
            { field: 'dataNascimento', headerName: 'Data de Nascimento', width: 300 },
            { field: 'email', headerName: 'Email', width: 300 },
          ]}
          selectionModel={(data) => this.setState({ selectionModel: data })}
          rows={inscricoes ? inscricoes : []}
        />
        }
      </div>
    )

  }
}

const mapStateToProps = state => ({
  inscricoes: state.inscricoes.inscricoes,
  usuario: state.auth.usuario
})
export default connect(mapStateToProps, actions)(AreaAdm)