import React from 'react'

const Footer = () => (
  <div className="Footer">
    <div className="faixa-footer"></div>
    <div className="text-center">
      <div className="Footer-Container flex flex-1">
        <div className="Footer-img flex-1">
          <img className="img-responsive" alt="logosocial" src="https://cdn.anapolis.go.gov.br/img/logos/sem_fundo/brancas/integracao.png" /></div>
        <div className="Footer-Text flex-1">
          <p className="text-center col-md-12">© Copyright 2021 | #CENTRODIADOIDOSO - Todos os Direitos Reservados<br />
            <span style={{ fontStyle: 'oblique' }}>Secretaria de Comunicação, Eventos e Modernização</span>
            <br />
            <span style={{ fontStyle: 'oblique' }}>Diretoria de Sistemas</span>
          </p>
        </div>
        <div className="Footer-Text-right flex-1">
          <b>Endereço</b>: Rua General Joaquim Inácio,<br />esq. c/ Travessa João Bruta<br />Centro - Anápolis - GO<br />Fone: (62) 3902-2034
        </div>
      </div>
    </div>
  </div>

)

export default Footer;

